import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import translationEN from './i18n/en/resource.json';
import translationES from './i18n/es/resource.json';
import translationNB from './i18n/nb/resource.json';
import translationUK from './i18n/en_uk/resource.json';
import translationFR from './i18n/fr/resource.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  en_uk: {
    translation: translationUK
  },
  es: {
    translation: translationES
  },
  fr: {
    translation: translationFR
  },
  nb: {
    translation: translationNB
  }
};

i18n
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .init({
      resources,
      lng: "en",

      keySeparator: false, // we do not use keys in form messages.welcome
      nsSeparator: false,
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });

export default i18n;
