import React from "react";
import { getI18n, withI18n } from "react-i18next";
import { SmallText } from "./StyledComponents";

const TermUsePrivacy = (props: { t: any }) => {
  const { t } = props;
  const isLangSpanish = getI18n().language === "es";
  const terms = isLangSpanish
    ? process.env.REACT_APP_CWB_Terms_Path_es
    : `${process.env.REACT_APP_CWB_Site}/${process.env.REACT_APP_CWB_Terms_Path}`
  const privacy = isLangSpanish
    ? process.env.REACT_APP_CWB_Privacy_Path_es
    : `${process.env.REACT_APP_CWB_Site}/${process.env.REACT_APP_CWB_Privacy_Path}`;

  return (
    <SmallText>
      {t("By signing up you agree to our")}{" "}
      <a
        href={terms}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t("Terms of Use")}
      </a>{" "}
      {t("and")}{" "}
      <a
        href={privacy}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t("Privacy Policy")}
      </a>
      .
    </SmallText>
  );
};
export default withI18n()(TermUsePrivacy);
