import { getUserActorSignUpId } from "helpers";
import * as http from "../http";
import { IAutoDiscount, IDiscount, IDiscountWithClientId } from "./dtos/IPromoCodeDto";
// @ts-ignore
import { getAuthConfig } from "cwb-react";

const api = `${process.env.REACT_APP_REG_Api}/PromoCode`;

export const verifyDiscount = (code: string) => {
  return http.get<IDiscount>(`${api}/verify-discount/${code}`);
};

export const getDiscount = (code: string) => {
  let signUpId = getUserActorSignUpId();
  if (signUpId) {
    return http.get<IDiscount>(`${api}/discount/${code}?suid=${signUpId}`);
  } else {
    return http.get<IDiscount>(`${api}/discount/${code}`);
  }
};

export const getDiscountByClientId = (dto: IDiscountWithClientId) => {
  const { code, clientId } = dto;
  return http.get<IDiscount>(`${api}/discount/${code}?cid=${clientId}`);
};

export function getAutoDiscountByClientId(dto: { clientId: number; }) {
  return http.get<IAutoDiscount>(`${api}/auto-discount/${dto.clientId}`,
  {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      CWB: '1',
      ...getAuthConfig().headers
    },
  }
  );
}
