import * as http from "../http";
// @ts-ignore
import { getAuthConfig } from "cwb-react";
import {
  ISubscriptionService,
  ISubscriptionDiscountService,
  IProductSubscriptionService,
  ITrialSubscriptionService,
} from "./dtos/IServicesDto";

const api = `${process.env.REACT_APP_REG_Api}/Services`;


export const getOneTimeServiceSignUp = (actorSignUpId: number) => {
  return http.get<ISubscriptionService[]>(
    `${api}/paid/onetimefee/signup/${actorSignUpId}`
  );
};

export const getPaidServicesSignup = (
  actorSignUpId: number,
  promo?: string
) => {
  return http.get<ISubscriptionService[]>(
    `${api}/paid/signup/${actorSignUpId}${(promo ? `?promoCode=${promo}`: '')}`
  );
};

export const getPaidBdSubmissionSignup = (
  actorSignUpId: number,
  promo?: string
) => {
  return http.get<ISubscriptionService>(
    `${api}/paid/bdsubmission/${actorSignUpId}${(promo ? `?promoCode=${promo}`: '')}`
  );
};


export const getFreeServiceSignup = (actorSignUpId: number) => {
  return http.get<ISubscriptionService>(`${api}/free/signup/${actorSignUpId}`);
};


export const getSubscriptionsServices = () => {
  return http.get<ISubscriptionService[]>(`${api}/all`);
};

export const getPaidServicesCWBlabs = (countryCode: string) => {
  return http.get<ISubscriptionService[]>(`${api}/paid/${countryCode}`);
};

export const getTrialService = (countryCode: string, isDev: boolean) => {
  return http.get<ISubscriptionService>(
    `${api}/trial/${countryCode}` + (isDev ? `?dev=${isDev}` : '')
  );
};

export const getPaidServicesClient = (promo?:string) => {
  const options = {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      CWB: "1",
      ...getAuthConfig().headers,
    },
  } as RequestInit;

  return http.get<ISubscriptionService[]>(`${api}/paid/client${(promo ? (`?promo=${promo}`) : '')}`, options);
};

export function getBreakdownSubmissionClientService(promo?:string) {
  const options = {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      CWB: "1",
      ...getAuthConfig().headers,
    },
  } as RequestInit;

  return http.get<ISubscriptionService>(`${api}/paid/client/bdsubmission${(promo ? (`?promo=${promo}`) : '')}`, options);
}

export const getOneTimeServiceClient = () => {
  const options = {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      CWB: "1",
      ...getAuthConfig().headers,
    },
  } as RequestInit;

  return http.get<ISubscriptionService[]>(
    `${api}/paid/onetimefee/client`,
    options
  );
};

export const getTrialUpgradeServices = () => {
  const options = {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      CWB: "1",
      ...getAuthConfig().headers,
    },
  } as RequestInit;

  return http.get<ISubscriptionService[]>(
    `${api}/paid/trialupgrade/client`,
    options
  );
};

export const getFreeServiceClient = () => {
  const options = {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      CWB: "1",
      ...getAuthConfig().headers,
    },
  } as RequestInit;

  return http.get<ISubscriptionService>(`${api}/free/client`, options);
};

export const getProductServicesClient = (service: number) => {
  const options = {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      CWB: "1",
      ...getAuthConfig().headers,
    },
  } as RequestInit;

  return http.get<IProductSubscriptionService>(
    `${api}/promotional/${service}`,
    options
  );
};

export const getLinkCodeTrialService = (linkCode: any, countryCode: string) => {
  return http.get<ITrialSubscriptionService>(
    `${api}/trial/link/${linkCode}/${countryCode}`
  );
};
