import React from "react";
import { withI18n } from "react-i18next";
import styled, { css } from "styled-components";
import { connect } from "react-redux";

// @ts-ignore
import { CWBDropdown } from "cwb-react";

import { getCurUserSignUp, PasswordLength, getCountryCode } from "../../helpers";
import { iRootState } from "../../store";
import { IAgentUser, IAgentError } from "../../shared/api/dtos/IAgentDtos";
import {
  nameRegex,
  phoneValidation,
  emailValidation,
  passwordValidation,
} from "../../helpers/validations";
import { IGroupedCountry } from "../../helpers/ICountryList";
import {
  CheckboxWrapper,
  Section,
  SectionTitle,
  StyledInput,
  Divider,
  StyledCheckbox,
  StyledBlueBtn,
  Spacer10,
  Spinner,
} from "../Common/StyledComponents";
import PasswordInput from "../Common/PasswordInput";
import TermUsePrivacy from "../Common/TermUsePrivacy";
import PhoneInput from "react-phone-input-2";
import { Typography } from "components/Common";
import { withRouter } from "react-router-dom";
import qs from "querystring";

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location: any;
}

interface IState {
  fields: any;
  isFormSubmitClicked: boolean;
  associationCheckboxes: any;
  cityList: any[];
  phoneCountryList: any[];
  phoneSelectedCountry: any;
}

const COUNTRIES = [
  "ca",
  "us",
  "gb",
  "de",
  "au",
  "gb",
  "ie",
  "es",
  "mx",
  "ar",
  "cl",
  "co",
  "pe",
  "ve",
  "cn",
];

class AgentRegistration extends React.Component<IProps, IState> {
  public state: IState = {
    fields: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      agency: "",
      country: "",
      city: "",
      onCamera: false,
      voice: false,
      association: "",
      countryId: undefined,
      cityId: undefined,
      countryCode: "CA",
      otherAssociation: "",
      referenceName1: "",
      referenceEmail1: "",
      referenceName2: "",
      referenceEmail2: "",
    } as IAgentUser,
    associationCheckboxes: {
      TAMAC: false,
      SAGAFTRA: false,
      ATA: false,
      AQAA: false,
      Other: false,
      none: false,
    },
    isFormSubmitClicked: false,
    cityList: [],
    phoneCountryList: [],
    phoneSelectedCountry: {}
  };

  async componentDidMount() {
    if (!this.props.countryCode && !getCountryCode()) {
      const qsArgs = this.props.location.search ? { ...qs.parse(this.props.location.search) } as any : {};
      if (qsArgs.dev && qsArgs.ctry) {
        await this.props.setForceRequesterCountryCode(qsArgs.ctry.toString())
      } else {
        try {
          await this.props.setRequesterCountryCode();
        } catch (e) {
          console.error(e);
        }
      }
    }

    if (this.props.groupCountryList.length <= 1)
      await this.props.getGroupCountryList();

    if (this.props.groupCountryList) {
      let fields: IAgentUser = this.state.fields;
      let cityList: any[] = [];
      const user: IAgentUser = getCurUserSignUp();
      if (user) {
        fields.country = user.country;
        fields.countryId = user.countryId;
        const country = this.props.groupCountryList.find((c) => {
          return c.id === user.countryId;
        });
        cityList = country
          ? country.cityList.map((city) => ({
            id: city.cityId,
            label: city.cityName,
            value: city.cityName,
            city,
          }))
          : [];
      } else {
        const grpCountries = this.props.groupCountryList;
        let o = grpCountries[0];

        // To test below code. Install Opera. In settings, turn on VPN.
        if (o && getCountryCode() && getCountryCode()?.toUpperCase() !== "CA")
          o = grpCountries.find((x) => x.country.countryCode?.toUpperCase() === "US");

        fields.country = o.country.countryCode;
        fields.countryId = o.country.countryId;
        cityList = o.cityList.map((city) => ({
          id: city.cityId,
          label: city.cityName,
          value: city.cityName,
          city,
        }));
      }
      this.setState({ fields, cityList });
      const phoneCountryList = this.props.groupCountryList.map((item) => {
        return {
          ...item,
          label: `${item.country.countryName} (${item.country.internationalCallingCode})`,
          value: item.country.internationalCallingCode,
        };
      });
      const phoneSelectedCountry = phoneCountryList.find((item) => {
        return item.country.countryCode === "CA";
      });
      this.setState({ phoneCountryList, phoneSelectedCountry });
    }
  }

  checkValidation() {
    const { t } = this.props;
    let fields: IAgentUser = this.state.fields;
    let errors: IAgentError = {} as IAgentError;

    //Name
    if (!fields.firstName || fields.firstName.trim().length < 2)
      errors.FirstName = !fields.firstName
        ? t("First Name is required")
        : t("Minimum 2 characters");

    if (fields.firstName && !nameRegex.test(fields.firstName))
      errors.FirstName = t(
        "Name can only contain letters, spaces, -, ' and `."
      );

    //Last Name
    if (!fields.lastName || fields.lastName.trim().length < 2)
      errors.LastName = !fields.lastName
        ? t("Last Name is required")
        : t("Minimum 2 characters");

    if (fields.lastName && !nameRegex.test(fields.lastName))
      errors.LastName = t("Name can only contain letters, spaces, -, ' and `.");

    if (emailValidation(fields.email, t))
      errors.Email = emailValidation(fields.email, t);

    if (passwordValidation(fields.password, PasswordLength, t))
      errors.Password = passwordValidation(fields.password, PasswordLength, t);

    //projectTypeToCast
    if (!fields.onCamera && !fields.voice)
      errors.OnCamera = t("Select at least one type");

    if (!fields.country) errors.Country = t("Country field is required");

    if (!fields.agency) errors.Agency = t("Agency field is required");

    if (phoneValidation(fields.phone, t))
      errors.Phone = phoneValidation(fields.phone, t);

    if (
      !fields.association &&
      !this.state.associationCheckboxes.Other &&
      !this.state.associationCheckboxes.none
    ) {
      errors.association = t(
        `You must select at least one association or "None of the above"`
      );
    } else if (
      this.state.associationCheckboxes.Other &&
      !fields.otherAssociation
    ) {
      errors.association = t("Association name is required");
    } else if (
      !fields.association &&
      !this.state.associationCheckboxes.Other &&
      (!fields.referenceEmail1 ||
        !fields.referenceEmail2 ||
        !fields.referenceName1 ||
        !fields.referenceName2)
    ) {
      errors.association = t("References are required");

      if (!fields.referenceEmail1) errors.referenceEmail1 = " ";
      if (!fields.referenceEmail2) errors.referenceEmail2 = " ";
      if (!fields.referenceName1) errors.referenceName1 = " ";
      if (!fields.referenceName2) errors.referenceName2 = " ";
    }

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  handleCheckbox = (field: string, value: boolean) => {
    let fields = this.state.fields;
    fields[field] = value;
    this.setState({ fields });
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  handleChange = (e: any) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  checkNumber() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IAgentError;

    if (!fields.phone || fields.phone.match(/^[0-9`]+$/i)) {
      this.props.setErrors(errors);
      return true;
    }

    errors.Phone = t("Only numbers are accepted");
    fields.phone = "";
    this.setState({ fields });
    this.props.setErrors(errors);
    return false;
  }

  handlePhoneChange = (phoneNumber: string, country: any) => {
    this.setState({
      fields: {
        ...this.state.fields,
        phone: phoneNumber,
        countryCode: country.countryCode,
      },
    });
    this.checkNumber();
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  handleCountry = (option: any) => {
    let fields: IAgentUser = this.state.fields;
    fields.country = option.label;
    fields.countryId = option.id;
    this.setState({
      fields,
      cityList: option.cityList.map((c: any) => ({
        id: c.cityId,
        label: c.cityName,
        value: c.cityName,
        c,
      })),
    });
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  handleCity = (option: any) => {
    let fields: IAgentUser = this.state.fields;
    fields.city = option.label;
    fields.cityId = option.id;
    this.setState({ fields });
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  handleAssociations = (name: string, value: boolean) => {
    let fields: IAgentUser = { ...this.state.fields };
    let association = fields.association;
    let associationCheckboxes = { ...this.state.associationCheckboxes };

    if (name === "none") {
      // Clear Other flags
      Object.keys(associationCheckboxes).forEach((k: any) => {
        associationCheckboxes[k] = false;
      });
      association = "";
    } else if (value) {
      associationCheckboxes.none = false;
      if (association.indexOf(name) === -1) {
        let arr = association.split(",");
        arr.push(name);
        let tmp = arr.filter((x) => x !== "");
        association = tmp.join(",");
      }
    } else {
      if (association.indexOf(name) > -1) {
        let arr = association.split(",");
        let tmp = arr.filter((x) => x !== name);
        association = tmp.join(",");
      }
    }

    associationCheckboxes[name] = value;
    fields.association = association;

    this.setState({ fields, associationCheckboxes }, () => {
      this.state.isFormSubmitClicked && this.checkValidation();
    });
  };

  buildPhoneNumber = (fields: any) => {
    return {
      ...fields,
      phone: `+${fields.phone}`,
    };
  };

  createAccount = async (e: any) => {
    e.preventDefault();
    this.setState({ isFormSubmitClicked: true });
    let fields = this.state.fields;
    if (!this.checkValidation()) return;
    fields = this.buildPhoneNumber(fields);
    await this.props.createAgentAccount(fields);
    this.moveToNext();
  };

  moveToNext = () => {
    if (this.props.errors.errorMessage)
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push("/agent/completed");
    }
  };

  render() {
    const { t, countryCode } = this.props;
    const fields: IAgentUser = this.state.fields;
    const errors: IAgentError = this.props.errors;
    const associations = this.state.associationCheckboxes;

    const country = this.props.groupCountryList.find(
      (x) => x.id === fields.countryId
    );

    const city = this.state.cityList.find((c) => c.id === fields.cityId);
    const userCountryCode = countryCode || getCountryCode();
    const noneForm = (
      <NoneForm>
        <Divider />
        <Typography color="medGrey">
          {t(
            "Please provide the names and email addresses for 2 Casting Directors who are members of CSA, CDC, CCDA, EIC, and/or Casting Workbook as references."
          )}
        </Typography>
        <ReferenceContainer>
          <Typography color="darkGrey" component="h2" gutterBottom variant="h5">
            {t(`Reference ${1}`)}
          </Typography>
          <Typography color="medGrey" variant="captionBold">
            {t("Name")}
          </Typography>
          <StyledInput
            className={errors.referenceName1 ? "invalid" : ""}
            name="referenceName1"
            type="text"
            onChange={this.handleChange}
            value={fields.referenceName1}
          />

          <Typography color="medGrey" variant="captionBold">
            {t("Email")}
          </Typography>
          <StyledInput
            className={errors.referenceEmail1 ? "invalid" : ""}
            name="referenceEmail1"
            type="text"
            onChange={this.handleChange}
            value={fields.referenceEmail1}
          />

          <Divider />
        </ReferenceContainer>

        <ReferenceContainer>
          <Typography color="darkGrey" component="h2" gutterBottom variant="h5">
            {t(`Reference ${2}`)}
          </Typography>
          <Typography color="medGrey" variant="captionBold">
            {t("Name")}
          </Typography>
          <StyledInput
            className={errors.referenceName2 ? "invalid" : ""}
            name="referenceName2"
            type="text"
            onChange={this.handleChange}
            value={fields.referenceName2}
          />

          <Typography color="medGrey" variant="captionBold">
            {t("Email")}
          </Typography>
          <StyledInput
            className={errors.referenceEmail2 ? "invalid" : ""}
            name="referenceEmail2"
            type="text"
            onChange={this.handleChange}
            value={fields.referenceEmail2}
          />
        </ReferenceContainer>
      </NoneForm>
    );

    return (
      <Wrapper>
        <form
          name="registerForm"
          className="registerForm"
          onSubmit={this.createAccount}
          style={{ width: "100%" }}
        >
          <fieldset>
            <Section>
              <SectionTitle>{t("First Name")}</SectionTitle>
              <StyledInput
                name="firstName"
                type="text"
                className={errors.FirstName ? "invalid" : ""}
                maxLength={256}
                onChange={this.handleChange}
                value={fields.firstName}
              />
              <SmallText className="error">
                <span>{errors.FirstName}</span>
              </SmallText>
            </Section>
            <Section>
              <SectionTitle>{t("Last Name")}</SectionTitle>
              <StyledInput
                name="lastName"
                type="text"
                maxLength={256}
                className={errors.LastName ? "invalid" : ""}
                onChange={this.handleChange}
                value={fields.lastName}
              />
              <SmallText className="error">
                <span>{errors.LastName}</span>
              </SmallText>
            </Section>
            <Section>
              <SectionTitle>{t("Email")}</SectionTitle>
              <StyledInput
                name="email"
                type="text"
                autoComplete="off"
                className={errors.Email ? "invalid" : ""}
                onChange={this.handleChange}
                value={fields.email}
              />
              <SmallText className="error">
                <span>{errors.Email}</span>
              </SmallText>
            </Section>
            <Section>
              <SectionTitle>{t("Password")}</SectionTitle>
              <PasswordInput
                name="password"
                autoComplete="new-password"
                className={errors.Password ? "invalid" : ""}
                onChange={this.handleChange}
                value={fields.password}
              />
              {!errors.Password && (
                <SmallText>
                  <span>
                    {t(
                      "Must contain at least 6 characters, 1 letter and 1 number"
                    )}
                  </span>
                </SmallText>
              )}
              <SmallText className="error">
                <span>{errors.Password}</span>
              </SmallText>
            </Section>
            <Section>
              <SectionTitle>{t("Phone Number - Optional")}</SectionTitle>

              <PhoneDiv>
                <PhoneInput
                  containerStyle={{ marginTop: "8px" }}
                  country={userCountryCode ? userCountryCode.toLocaleLowerCase() : ""}
                  onlyCountries={COUNTRIES}
                  value={this.state.fields.phone}
                  onChange={(phoneNumber, country) =>
                    this.handlePhoneChange(phoneNumber, country)
                  }
                />
              </PhoneDiv>
              <SmallText className="error">
                <span>{errors["Phone"]}</span>
              </SmallText>
            </Section>
            <Divider></Divider>
            <Section>
              <SectionTitle>{t("Agency")}</SectionTitle>
              <StyledInput
                name="agency"
                type="text"
                maxLength={50}
                className={errors.Agency ? "invalid" : ""}
                onChange={this.handleChange}
                value={fields.agency}
              />
              <SmallText className="error">
                <span>{errors.Agency}</span>
              </SmallText>
            </Section>
            <Section>
              <SectionTitle>{t("Choose your country")}</SectionTitle>
              <CWBDropdown
                value={country}
                isValueSelected={true}
                placeholder={t("Select Country")}
                items={this.props.groupCountryList}
                onChange={(option: any) => this.handleCountry(option)}
              />
              <SmallText className="error">
                <span>{errors.Country}</span>
              </SmallText>
            </Section>
            <Section className="margin-bottom-none">
              <SectionTitle>{t("Choose your nearest city")}</SectionTitle>
              <CWBDropdown
                value={city || null}
                isValueSelected={true}
                placeholder={t("Select City")}
                items={this.state.cityList}
                onChange={(option: any) => this.handleCity(option)}
              />
              <SmallText className="error">
                <span>{errors.City}</span>
              </SmallText>
            </Section>
            <Divider></Divider>
            <Section>
              <SectionTitle>
                {t("What type of actors do you represent?")}
              </SectionTitle>
              <StyledSectionDiv>
                <CheckboxWrapper
                  onClick={() =>
                    this.handleCheckbox("onCamera", !fields.onCamera)
                  }
                >
                  <StyledCheckbox
                    className="small-cb"
                    isChecked={fields.onCamera}
                  />
                  <StyledImg alt="camera" src="../images/camera.svg" />
                  <StyledCbLabel>{t("On-Camera")}</StyledCbLabel>
                </CheckboxWrapper>
                <CheckboxWrapper
                  onClick={() => this.handleCheckbox("voice", !fields.voice)}
                >
                  <StyledCheckbox
                    className="small-cb"
                    isChecked={fields.voice}
                  />
                  <StyledImg alt="voice" src="../images/voice.svg" />
                  <StyledCbLabel>{t("Voice")}</StyledCbLabel>
                </CheckboxWrapper>
              </StyledSectionDiv>
              <SmallText className="error">
                <span>{errors.OnCamera}</span>
              </SmallText>
            </Section>
            <Section>
              <SectionTitle>
                {t("Please select any associations you are member of")}:
              </SectionTitle>
              <Spacer10 />
              {Object.keys(associations).map((assoc, index) => (
                <StyledLabel key={index}>
                  <CheckboxInput
                    checked={associations[assoc]}
                    name={assoc}
                    type="checkbox"
                    onChange={(e) =>
                      this.handleAssociations(
                        (e.target as any).name,
                        (e.target as any).checked
                      )
                    }
                  />
                  <Checkbox checked={associations[assoc]} />
                  <Checkmark checked={associations[assoc]} />
                  {assoc === "Other"
                    ? t("Other")
                    : assoc === "none"
                      ? t("None of the above")
                      : t(assoc)}
                </StyledLabel>
              ))}
            </Section>
            {this.state.associationCheckboxes.none && noneForm}
            {this.state.associationCheckboxes.Other && (
              <StyledSectionAssoc>
                <SectionTitle>
                  {t("Please enter your association below.")}
                </SectionTitle>
                <StyledInput
                  name="otherAssociation"
                  type="text"
                  className={errors.association ? "invalid" : ""}
                  onChange={this.handleChange}
                  value={fields.otherAssociation}
                />
              </StyledSectionAssoc>
            )}
            <SmallText className="error">
              <span>{errors.association}</span>
            </SmallText>
            <Section>
              <StyledBlueBtn type="submit" id="submit" disabled={this.props.isLoading}>
                {t("Submit")}
                {this.props.isLoading && (
                  <Spinner
                    src="../images/spinner.svg"
                    className="spinner-width"
                  />
                )}
              </StyledBlueBtn>
            </Section>
          </fieldset>
        </form>
        <TermUsePrivacy />
      </Wrapper>
    );
  }
}

interface StateProps {
  errors: IAgentError;
  groupCountryList: IGroupedCountry[];
  isLoading: boolean;
  countryCode: string;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    errors: state.agentModel.errors,
    groupCountryList: state.referencesModel.groupCountryList,
    isLoading: state.agentModel.isLoading,
    countryCode: state.referencesModel.countryCode,
  };
}

interface DispatchProps {
  createAgentAccount: (fields: IAgentUser) => void;
  setErrors: (errors: any) => void;
  getGroupCountryList: () => void;
  setRequesterCountryCode: (fallback?: string | null) => void;
  setForceRequesterCountryCode: (countryCode: string) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    createAgentAccount: dispatch.agentModel.createAgentAccount,
    setErrors: dispatch.agentModel.setErrors,
    getGroupCountryList: dispatch.referencesModel.getGroupCountryList,
    setRequesterCountryCode: dispatch.referencesModel.setRequesterCountryCode,
    setForceRequesterCountryCode: dispatch.referencesModel.setForceRequesterCountryCode
  };
}

export default withI18n()(withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AgentRegistration)
));

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  & input {
    font-size: ${(p) => p.theme["s-font-size"]};
  }
`;

const SmallText = styled.div`
  color: ${(p) => p.theme.color} !important;
  width: 100%;
  font-size: ${(p) => p.theme["xxs-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  letter-spacing: 0.21px;
  line-height: 15px;
  margin-top: 8px;

  span {
    font-weight: normal;
  }

  &.error,
  &.error span {
    color: ${(p) => p.theme.errorColor} !important;
    font-weight: normal;
  }
`;

const PhoneDiv = styled.div`
  display: flex;
  margin-top: ${(p) => p.theme.spacing(1)};
`;

const StyledSectionDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const StyledCbLabel = styled.span`
  font-size: ${(p) => p.theme["xxs-font-size"]};
  letter-spacing: 0.2px;
  margin-left: 4px;
`;

const StyledImg = styled.img`
  margin: ${(p) => p.theme.spacing(0, 0, 0.25, 1)};
`;

const StyledSection = styled.section`
  margin-bottom: ${(p) => p.theme.spacing(-1)};
`;

const StyledSectionAssoc = styled(StyledSection)`
  padding-bottom: ${(p) => p.theme.spacing(1)};
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 11px;
`;

const CheckboxInput = styled.input`
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0;
`;

const Checkbox = styled.div<{ checked: boolean }>`
  width: 12px;
  height: 12px;
  margin: 0 4px 2px 0;
  border: 1px solid #c9ced6;
  border-radius: 2px;

  ${(p) =>
    p.checked &&
    css`
      background-color: #0f9bf0;
      border-color: #0f9bf0;
    `}
`;

const Checkmark = styled.span<{ checked: boolean }>`
  width: 4px;
  height: 8px;
  position: absolute;
  top: 2px;
  left: 4px;
  display: none;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);

  ${(p) =>
    p.checked &&
    css`
      display: block;
    `}
`;

const NoneForm = styled.div`
  & > p {
    margin-bottom: ${(p) => p.theme.spacing(2)};
  }
`;

const ReferenceContainer = styled.div`
  & > input {
    margin: ${(p) => p.theme.spacing(0)};
  }
  & > p {
    margin: ${(p) => p.theme.spacing(2, 0, 1)};
  }
`;
