import React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";

import * as serviceWorker from "./serviceWorker";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "react-phone-input-2/lib/style.css";
import { store } from "./store";
import "./i18n";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === "production")
  Sentry.init({ dsn: process.env.REACT_APP_SentryDns });

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={"Sentry Fallback - something went wrong"}>
    <Provider store={store}>
      <App />
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
