import React, { useEffect } from "react";
import qs from "query-string";
import { withI18n } from "react-i18next";
import { connect } from "react-redux";
import { Link as ReactRouterLink, RouteComponentProps } from "react-router-dom";
import {
  cleanCache,
  getCountryCode,
  timedSentrySendThenErrorPage,
} from "../../helpers/index";
import styled from "styled-components";
import { Typography } from "components/Common";
import ActorSignUpForm from "./components/ActorSignUpForm";
import Header from "./components/Header";
import Page from "./components/Page";
import ActorSellingFeatures from "./components/ActorSellingFeatures";
// @ts-ignore
import * as Sentry from "@sentry/react";

type Props = {
  t: any;
} & StateProps &
  DispatchProps &
  RouteComponentProps;

// CWB labs landing
const ActorSignUp: React.FC<Props> = ({
  t,
  location,
  currencySymbol,
  yearlyPerMonthCharge,
  getSubscriptionServicesCWBlabs,
  countryCode,
  setRequesterCountryCode,
  setForceRequesterCountryCode
}) => {
  useEffect(() => {
    cleanCache();
  }, []);
  useEffect(() => {
    (async () => {
      try {
        let countryCodeCopy = countryCode;
        const qsArgs = qs.parse(location.search)
        if (qsArgs.dev && qsArgs.ctry) {
          countryCodeCopy = qs.parse(location.search).ctry as string;
          setForceRequesterCountryCode(countryCodeCopy);
        }

        if (!countryCodeCopy && !getCountryCode()) {
          (async () => {
            try {
              await setRequesterCountryCode();
            } catch (e) {
              console.error(e);
              Sentry.captureException(e);
            }
          })();
        }
        if (countryCodeCopy || getCountryCode())
          await getSubscriptionServicesCWBlabs(countryCodeCopy || getCountryCode());
      } catch (e) {
        timedSentrySendThenErrorPage(e);
      }
    })();
  }, [countryCode]);

  const params = qs.stringify({
    cwblabs: true,
    redirect: "cwblabs",
    ...qs.parse(location.search),
  });

  let charge = yearlyPerMonthCharge?.toString() ?? "";
  if (currencySymbol === "€" && yearlyPerMonthCharge) {
    charge = yearlyPerMonthCharge.toString().replace(".", ",");
  }

  return (
    <>
      <Header />
      <Page>
        <Wrapper>
          <WrapperColumnsLeft>
            <ActorSelling>
              <ActorSellingFeatures />
            </ActorSelling>
          </WrapperColumnsLeft>
          <WrapperColumnsRight>
            <StyledActorSignUpForm
              currencySymbol={currencySymbol}
              yearlyPerMonthCharge={yearlyPerMonthCharge}
            />
          </WrapperColumnsRight>
        </Wrapper>
        <Footnote align="center" color="white">
          {t(
            "Are you a Casting Director or Agent? Learn more about how Casting Workbook can support you."
          )}
        </Footnote>
        <LinksContainer>
          <StyledReactRouterLink to={`/register/agent?${params}`}>
            {t("Agents & Managers")}
          </StyledReactRouterLink>
          <StyledReactRouterLink to={`/register/casting?${params}`}>
            {t("Casting Directors")}
          </StyledReactRouterLink>
        </LinksContainer>
      </Page>
    </>
  );
};

const StyledActorSignUpForm = styled(ActorSignUpForm)`
  width: 480px;

  @media all and (max-width: 520px) {
    width: 100%;
  }
`;

const Footnote = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(2)};
  line-height: 19px;
  @media all and (max-width: 520px) {
    padding: 10px;
  }
`;

const LinksContainer = styled.div`
  margin-bottom: ${(p) => p.theme.spacing(6)};

  & > a + a {
    margin-left: ${(p) => p.theme.spacing(6)};
  }
`;

const StyledReactRouterLink = styled(ReactRouterLink)`
  && {
    color: #02b8f9;
    font-weight: bold;

    &:hover {
      color: #02b8f9;
      filter: brightness(1.15);
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 48px;
  margin-bottom: 48px;

  @media all and (max-width: 768px) {
    flex-direction: column;
    background-color: white;
  }
`;

const WrapperColumnsLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  flex: 0 0 60%;
  max-width: 60%;

  @media all and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;

    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

const WrapperColumnsRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  flex: 0 0 40%;
  max-width: 40%;

  @media all and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;

    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

const ActorSelling = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;

  max-width: 672px;
  min-width: 480px;

  float: right !important;

  @media all and (max-width: 768px) {
    max-width: 680px;
    min-width: 40%;
    float: none !important;
    margin-bottom: 0;
    width: 100%;
  }
`;

type StateProps = {
  currencySymbol: string;
  mediaLimit: number;
  yearlyPerMonthCharge: number;
  numPhotos: number;
  countryCode: string;
};

const mapStateToProps = (state: any) => ({
  currencySymbol: state.premiumModel.currencySymbol,
  mediaLimit: state.premiumModel.mediaLimit,
  numPhotos: state.premiumModel.headshot.premium,
  yearlyPerMonthCharge: state.premiumModel.yearlyPerMonthCharge,
  countryCode: state.referencesModel.countryCode
});

type DispatchProps = {
  getSubscriptionServicesCWBlabs: (code: string) => void;
  setRequesterCountryCode: (fallback?: string | null) => void;
  setForceRequesterCountryCode: (countryCode: string) => void;
};

const mapDispatchToProps = (dispatch: any) => ({
  getSubscriptionServicesCWBlabs:
  dispatch.premiumModel.getSubscriptionServicesCWBlabs,
  setRequesterCountryCode: dispatch.referencesModel.setRequesterCountryCode,
  setForceRequesterCountryCode: dispatch.referencesModel.setForceRequesterCountryCode
});

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorSignUp)
);
