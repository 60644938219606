import { createModel } from "@rematch/core";
import * as PerformerAPI from "shared/api/Performer.api";
// @ts-ignore
import { getSessionDataFromAuthToken, setUserAuthToken } from "cwb-react";
import { cleanObjectProperties, setWebSessionKey } from "../helpers";
// @ts-ignore
import * as Sentry from "@sentry/react";

const performerModel = createModel({
  state: {
    clientId: "",
  },
  reducers: {
    setClientId: (state, clientId: string) => ({
      ...state,
      clientId,
    }),
  },
  effects: (dispatch: any) => ({
    async getPerformerTypes() {
      const response = await PerformerAPI.getPerformerTypes();
      if (!response.ok) return [];
      return (response.data as any[]).map((item) => ({
        label: item.description,
        value: item.performerTypeID,
      }));
    },
    async createPerformer(performer) {
      cleanObjectProperties(performer);
      let resp: any;
      try {
        resp = await PerformerAPI.createPerformer(performer);
        try {
          const jwtToken = resp!.data.jwt;
          setUserAuthToken(jwtToken);
          const clientId = resp!.data.clientId;
          dispatch.performerModel.setClientId(clientId);
          let data = await getSessionDataFromAuthToken(jwtToken);
          let webSessionKey = data.WebSessionKey;
          setWebSessionKey(webSessionKey);
        } catch (err) {
          if (resp && (resp.status == 400 || resp.status >= 500))
            Sentry.captureException(err);
        }
      } catch (err) {
        if (resp && (resp.status == 400 || resp.status >= 500))
          Sentry.captureException(err);
        throw err;
      }
    },
  }),
});

export default performerModel;