import {
  IActorCredentials,
  IActorPhone,
  IActorAccountDto,
  IActorStageName,
  IActorSignUpDto,
  IAccountInfo,
  IAccountCredentials,
  IActorFilmCenter,
  IActorEmail,
} from "./dtos/IActorDto";
import * as http from "../http";
import axios from "axios";
import { getTrackingData } from "helpers";
import { TrackingData } from "./dtos/ITrackingDataDto";

export const createActorAccount = (dto: IActorCredentials) => {
  return http.post<IActorAccountDto>(
    `${process.env.REACT_APP_REG_Api}/actor/credentials`,
    dto
  );
};

export const createActorPhone = (sessionId: any, dto: IActorPhone) => {
  return http.post<IActorAccountDto>(
    `${process.env.REACT_APP_REG_Api}/actor/${sessionId}/phone`,
    dto
  );
};

export const verifyActorPhone = (
  sessionId: any,
  dto: IActorPhone,
  code: string
) => {
  return http.post<IActorAccountDto>(
    `${process.env.REACT_APP_REG_Api}/actor/${sessionId}/verify-phone/${code}`,
    dto
  );
};

export const verifyActorEmail = (sessionId: any, code: string, dev: string = null) => {
  const qs = dev ? `?dev=${dev}` : '';
  return http.post<IActorAccountDto>(
    `${process.env.REACT_APP_REG_Api}/actor/${sessionId}/verify/email/${code}${qs}`,
    {}
  );
};

export const verifyEmailResendCode = (sessionId: any, dto: IActorEmail, dev: string = null) => {
  const qs = dev ? `?dev=${dev}` : '';
  return http.post<IActorAccountDto>(
    `${process.env.REACT_APP_REG_Api}/actor/${sessionId}/verify/email/resend${qs}`,
    dto
  );
};

export const createStageName = (sessionId: any, dto: IActorStageName) => {
  return http.post<IActorAccountDto>(
    `${process.env.REACT_APP_REG_Api}/actor/${sessionId}/stagename`,
    dto
  );
};

export const createTrialSignUp = (sessionId: any, dto: IActorFilmCenter, dev:string = null) => {
  const qs = dev ? `?dev=${dev}` : '';
  return http.post<IActorSignUpDto>(
    `${process.env.REACT_APP_REG_Api}/actor/${sessionId}/trial/signup${qs}`,
    dto
  );
};

export const createActorSignUp = (sessionId: any, dto: IActorAccountDto, dev:string = null) => {
  const qs = dev ? `?dev=${dev}` : '';
  return http.post<IActorSignUpDto>(
    `${process.env.REACT_APP_REG_Api}/actor/${sessionId}/save-signup${qs}`,
    dto
  );
};

export const getAccountInfo = (guid: string) => {
  return http.get<IAccountInfo>(
    `${process.env.REACT_APP_REG_Api}/actor/basicInfo/${guid}`
  );
};

export const confirmAccount = (password: string, guid: string) => {
  return axios.post(
    `${process.env.REACT_APP_REG_Api}/actor/updatepass/${guid}?password=${password}`,
    null
  );
};

export const loginAccount = (dto: IAccountCredentials) => {
  return axios.post(`${process.env.REACT_APP_Api}/auth/jwt/authorize`, {
    login: dto.workbookId,
    password: dto.password,
  });
};

export const createFreemiumUser = (actorSignUpId: number) => {
  let trackingData = {} as TrackingData;
  if(getTrackingData())
    trackingData = getTrackingData();
  
  return http.post(
    `${process.env.REACT_APP_REG_Api}/actor/create-freemium/${actorSignUpId}${window.location.search}`,
    trackingData
  );
};

export const createTrialUser = (
  actorSignUpId: number,
  isDev: boolean = false
) => {
  let trackingData = {} as TrackingData;
  if(getTrackingData())
    trackingData = getTrackingData();
  return http.post(
    `${process.env.REACT_APP_REG_Api}/StripeTrialSubscription/create/${actorSignUpId}?dev=${isDev}`,
    trackingData
  );
};

export const createTrialCodeUser = (
  actorSignUpId: number,
  serviceCode: string,
  aId?: string,
  dId?: string
) => {
  const data: any = {
    trackingDataDto: getTrackingData(),
    actorSignUpId,
    linkCode: serviceCode,
  };
  let url = `${process.env.REACT_APP_REG_Api}/StripeTrialSubscription/create/code`;
  if (aId && dId) {
    url = url.concat(`?aId=${aId}&dId=${dId}`);
  } else if (aId && !dId) {
    url = url.concat(`?aId=${aId}`);
  } else if (!aId && dId) {
    url = url.concat(`?dId=${dId}`);
  }
  return http.post(url, data);
};
