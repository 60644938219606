import { createModel } from "@rematch/core";
import * as ServicesAPI from "../shared/api/Services.api";
import * as JobsAPI from "../shared/api/ActorJobs.api";
import {
  ISubscriptionService,
  IProductSubscriptionService,
} from "../shared/api/dtos/IServicesDto";
// @ts-ignore
import * as Sentry from "@sentry/react";
import moment from "moment";

const servicesModel = createModel({
  state: {
    isLoading: false as boolean,
    services: [] as ISubscriptionService[],
    trialUpgradeServices: [] as ISubscriptionService[],
    productService: {} as IProductSubscriptionService,
    oneTimeService: {} as ISubscriptionService,
    fromPrecheckout: false as boolean
  },
  reducers: {
    setIsLoading: (state: any, isLoading: boolean) => ({
      ...state,
      isLoading,
    }),
    setServices: (state: any, services: ISubscriptionService[]) => ({
      ...state,
      services,
    }),
    setTrialUpgradeServices: (
      state: any,
      trialUpgradeServices: ISubscriptionService[]
    ) => ({
      ...state,
      trialUpgradeServices,
    }),
    setProductService: (
      state: any,
      productService: IProductSubscriptionService
    ) => ({
      ...state,
      productService,
    }),
    setOneTimeService: (state: any, oneTimeService: ISubscriptionService) => ({
      ...state,
      oneTimeService,
    }),
    setFromPrecheckout: (state: any, fromPrecheckout: boolean) => ({
      ...state,
      fromPrecheckout,
    }),
  },
  effects: (dispatch: any) => ({
    getPaidServicesSignup: async (actorSignUpId) => {
      dispatch.servicesModel.setIsLoading(true);
      try {
        const response = await ServicesAPI.getPaidServicesSignup(actorSignUpId);
        dispatch.servicesModel.setServices(response.data);
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        dispatch.servicesModel.setIsLoading(false);
      }
    },
    getOneTimePaidServiceSignup: async (actorSignUpId) => {
      dispatch.servicesModel.setIsLoading(true);
      let isValid = false;
      try {
        const response = await ServicesAPI.getOneTimeServiceSignUp(
          actorSignUpId
        );
        const services = response.data;
        services.forEach((item) => {
          if (
            item.oneTimeFee &&
            item.hasOneTimeFee &&
            moment(item.oneTimeFeeExpirationDate).isAfter(moment())
          ) {
            isValid = true;
            dispatch.servicesModel.setOneTimeService(item);
          }
        });
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        dispatch.servicesModel.setIsLoading(false);
      }
      return isValid;
    },
    getPaidServicesClient: async () => {
      dispatch.servicesModel.setIsLoading(true);
      try {
        const response = await ServicesAPI.getPaidServicesClient();
        dispatch.servicesModel.setServices(response.data);
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        dispatch.servicesModel.setIsLoading(false);
      }
    },
    isAgentFindBreakdown: async ({bdId, bdType} : any) => {
      try {
        const response = await JobsAPI.isAgentFindBreakdown(bdId, bdType);
        if (response.data && response.data.isAgentFind) {
          return true;
        }
      } catch (e) {
        Sentry.captureException(e);
      }
      return false;
    },
    getTrialUpgradeServices: async () => {
      dispatch.servicesModel.setIsLoading(true);
      try {
        const response = await ServicesAPI.getTrialUpgradeServices();
        dispatch.servicesModel.setTrialUpgradeServices(response.data);
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        dispatch.servicesModel.setIsLoading(false);
      }
    },
    getProductServices: async (serviceId) => {
      dispatch.servicesModel.setIsLoading(true);
      try {
        const response = await ServicesAPI.getProductServicesClient(serviceId);
        dispatch.servicesModel.setProductService(response.data);
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        dispatch.servicesModel.setIsLoading(false);
      }
    },
    getOneTimeServices: async () => {
      dispatch.servicesModel.setIsLoading(true);
      let isValid = false;
      try {
        const response = await ServicesAPI.getOneTimeServiceClient();
        const services = response.data;
        services.forEach((item) => {
          if (
            item.oneTimeFee &&
            item.hasOneTimeFee &&
            moment(item.oneTimeFeeExpirationDate).isAfter(moment())
          ) {
            isValid = true;
            dispatch.servicesModel.setOneTimeService(item);
          }
        });
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        dispatch.servicesModel.setIsLoading(false);
      }
      return isValid;
    },
  }),
});

export default servicesModel;
