import React from "react";
import styled from "styled-components";

interface Props {
  className?: string;
  [key: string]: any;
};

const Modal: React.FC<Props> = ({ children, className }) => {
  return (
    <StyledModal className={className}>
      {children}
    </StyledModal>
  );
};

const StyledModal = styled.div`
  background-color: ${p => p.theme.white};

  @media all and (min-width: 520px) {
    border-top: 6px solid ${p => p.theme.lightBlue};
    border-radius: 4px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
  }
`;

export default Modal;
