import React from 'react';
import qs from 'query-string';
import { withI18n } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Typography } from 'components/Common';
import Card from './components/Card';
import Header from './components/Header';
import Footer from './components/Footer';
import Page from './components/Page';
import CheckmarkCircle from 'images/checkmark_circle.svg';
import { connect } from 'react-redux';
import { getWebSessionKey } from "../../helpers";

type Props = {
  t: any;
} & StateProps & RouteComponentProps;

class SignUpSuccess extends React.Component<Props> {
  state = {
    numSeconds: 20
  };

  componentDidMount() {
    const tick = () => {
      if (this.state.numSeconds <= 0) {
        this.redirect();
      } else {
        setTimeout(() => {
          this.setState({
            numSeconds: this.state.numSeconds - 1
          }, tick);
        }, 1000);
      }
    };
    tick();
  }

  redirect = () => {
    const k = getWebSessionKey();
    const redirectObject:any = {
      cwblabs: true,
      redirect: 'cwblabs',
      ...(qs.parse(this.props.location.search)),
      signUp:1,
    }
    if(this.props.clientId){
      redirectObject.login = this.props.clientId;
    }
    if(k){
      redirectObject.k = k;
      const params = qs.stringify(redirectObject);
      window.location.assign(`${process.env.REACT_APP_CWB_Site}/login/go?${params}`);
    }else {
      const params = qs.stringify(redirectObject);
      window.location.assign(`${process.env.REACT_APP_CWB_Site}/login/?${params}`);
    }
  };

  render() {
    const { t } = this.props;
    const { numSeconds } = this.state;

    return (
      <>
        <Header />
        <Page>
          <StyledDiv>
            <StyledCard>
              <Icon src={CheckmarkCircle} />
              <Typography align="center" component="h1" variant="h3">
                {t('Thank you for signing up for a Casting Workbook Labs Account')}
              </Typography>
              <HorizontalSeparator />
              <StyledTypography align="center">
                {t('Your account has been created.')}
              </StyledTypography>
              <StyledTypography align="center">
                {t('You can now enjoy everything that Casting Workbook Labs has to offer! Please login to your account to begin exploring!')}
              </StyledTypography>
              <StyledButton onClick={this.redirect}>
                {t('Go to Casting Workbook Labs')}
              </StyledButton>
            </StyledCard>
            <StyledTypography
              style={{ width: '75%' }}
              align="center"
              color="white"
              component="span"
            >
              {t('You will automatically be redirected to the login screen, in')}
              &nbsp;
              <Counter>{numSeconds}</Counter>
              &nbsp;
              {t('seconds.')}
            </StyledTypography>
            <StyledFooter />
          </StyledDiv>
        </Page>
      </>
    );
  }
}

const StyledDiv = styled.div`
  width: 450px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: ${(p) => p.theme.spacing(5, 'auto', 0)};

  @media all and (max-width: 520px) {
    width: unset;
    margin-top: ${(p) => p.theme.spacing(3)};
    padding: ${(p) => p.theme.spacing(0, 2)};
  }
`;

const StyledCard = styled(Card)`
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing(3)};
  padding-top: ${(p) => p.theme.spacing(2.5)};
  padding-left: ${(p) => p.theme.spacing(7)};
  padding-right: ${(p) => p.theme.spacing(7)};

  @media all and (max-width: 520px) {
    padding-left: ${(p) => p.theme.spacing(3)};
    padding-right: ${(p) => p.theme.spacing(3)};
  }
`;

const Icon = styled.img`
  margin-bottom: ${(p) => p.theme.spacing(1)};
`;

const HorizontalSeparator = styled.div`
  width: 72px;
  height: 3px;
  margin: ${(p) => p.theme.spacing(1, 0, 2)};
  background: #02B8F9;
`;

const StyledTypography = styled(Typography)`
  margin: ${(p) => p.theme.spacing(0, 0, 2)};
  line-height: 18px;
`;

const StyledButton = styled(Button)`
  margin-top: ${(p) => p.theme.spacing(2)};
  padding: ${(p) => p.theme.spacing(1, 2)};
  background-color: #02B8F9;

  @media all and (max-width: 520px) {
    width: 100%;
  }
`;

const Counter = styled.span`
  text-decoration: underline;
`;

const StyledFooter = styled(Footer)`
  margin-top: ${(p) => p.theme.spacing(1)};
`;

type StateProps = {
  clientId: string;
};

const mapStateToProps = (state: any) => ({
  clientId: state.performerModel.clientId
});

export default withI18n()(connect(
  mapStateToProps,
  null
)(SignUpSuccess));
