import * as http from "../http";

const api = `${process.env.REACT_APP_Api}/actorjobs`;

export const getFilterResult = (sessionId:string, bdId:any, cityId:number, page:any) => {
  return http.get<any>(
    `${api}/api/signup/filter?sessionId=${sessionId}&bd=${bdId}&cityId=${cityId}&page=${page}`
  );
};

export const getBreakdownMediaType = (bdId:any, bdType: any) => {
  return http.get<any>(
    `${api}/api/jobs/mediatype?jobId=${bdId}&jobSubTypeId=${bdType}`
  );
};

export const isAgentFindBreakdown = (bdId:any, bdType: any) => {
  return http.get<any>(
    `${api}/api/jobs/agentFind?jobId=${bdId}&jobSubTypeId=${bdType}`
  );
};