import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withI18n } from 'react-i18next';
import styled from 'styled-components'
import { Button, ButtonGroup, Typography } from '../Common';
// @ts-ignore
import { CWBModal } from 'cwb-react';
import { Spinner } from "../Common/StyledComponents";

interface IProps extends  RouteComponentProps {
  t: any;
  history: any;
  location: any;
  onClose: () => void;
  open:boolean;
  handleUpgrade:() => void;
  freeSignUp:() => void;
  isLoading:boolean;
}

const PayAsYouGoModal: React.FC<IProps> = ({
  t,
  history,
  location,
  onClose: handleClose,
  open,
  handleUpgrade,
  freeSignUp,
  isLoading
}) => {

  return (
    <StyledCWBModal
      backdrop="static"
      isOpen={open}
      title={(
        <Typography
          key={0}
          align="left"
          component="span"
          variant="h2"
        >
          {t("'Lite Member' is NOT RECOMMENDED for represented talent.")}
        </Typography>
      )}
      buttons={(
        <ButtonGroup>
          <Button onClick={handleUpgrade} disabled={isLoading}>
            {t('Upgrade now')}
          </Button>
          <StyledButton
            variant="secondary"
            onClick={freeSignUp}
            disabled={isLoading}
          >
            {t('Lite Member')}
            {isLoading && <StyledSpinner src="/images/spinner_blue.svg" />}
          </StyledButton>
        </ButtonGroup>
      )}
      hideModal={handleClose}
    >
      <Typography
        color="medGrey"
        component="span"
      >
       {t("If you are self-represented, we will store your materials online and you can pay a submission fee to submit yourself for each role. Agents cannot use this service.If you want unlimited submissions and video links as well as access to free classes and the Working Actor Series library, you will want to choose an annual or monthly package.")}
      </Typography>
      &nbsp;
    </StyledCWBModal>
  );
};

const StyledCWBModal = styled(CWBModal)`
  & .modal-header { justify-content: space-between; }
  & .modal-body { text-align: left; }
  & .modal-footer .modal-buttons {
    justify-content: flex-start;
    margin: ${p => p.theme.spacing(0, 0, 1)};
  }
  border-top:6px solid #00AAFF;
  top:200px;
  max-width: 600px !important;
`;

const StyledSpinner = styled(Spinner)`
  margin-left: ${(p) => p.theme.spacing(0.5)};
`;


const StyledButton = styled(Button)`
font-weight: bold;
font-size: 14px;
line-height: 20px;
color: #0A1F44 !important;
`;



export default withI18n()(withRouter(PayAsYouGoModal));