import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getI18n, withI18n } from 'react-i18next';
import styled from 'styled-components';

// @ts-ignore
import * as Sentry from "@sentry/react";

interface Props extends StateProps, DispatchProps {
  t: any;
  declined:boolean;
}

const UpgradeTable: React.FC<Props> = ({
  t,
  declined
}) => {

  const defaultContent: any = [
    [
      <>{t("Without a premium membership, you’ll lose access to all of these important features...")}</>,
    ],
    [
      <><SpecialIcon src="../images/specialUpload.svg" />{t("Submit to all jobs and casting notices")}</>
    ],
    [
      <><SpecialIcon src="../images/specialSend.svg" />{t("Send Materials to anyone by email")}</>,
    ],
    [
      <><SpecialIcon src="../images/specialSchool.svg" />{t("Access to Monthly Classes with the Top 20 Acting Schools")}</>,
    ],
    [<><SpecialIcon src="../images/specialMedia.svg" />{t("Access to The Working Actor Episodes and library")}</>],
  ];

  const declinedContent :any = [
    [
      <>{t("Without a premium membership, you’ll lose access to these features...")}</>,
    ],
    [
      <><SpecialIcon src="../images/specialHeadshot.svg" />{t("Headshots")}</>
    ],
    [
      <><SpecialIcon src="../images/specialResume.svg" />{t("Resumes")}</>,
    ],
    [
      <><SpecialIcon src="../images/specialUpload.svg" />{t("Job Submissions")}</>,
    ],
    [<><SpecialIcon src="../images/specialPhotos.svg" />{t("Media Content (3GB of Data)")}</>],
    [<><SpecialIcon src="../images/specialPremium.svg" />{t("CWB Premium Content")}</>],
    [<><SpecialIcon src="../images/specialSend.svg" />{t("Send Materials / ePitch")}</>],
    [<><SpecialIcon src="../images/specialScript.svg" />{t("Scripts & Sides")}</>],
    [<><SpecialIcon src="../images/specialMedia.svg" />{t("The Working Actor Episodes")}</>],
    [<><SpecialIcon src="../images/specialSchool.svg" />{t("Monthly Class Top 20 Acting Schools")}</>],
  ];

  const tableContent = declined? declinedContent:defaultContent;

  return <StyledTable style={{width:declined?'580px':'620px'}}>
      <thead>
        <tr>
          {tableContent[0].map((tableHeader: any, index: number) => (
            <th key={index}>
              {tableHeader}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableContent.slice(1).map((row: any, index: number) => (
          <tr key={index}>
            {row.map((tableData: any, index: number) => (
              <td key={index}>
                {tableData}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
};

const StyledTable = styled.table`
  width:100%;

  & > thead > tr {
    color: #353B48;
    background-color: #f8f8f8;
    border-bottom: 1px solid #f1f2f4;
    border-radius: 3px 3px 0 0;
  }

  & > tbody > tr {
    color: #53627C;
    &:nth-child(even) { background-color: #f1f2f4; }
  }

  & th, & td {
    font-size: 14px;
    padding:0 16px;
  }

  & tr { height: 53px; }
  & th { font-weight: bold; }
`;


const SpecialIcon = styled.img`
width: 20px;
height: 20px;
margin-right:16px;
`;


interface StateProps {
}

const mapStateToProps = (state: any) => ({
});

interface DispatchProps {
  getSubscriptionServices: () => void;
  getDiscountSubscriptionServices: (promo:string) => void;
}

const mapDispatchToProps = (dispatch: any) => ({
  getSubscriptionServices: dispatch.premiumModel.getSubscriptionServices,
  getDiscountSubscriptionServices: dispatch.premiumModel.getDiscountSubscriptionServices
});

export default withI18n()(connect(
  mapStateToProps,
  mapDispatchToProps
)(UpgradeTable));
