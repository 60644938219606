import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import Routes from "./Routes";
import CWBRTheme from "./Theme/CWBRSTheme";
import { setLanguage } from "./service/UserService";

const App: React.FC = () => {
  setLanguage();

  return (
    <ThemeProvider theme={CWBRTheme}>
      <Wrapper>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </Wrapper>
    </ThemeProvider>
  );
};

const Wrapper = styled.div`
  * {
    font-family: ${(p) => p.theme["font-family"]};
  }

  a {
    color: ${(p) => p.theme.btnDarkBlue};
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
`;

export default App;
