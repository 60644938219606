import * as http from "../http";
// @ts-ignore
import { getAuthConfig } from "cwb-react";
import { IPaymentDetailsDto } from "./dtos/IPaymentDetailsDto";
import { ICustomerPortalDto } from "./dtos/ICustomerPortalDto";

const api = `${process.env.REACT_APP_REG_Api}/StripeCustomer`;

export const getCustomerPaymentDetails = () => {
  const options = {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      CWB: "1",
      ...getAuthConfig().headers,
    },
  } as RequestInit;

  return http.get<IPaymentDetailsDto>(`${api}/payment/details`, options);
};

export const getCustomerPortal = () => {
  const options = {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      CWB: "1",
      ...getAuthConfig().headers,
    },
  } as RequestInit;

  return http.get<ICustomerPortalDto>(`${api}/portal`, options);
};
