import React from "react";
import qs from "query-string";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";

import {
  getCurUserSignUp,
  getSessionId,
  getCountryCode,
} from "../../helpers/index";
// @ts-ignore
import { CWBDropdown } from "cwb-react";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import {
  ICastingError,
  ICastingFilmCenter,
} from "../../shared/api/dtos/ICastingDtos";
import { iRootState } from "../../store";
import Footer from "../Common/Footer";
import Modal from "../Common/Modal";
import PageContainer from "../Common/PageContainer";
import {
  ErrorText,
  CheckboxWrapper,
  Section,
  SectionTitle,
  StyledCheckbox,
  Divider,
  StyledBlueBtn,
  LargeText,
  Spinner,
} from "../Common/StyledComponents";
import { IGroupedCountry } from "../../helpers/ICountryList";

interface IProps extends StateProps, DispatchProps, RouteComponentProps<any> {
  t?: any;
}

interface IState {
  fields: any;
  isFormSubmitClicked: boolean;
  cityList: any[];
}

//@ ts-check
// TODO: Move style and JSDOM out of container compnent  into Presentational Components
class CastingFilmCenterStep extends React.Component<IProps, IState> {
  public state: IState = {
    fields: {
      onCamera: false,
      voice: false,
      country: "",
      city: "",
      cityId: 1,
      countryId: 1,
    },
    isFormSubmitClicked: false,
    cityList: [],
  };

  async componentDidMount() {
    const user = getCurUserSignUp();
    if (getSessionId() && user) {
      if (this.props.groupCountryList.length <= 0)
        await this.props.getGroupCountryList();

      const userfilmCenter = user!.castingFilmCenter;

      if (userfilmCenter) this.mapUserToStateFields(userfilmCenter);
      else {
        const grpCountries = this.props.groupCountryList;
        let country = grpCountries[0];

        // To test below code. Install Opera. In settings, turn on VPN.
        if (getCountryCode() && getCountryCode()?.toUpperCase() !== "CA")
          country = grpCountries.find((x) => x.country.countryCode?.toUpperCase() === "US");

        this.setCountry(country);
      }
    } else {
      window.location.replace("/");
    }
  }

  mapUserToStateFields = (userfilmCenter: ICastingFilmCenter) => {
    if (!userfilmCenter) return;
    let fields: ICastingFilmCenter = this.state.fields;

    fields.city = userfilmCenter.city;
    fields.cityId = userfilmCenter.cityId;
    fields.countryId = userfilmCenter.countryId;
    fields.onCamera = userfilmCenter ? userfilmCenter.onCamera : false;
    fields.voice = userfilmCenter ? userfilmCenter.voice : false;

    this.setState({ fields });

    const grpCountries = this.props.groupCountryList;
    const country = grpCountries.find(
      (x) => x.value === userfilmCenter.country
    );
    this.setCountry(country);
  };

  checkValidation(): boolean {
    const { t } = this.props;
    let fields: ICastingFilmCenter = this.state.fields;
    let errors: ICastingError = {} as ICastingError;

    //projectTypeToCast
    if (!fields.onCamera && !fields.voice)
      errors.OnCamera = t("Select at least one type");

    //country
    if (!fields.country) errors.Country = t("Country field is required");

    //city
    if (!fields.city) errors.City = t("City field is required");

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  continueAccountSetUp = async (e: any) => {
    e.preventDefault();
    this.setState({ isFormSubmitClicked: true });
    const isProducer =
      qs.parse(this.props.location.search).subType === "producer";
    let dto: ICastingFilmCenter = this.state.fields;

    if (this.checkValidation()) {
      await this.props.saveCastingFilmCenter(dto);
      if (isProducer) {
        await this.props.createCastingProducer();
        return this.moveToNext("/casting/limited");
      }
      this.moveToNext("/casting/2");
    }
  };

  moveToNext = (path?: string) => {
    if (this.props.errors.errorMessage)
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: path,
        search: this.props.location.search,
      });
    }
  };

  setCountry = (option: any) => {
    let fields: ICastingFilmCenter = this.state.fields;
    // TODO:  create inteface for Select option items
    try {
      const cityList = option.cityList
        ? option.cityList.map((city: any) => ({
            label: city.cityName,
            value: city.cityName,
            id: city.cityId,
            city,
          }))
        : [];
      fields.country = option.value;
      fields.countryId = option.id;
      this.setState({ fields });
      this.setState({ cityList });
    } catch (err) {
      // nothing needed here... an unhandled error sometimes occurs if the country.cities are not loaded yet and the user click the city dropdown
    }
  };

  handleChange = (field: string, option: any) => {
    let fields = this.state.fields;
    fields[field] = option;
    this.setState({ fields });
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  handleCity = (option: any) => {
    let fields: ICastingFilmCenter = this.state.fields;
    fields.city = option.value;
    fields.cityId = option.id;
    this.setState({ fields });
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  handleCountry = (option: any) => {
    this.setCountry(option);
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  render() {
    const { t } = this.props;
    const errors: ICastingError = this.props.errors;
    const fields: ICastingFilmCenter = this.state.fields;
    const city = this.state.cityList.find((x) => x.id === fields.cityId);
    const country = this.props.groupCountryList.find(
      (x) => x.id === fields.countryId
    );
    return (
      <PageContainer>
        <Wrapper>
          <StyledTitle>{t("Customize your account")}</StyledTitle>
          <Section>
            <SectionTitle>
              {t("What type of projects will you be casting?")}
            </SectionTitle>
            <StyledSectionDiv>
              <CheckboxWrapper
                onClick={() => this.handleChange("onCamera", !fields.onCamera)}
              >
                <StyledCheckbox
                  className="small-cb"
                  isChecked={fields.onCamera}
                />
                <StyledImg alt="camera" src="../images/camera.svg" />
                <StyledCbLabel>{t("On-Camera")}</StyledCbLabel>
              </CheckboxWrapper>
              <CheckboxWrapper
                onClick={() => this.handleChange("voice", !fields.voice)}
              >
                <StyledCheckbox className="small-cb" isChecked={fields.voice} />
                <StyledImg alt="voice" src="../images/voice.svg" />
                <StyledCbLabel>{t("Voice")}</StyledCbLabel>
              </CheckboxWrapper>
            </StyledSectionDiv>
            <ErrorText>{errors.OnCamera}</ErrorText>
          </Section>
          <Divider></Divider>
          <Section className="margin-bottom-none">
            <LargeText>{t("Film center")}</LargeText>
            <FilmCenterContent>
              {t("Select your closest location from the list below.")}
            </FilmCenterContent>
          </Section>
          <Section className="margin-bottom-none">
            <SectionTitle>{t("Choose your country")}</SectionTitle>
            <CWBDropdown
              value={country}
              isValueSelected={true}
              placeholder={t("Select Country")}
              items={this.props.groupCountryList}
              onChange={(option: any) => this.handleCountry(option)}
            />
            <ErrorText>{errors.Country}</ErrorText>
          </Section>
          <Section className="margin-bottom-none">
            <SectionTitle>{t("Choose your nearest city")}</SectionTitle>
            <CWBDropdown
              name="city"
              value={city || null}
              isValueSelected={true}
              placeholder={t("Select an Option")}
              items={this.state.cityList ? this.state.cityList : []}
              onChange={(option: any) => this.handleCity(option)}
            />
            <ErrorText>{errors.City}</ErrorText>
          </Section>
          <ErrorText>{errors.errorMessage}</ErrorText>
          <Section>
            <StyledBlueBtn
              onClick={this.continueAccountSetUp}
              disabled={this.props.isLoading}
            >
              {t("Continue")}
              {this.props.isLoading && <Spinner src="../images/spinner.svg" />}
            </StyledBlueBtn>
          </Section>
        </Wrapper>
        <Footer />
      </PageContainer>
    );
  }
}

interface StateProps {
  errors: ICastingError;
  groupCountryList: IGroupedCountry[]; // TODO: add type
  isLoading: boolean;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    errors: state.castingModel.errors,
    groupCountryList: state.referencesModel.groupCountryList,
    isLoading: state.castingModel.isLoading,
  };
}

interface DispatchProps {
  setCurrentUser: (currentUser: any) => void;
  saveCastingFilmCenter: (fields: any) => void;
  createCastingProducer: () => void;
  getGroupCountryList: () => void;
  setErrors: (errors: ICastingError) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    setCurrentUser: dispatch.castingModel.setCurrentUser,
    saveCastingFilmCenter: dispatch.castingModel.saveCastingFilmCenter,
    createCastingProducer: dispatch.castingModel.createCastingProducer,
    getGroupCountryList: dispatch.referencesModel.getGroupCountryList,
    setErrors: dispatch.castingModel.setErrors,
  };
}

export default withI18n()(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CastingFilmCenterStep)
  )
);

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-weight: ${(p) => p.theme["font-weight-600"]};

  max-width: 480px;
  min-width: 480px;
  background-color: ${(p) => p.theme.white};
  padding: 40px 80px;

  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 40px;
  }

  .cwb-dropdown-wrapper {
    margin-top: 8px;
    max-width: 100%;
    height: 40px;

    .cwb-dropdown__single-value {
      font-size: ${(p) => p.theme["xs-font-size"]};
    }

    .cwb-dropdown__indicator {
      width: 14px;
    }
  }

  .cwb-dropdown__placeholder {
    color: ${(p) => p.theme.midGrey};
    font-size: ${(p) => p.theme["xs-font-size"]};
    font-weight: ${(p) => p.theme["font-weight-600"]};
  }

  .cwb-dropdown-wrapper > div {
    min-width: 100%;
    max-width: 100%;
  }

  .cwb-dropdown-wrapper > div .cwb-dropdown__control {
    min-height: 40px;
    border: ${(props) => props.theme.dropDown["border"]} !important;
    border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  }
`;

const StyledTitle = styled.div`
  font-size: ${(p) => p.theme["xxl-font-size"]};
  margin-bottom: 8px;
`;

const StyledSectionDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

const StyledCbLabel = styled.span`
  font-size: ${(p) => p.theme["xxs-font-size"]};
  letter-spacing: 0.2px;
  margin-left: 4px;
`;

const FilmCenterContent = styled.div`
  color: ${(p) => p.theme.color} !important;
  font-size: ${(p) => p.theme["s-font-size"]};
  font-weight: normal;
`;

const StyledImg = styled.img`
  margin: ${(p) => p.theme.spacing(0, 0, 0.25, 1)};
`;
