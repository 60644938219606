import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getI18n, withI18n } from "react-i18next";
import styled from "styled-components";

import { Skeleton } from "components/Common";
import Tooltip from "../Common/Tooltip";
import { IPremiumFeatureComparison } from "../../shared/api/dtos/IPremiumDto";
import { timedSentrySendThenErrorPage } from "helpers";

interface Props extends StateProps, DispatchProps {
  t: any;
  i18n: any;
  promo?: any;
}

const UpgradeTableNew: React.FC<Props> = ({
  t,
  i18n,
  monthlyCharge,
  yearlyPerMonthCharge,
  yearlyCharge,
  headshot,
  resume,
  mediaLimit,
  getSubscriptionServices,
  getDiscountSubscriptionServices,
  currencySymbol,
  promo,
  postCurrencySymbol,
  bdSubmissionCharge,
  isJobSubmissionFree
}) => {
  useEffect(() => {
    const fetchSubscriptionServices = async () => {
      try {
        await getSubscriptionServices();
      } catch (e) {
        timedSentrySendThenErrorPage(e);
      }
    };

    const fetchDiscountSubscriptionServices = async () => {
      try {
        await getDiscountSubscriptionServices(promo);
      } catch (e) {
        timedSentrySendThenErrorPage(e);
      }
    };

    if (promo) {
      fetchDiscountSubscriptionServices();
    } else {
      fetchSubscriptionServices();
    }
  }, [getSubscriptionServices, getDiscountSubscriptionServices, promo]);

  const isMobile = window.matchMedia("(max-width: 520px)").matches;

  const tickIcon = <Icon alt="check" src="../images/icon-tick.svg" />;

  const getServiceNum = (num: number) => {
    if (num >= 99) return tickIcon;
    return num;
  };

  let charge = yearlyPerMonthCharge?.toString() ?? "";
  if (currencySymbol === "€" && yearlyPerMonthCharge) {
    charge = yearlyPerMonthCharge.toString().replace(".", ",");
  }

  let chargeOnce = bdSubmissionCharge?.toString();
  if (!chargeOnce) {
    chargeOnce = "2.99";
  }

  if (currencySymbol === "€") {
    chargeOnce = chargeOnce.replace('.', ',');
  }

  const ProMember = (
    <>
      <div>{t("Pro Member")}</div>
      <PriceTag>
        {t("as low as {{currencySymbol}}{{charge}}/mo", {
          currencySymbol,
          charge,
          postCurrencySymbol
        })}
      </PriceTag>
    </>
  );

  const tableContent: any = [
    [
      t("What you get"),
      t("Lite Member"),
      isMobile
        ? t("Pro") // eslint-disable-next-line
        : ProMember,
    ],
    [
      t("Headshots"),
      getServiceNum(headshot.free),
      getServiceNum(headshot.premium),
    ],
    [t("Resumes"), 2, t("Unlimited")],
    [
      <>{t("Job Submissions")}</>,
      (isJobSubmissionFree || i18n.language == "es")  ?
        (<>
          {t("Unlimited")}
          {/* <StyledTooltip
           placement="top"
           tip={t("Offer subject to availability and may change or expire at any time")}
          >
            <img alt="more info" src="../images/info.svg" />
          </StyledTooltip> */}
        </>) :
        // (<>{t("{{currencySymbol}}{{chargeOnce}}/Submission", {
        //   currencySymbol,
        //   chargeOnce,
        //   postCurrencySymbol
        // })}
        // </>)
        "-"
        ,t("Unlimited"),
    ],
    [t("ACTOR.APP"), tickIcon, tickIcon],
    [t("The Working Actor Series"), tickIcon, tickIcon],
    [t("Demo Reels(unlimited links)"), "-", tickIcon],
    [t("Free Acting Classes"), "-", tickIcon],
    [t("Self Tapes"), t("If requested"), tickIcon],
  ];

  const isLoading = !(
    monthlyCharge ||
    yearlyCharge ||
    headshot.free ||
    headshot.premium ||
    resume.free ||
    resume.premium ||
    mediaLimit
  );

  return (
    <StyledTable>
      <thead>
        <tr>
          {tableContent[0].map((tableHeader: any, index: number) => (
            <th key={index}>
              {isLoading ? <StyledSkeleton height={18} /> : tableHeader}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableContent.slice(1).map((row: any, index: number) => (
          <tr key={index}>
            {row.map((tableData: any, index: number) => (
              <td key={index}>
                {isLoading ? <StyledSkeleton height={18} /> : tableData}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  width: 100%;

  & > thead > tr {
    color: #353B48;
    background-color: #f8f8f8;
    border-bottom: 1px solid #f1f2f4;
    border-radius: 3px 3px 0 0;
  }

  & > tbody > tr {
    color: #53627C;
    &:nth-child(even) { background-color: #f1f2f4; }
  }

  & th, & td {
    font-size: 14px;
    &:first-child {
      width: 265px;
      padding-left: 16px;
      @media all and (max-width: 520px) {
        width: 190px;
        padding-left 8px;
      }
    }
    &:not(:first-child) { text-align: center; }
  }

  & tr { height: 53px; }
  & th { font-weight: bold; }
  & td { font-weight: 600; }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  padding: 4px;
  background-color: ${(p) => p.theme.lightBlue};
  border-radius: 50%;
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: 5px;
  & > img {
    width: 18px;
    height: 18px;
    margin-top: -2px;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  width: 80%;
`;

const PriceTag = styled.div`
  font-weight: normal;
`;

interface StateProps {
  monthlyCharge: number;
  yearlyCharge: number;
  yearlyPerMonthCharge: number;
  headshot: IPremiumFeatureComparison;
  resume: IPremiumFeatureComparison;
  mediaLimit: number;
  currencySymbol: string;
  postCurrencySymbol?: string,
  bdSubmissionCharge?: any
  isJobSubmissionFree?: boolean
}

const mapStateToProps = (state: any) => {
  let postCurrencySymbol = "";
  if (state.premiumModel.postCurrencySymbol) {
    postCurrencySymbol = " " + state.premiumModel.postCurrencySymbol.toString();
  }

  return {
    monthlyCharge: state.premiumModel.monthlyCharge,
    yearlyCharge: state.premiumModel.yearlyCharge,
    yearlyPerMonthCharge: state.premiumModel.yearlyPerMonthCharge,
    headshot: state.premiumModel.headshot,
    resume: state.premiumModel.resume,
    mediaLimit: state.premiumModel.mediaLimit,
    currencySymbol: state.premiumModel.currencySymbol,
    postCurrencySymbol: postCurrencySymbol,
    bdSubmissionCharge: state.premiumModel.bdSubmissionCharge,
    isJobSubmissionFree: state.premiumModel.isJobSubmissionFree
  };
}

interface DispatchProps {
  getSubscriptionServices: () => void;
  getDiscountSubscriptionServices: (promo: string) => void;
}

const mapDispatchToProps = (dispatch: any) => ({
  getSubscriptionServices: dispatch.premiumModel.getSubscriptionServices,
  getDiscountSubscriptionServices:
    dispatch.premiumModel.getDiscountSubscriptionServices,
});

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(UpgradeTableNew)
);
