import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withI18n } from 'react-i18next';
import styled from 'styled-components'
import { Button, ButtonGroup, Typography } from '../Common';
import {
  Spinner,
} from "../Common/StyledComponents";
import qs from "query-string";
// @ts-ignore
import { CWBModal } from 'cwb-react';
import { iRootState } from "../../store";
import { connect } from "react-redux";

interface IProps extends  StateProps, RouteComponentProps {
  t: any;
  history: any;
  location: any;
  isLoading:boolean;
  onClose: () => void;
  handleUpgrade:() => void;
  toHomePage:() => void;
}

const ResidenceModal: React.FC<IProps> = ({
  t,
  history,
  location,
  onClose: handleClose,
  handleUpgrade,
  toHomePage,
  isLoading
}) => {

  return (
    <StyledCWBModal
      backdrop="static"
      isOpen={true}
      buttons={(
        <ButtonGroup>

          <StyledButton onClick={handleUpgrade}>
            {t('Continue')}
            {isLoading && (
                    <StyledSpinner
                      src="/images/spinner.svg"
                      className="spinner-width"
                    />
                  )}
          </StyledButton>
          <Button
            variant="secondary"
            onClick={toHomePage}
          >
            {t('Cancel')}
          </Button>
        </ButtonGroup>
      )}
      hideModal={handleClose}
    >
      <Typography
        color="medGrey"
        component="span"
      >
        {t("Casting has not requested submission from your area. Continue to get your account and submit to other projects or cancel now.")}
      </Typography>
      &nbsp;
    </StyledCWBModal>
  );
};

const StyledCWBModal = styled(CWBModal)`
  & .modal-header { justify-content: space-between; }
  & .modal-body { text-align: left; }
  & .modal-footer .modal-buttons {
    justify-content: flex-start;
    margin: ${p => p.theme.spacing(0, 0, 1)};
  }
  border-top:6px solid #00AAFF;
  top:150px;
  max-width: 600px !important;
`;

interface StateProps {
  isLoading: boolean;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    isLoading: state.actorModel.isLoading,
  };
}

const StyledButton = styled(Button)`
  width: 100%;
  height: 37px;
  font-size: 13px;
  font-weight: 600;
`;

const StyledSpinner = styled(Spinner)`
width:16px !important;
`;
export default withI18n()(
  withRouter(connect(mapStateToProps)(ResidenceModal)));
