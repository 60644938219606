import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withI18n } from 'react-i18next';
import styled from 'styled-components'
import { Button, ButtonGroup, Typography } from '../Common';
// @ts-ignore
import { CWBModal } from 'cwb-react';
import { Spinner } from "../Common/StyledComponents";

interface IProps extends  RouteComponentProps {
  t: any;
  history: any;
  location: any;
  onClose: () => void;
  open:boolean;
  isLoading:boolean;
}

const AgentFindModal: React.FC<IProps> = ({
  t,
  history,
  location,
  onClose: handleClose,
  open,
  isLoading
}) => {

  return (
    <StyledCWBModal
      backdrop="static"
      isOpen={open}
      title={(
        <Typography
          key={0}
          align="left"
          component="span"
          variant="h2"
        >
          {t("Upgrade to Pro and get AgentFind for one flat fee")}
        </Typography>
      )}
      buttons={(
        <ButtonGroup>
          <StyledButton
            variant="secondary"
            onClick={handleClose}
            disabled={isLoading}
          >
            {t('OK')}
            {isLoading && <StyledSpinner src="/images/spinner_blue.svg" />}
          </StyledButton>
        </ButtonGroup>
      )}
      hideModal={handleClose}
    >
   <AgentFindPanel dangerouslySetInnerHTML={{__html: t(`AgentFindMiddle`) }}>
   </AgentFindPanel>
      &nbsp;
    </StyledCWBModal>
  );
};

const StyledCWBModal = styled(CWBModal)`
  & .modal-header { justify-content: space-between; }
  & .modal-body { text-align: left; }
  & .modal-footer .modal-buttons {
    justify-content: center;
    margin: ${p => p.theme.spacing(0, 0, 1)};
  }
  border-top:6px solid #00AAFF;
  top:200px;
  max-width: 600px !important;
`;

const StyledSpinner = styled(Spinner)`
  margin-left: ${(p) => p.theme.spacing(0.5)};
`;


const StyledButton = styled(Button)`
font-weight: bold;
font-size: 14px;
line-height: 20px;
color: #0A1F44 !important;
`;


const AgentFindPanel = styled.div`
color: #495A6E;
p {
  margin-top: -4px;
  font-size: 16px !important;
}

ul {
  padding-inline-start: 24px;
  margin-bottom: 0;
  padding-bottom: 0;
}

li{
  font-size: 14px;
}
li::marker {
  color:#0A1F44;
  font-weight:bold;
}
`

export default withI18n()(withRouter(AgentFindModal));