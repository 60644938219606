import React, { MouseEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

type Props = {
  id?: string;
  className?: string;
  style?: any;
  disabled?: boolean;
  startIcon?: ReactNode;
  type?: 'submit' | 'button' | 'reset';
  variant?: 'primary' | 'secondary' | 'textPrimary' | 'textSecondary';
  onClick?: (event: MouseEvent) => void;
};

const Button: React.FC<Props> = ({
  children,
  id,
  className,
  style,
  disabled = false,
  startIcon,
  type = 'submit',
  variant = 'primary',
  onClick
}) => {
  const props = {
    id,
    className,
    style,
    disabled,
    isChildNodeString: typeof children === 'string',
    startIcon,
    type,
    variant,
    onClick
  };

  return (
    <StyledButton {...props}>
      {startIcon}
      {children}
    </StyledButton>
  );
};

const baseStyles: any = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${p => p.theme.spacing(1, 2)};
  border: none;
  border-radius: 4px;

  &:focus { outline: none; }
`;

const StyledButton = styled.button<Props & { isChildNodeString: boolean; }>`
  ${baseStyles}

  ${p => p.startIcon && css`
    & > :first-child { margin: -0.5px 5px 0.5px 0; }
  `}

  ${p => p.isChildNodeString && css`
    font-size: ${p => p.theme.typography.fontSize.button};
    font-weight: ${p => p.theme.typography.fontWeight.bold};
    line-height: ${p => p.theme.typography.lineHeight.button};
  `}

  ${p => p.variant === 'primary' && css`
    color: ${p => p.theme.palette.common.white};
    background-color: ${p => p.theme.palette.button.normal};

    &:hover {
      background-color: ${p => p.theme.palette.button.hover};
    }

    &:active {
      background-color: ${p => p.theme.palette.button.active};
    }

    &:disabled {
      color: ${p => p.theme.palette.common.white};
      background-color: ${p => p.theme.palette.misc.disabled};
      cursor: not-allowed;
    }
  `}

  ${p => p.variant === 'secondary' && css`
    color: ${p => p.theme.palette.grey[7]};
    background-color: ${p => p.theme.palette.common.white};
    border: 1px solid ${p => p.theme.palette.button.normal};

    &:hover {
      color: ${p => p.theme.palette.button.hover};
    }

    &:active {
      color: ${p => p.theme.palette.button.active};
    }

    &:disabled {
      color: ${p => p.theme.palette.grey[4]};
      background-color: ${p => p.theme.palette.common.white};
      border-color: ${p => p.theme.palette.grey[3]};
      cursor: not-allowed;
    }
  `}

  ${p => p.variant === 'textPrimary' && css`
    width: max-content;
    height: max-content;
    color: ${p => p.theme.palette.grey[7]};
    margin-top: auto;
    margin-bottom: auto;
    padding: 0;
    background-color: ${p => p.theme.palette.common.transparent};

    &:active {
      color: ${p => p.theme.palette.misc.darkBlue};
    }

    &:disabled {
      color: ${p => p.theme.palette.grey[4]};
      cursor: not-allowed;
    }
  `}

  ${p => p.variant === 'textSecondary' && css`
    width: max-content;
    height: max-content;
    color: ${p => p.theme.palette.grey[7]};
    margin-top: auto;
    margin-bottom: auto;
    padding: 0;
    background-color: ${p => p.theme.palette.common.transparent};
    font-weight: ${p => p.theme.typography.fontWeight.normal};
    text-decoration: underline;

    &:active {
      color: ${p => p.theme.palette.misc.darkBlue};
    }

    &:disabled {
      color: ${p => p.theme.palette.grey[4]};
      cursor: not-allowed;
    }
  `}
`;

export default Button;
