import React from "react";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import Footer from "../Common/Footer";
import Modal from "../Common/Modal";
import PageContainer from "../Common/PageContainer";

const SuccessSignUpBizhours = (props: { t: any }) => {
  const { t } = props;
  return (
    <PageContainer>
      <Wrapper>
        <StyledTitle>{t("Thank you for signing up")}</StyledTitle>
        <SubTitle>
          {t(
            "A customer service representative will be in touch via email shortly to help you verify your account and get started."
          )}
        </SubTitle>
        <TimeInfo>
          <Text>{t("Business Hours")}</Text>
          <Divider></Divider>
          <div className="d-flex justify-content-between">
            <Text>{t("West Coast")}</Text>
            <Text>{t("Mon to Fri, 9-6")}</Text>
          </div>
          <div className="d-flex justify-content-between">
            <Text>{t("East Coast")}</Text>
            <Text>{t("Mon to Fri, 9-5")}</Text>
          </div>
          <div className="d-flex justify-content-between">
            <Text>{t("UK")}</Text>
            <Text>{t("Mon to Fri, 9-5")}</Text>
          </div>
          <div className="d-flex justify-content-between">
            <Text>{t("Spain")}</Text>
            <Text>{t("Mon to Fri, 9-6")}</Text>
          </div>
        </TimeInfo>
      </Wrapper>
      <Footer />
    </PageContainer>
  );
};

export default withI18n()(SuccessSignUpBizhours);

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;

  * {
    color: ${p => p.theme.color} !important;
    font-size: ${p => p.theme["s-font-size"]};
  }

  max-width: 540px;
  min-width: 540px;
  background-color: ${p => p.theme.white};
  padding: 40px 80px;

  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 40px;
  }
`;

const StyledTitle = styled.div`
  color: ${p => p.theme.darkGrey} !important;
  font-size: ${p => p.theme["xxl-font-size"]};
  font-weight: ${p => p.theme["font-weight-600"]};
`;

const SubTitle = styled.div`
  margin-top: 8px;
  text-align: center;
`;

const TimeInfo = styled.div`
  min-width: 305px;
  background-color: #f8f8f9;
  border-radius: 4px;
  padding: 8px 40px 18px 40px;
  margin-top: 24px;
  @media all and (max-width: 520px) {
    width: 90%;
    min-width: 40%;
  }
`;

const Text = styled.div`
  margin-top: 8px;
`;

const Divider = styled.div`
  margin-top: 8px;
  height: 1px;
  width: 100%;
  background-color: ${props => props.theme.dropDown["border-color"]};
`;
