import React from "react";
import { withI18n } from "react-i18next";
import styled, { css } from "styled-components";
import { connect } from "react-redux";

import {
  
  getCurUserSignUp,
  getSessionId,
} from "../../../helpers";
import { isValidEmail } from "helpers/validations";
import Footer from "../../Common/Footer";
import Modal from "../../Common/Modal";
import PageContainer from "../../Common/PageContainer";
import {
  Section,
  StyledBlueBtn,
  SectionTitle,
  SmallTextError,
  Spinner,
} from "../../Common/StyledComponents";
import { iRootState } from "../../../store";
import {
  IActorError,
  IActorAccountDto,
  IActorEmail,
} from "../../../shared/api/dtos/IActorDto";
import { Typography } from "components/Common";

import qs from "query-string";

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location?: any;
}

interface IState {
  canResend: boolean;
  isFormSubmitClicked: boolean;
  fields: IActorEmail;
  emailChanging: boolean;
  newEmail: string;
}

class ActorVerifyEmailStep extends React.Component<IProps, IState> {
  public state: IState = {
    canResend: true,
    emailChanging: false,
    isFormSubmitClicked: false,
    fields: { email: "", code: "" },
    newEmail: "",
  };

  componentDidMount() {
    const sessionId = getSessionId();
    if (!sessionId) {
      window.location.replace(process.env.REACT_APP_CWB_500);
    }
    const user: IActorAccountDto = getCurUserSignUp();
    if (user && !this.state.fields.email)
      this.setState({
        fields: {
          ...this.state.fields,
          email: user.actorCredentials.email,
        },
      });
  }

  checkValidation() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IActorError;

    if (
      !fields.code ||
      !fields.code.match(/^\d{4}/i) ||
      fields.code.length !== 6
    ) {
      errors.Code = t(
        "Invalid code, only numbers and a code length of {{length}} is expected.",
        { length: 6 }
      );
      fields.code = "";
      this.setState({ fields });
    }

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  checkEmail() {
    const { t } = this.props;
    const { newEmail } = this.state;
    let errors = {} as IActorError;

    if (!newEmail || !isValidEmail(newEmail)) {
      errors.NewEmail = t("Email is invalid.");
      this.setState({ newEmail: "" });
    }

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  checkNumber() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IActorError;

    if (!fields.code || fields.code.match(/^[0-9`]+$/i)) {
      this.props.setErrors(errors);
      return true;
    }

    errors.Code = t("Only numbers are accepted");
    fields.code = "";
    this.setState({ fields });
    this.props.setErrors(errors);
    return false;
  }

  handleChange = (e: any) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    this.checkNumber();
  };

  handleChangeNewEmail = (e: any) => {
    this.setState({
      newEmail: e.target.value,
    });
  };

  verifyEmail = async (e: any) => {
    e.preventDefault();
    this.setState({ isFormSubmitClicked: true });
    if (!this.checkValidation()) return;

    const qsArgs = qs.parse(window.location.search);
    await this.props.verifyActorEmail({code: this.state.fields.code, dev: qsArgs.dev});
    this.moveToNext();
  };

  resendCode = async (e: any, email?: any) => {
    e.preventDefault();
    if (!this.state.canResend) return;
    const user: IActorAccountDto = getCurUserSignUp();
    if (!user) window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    this.setState({ canResend: false });
    let ae = this.state.fields;
    if (email) ae.email = email;
    await this.props.verifyEmailResendCode(ae);

    setTimeout(() => this.setState({ canResend: true }), 10 * 1000);
  };

  changeEmail = () => {
    this.setState({
      emailChanging: true,
    });
  };

  cancelChangeEmail = () => {
    this.setState({
      emailChanging: false,
    });
  };

  confirmChange = async (e: any) => {
    e.preventDefault();
    const user: IActorAccountDto = getCurUserSignUp();
    if (!user) window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    const { newEmail } = this.state;
    if (!this.checkEmail()) return;
    this.setState({
      fields: {
        ...this.state.fields,
        email: newEmail,
      },
      emailChanging: false,
      newEmail: "",
    });
    await this.resendCode(e, newEmail);
  };

  changeVerifyMethod = () => {
    this.props.setSelectPhone(true);
  };

  /* resendCode = async (e: any) => {
    e.preventDefault();
    if (!this.state.canResend) return;
    const user: IActorAccountDto = getCurUserSignUp();
    if (!user) window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    this.setState({ canResend: false });
    await this.props.createActorPhone(user.actorPhone);
    setTimeout(() => this.setState({ canResend: true }), 10 * 1000);
  }; */

  /* changePhone = () => {
    this.props.history.goBack();
  }; */

  moveToNext = () => {
    if (this.props.errors.errorMessage)
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/actor/3",
        search: this.props.location.search,
      });
    }
  };

  render() {
    const { t, location } = this.props;
    const { canResend, fields, emailChanging, newEmail } = this.state;

    return (
      <PageContainer>
        {emailChanging ? (
          <Wrapper>
            <Title>{t("Change your email")}</Title>
            <Section>
              <SectionTitle>{t("Existing Email")}</SectionTitle>
              <StyledInputDisable value={fields.email} disabled />
            </Section>
            <Section>
              <SectionTitle>{t("New Email")}</SectionTitle>
              <StyledInput
                value={newEmail}
                type="email"
                onChange={this.handleChangeNewEmail}
              />
            </Section>
            <SmallTextError className="error">
              <span>{this.props.errors.NewEmail}</span>
            </SmallTextError>
            <Section>
              <StyledBlueBtn onClick={this.confirmChange} disabled={this.props.isLoading}>
                {t("Confirm")}
                {this.props.isLoading && (
                  <Spinner
                    src="../images/spinner.svg"
                    className="spinner-width"
                  />
                )}
              </StyledBlueBtn>
            </Section>
            <StyledAnchor onClick={this.cancelChangeEmail}>
              {t("Cancel")}
            </StyledAnchor>
          </Wrapper>
        ) : (
          <Wrapper>
            <Title>{t("Verify your email")}</Title>
            <SubTitle>
              {t("Enter the {{length}} digit code we sent to", { length: 6 })}
            </SubTitle>
            <StyledTypography variant="bodyBold" style={{whiteSpace: 'nowrap'}}>
              {fields && fields.email}
              <ReplaceImg
                onClick={this.changeEmail}
                src="/images/replace.svg"
                alt=""
              />
            </StyledTypography>
            <UnderTitle dangerouslySetInnerHTML={{__html: t(`If you don’t receive the email in 30 seconds, please check your spam folder.`) }}>
            </UnderTitle>
            <form
              name="verifyForm"
              className="verifyForm"
              onSubmit={this.verifyEmail}
              style={{ width: "100%" }}
            >
              <fieldset>
                <Section>
                  <SectionTitle>{t("Verification Code")}</SectionTitle>
                  <StyledInput
                    name="code"
                    type="text"
                    className={this.props.errors.Code ? "invalid" : ""}
                    maxLength={6}
                    onChange={this.handleChange}
                    value={fields.code}
                  />
                  <SmallTextError className="error">
                    <span>{this.props.errors.Code}</span>
                  </SmallTextError>
                </Section>
                <Section>
                  <StyledBlueBtn type="submit" id="submit" disabled={this.props.isLoading}>
                    {t("Verify Email")}
                    {this.props.isLoading && (
                      <Spinner
                        src="../images/spinner.svg"
                        className="spinner-width"
                      />
                    )}
                  </StyledBlueBtn>
                </Section>
              </fieldset>
            </form>
            <StyledAnchor disabled={!canResend} onClick={this.resendCode}>
              {canResend ? t("Resend code") : t("Code resent!")}
            </StyledAnchor>
            <StyledAnchor onClick={this.changeVerifyMethod}>
              {t("Verify By Phone Number")}
            </StyledAnchor>
          </Wrapper>
        )}
        <Footer />
      </PageContainer>
    );
  }
}

interface StateProps {
  errors: IActorError;
  fields: IActorEmail;
  isLoading: boolean;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    errors: state.actorModel.errors,
    fields: state.actorModel.fields,
    isLoading: state.actorModel.isLoading,
  };
}

interface DispatchProps {
  setErrors: (errors: IActorError) => void;
  setSelectPhone: (phoneSelected: boolean) => void;
  verifyActorEmail: (code: any) => void;
  verifyEmailResendCode: (fields: IActorEmail) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    setErrors: dispatch.actorModel.setErrors,
    setSelectPhone: dispatch.actorModel.setSelectPhone,
    verifyActorEmail: dispatch.actorModel.verifyActorEmail,
    verifyEmailResendCode: dispatch.actorModel.verifyEmailResendCode,
  };
}

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorVerifyEmailStep)
);

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-weight: ${(p) => p.theme["font-weight-600"]};

  max-width: 480px;
  min-width: 480px;
  background-color: ${(p) => p.theme.white};
  padding: 40px 80px;

  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 30px;
  }

  * {
    font-size: ${(p) => p.theme["s-font-size"]};
  }
`;

const StyledAnchor = styled.a<{ disabled?: boolean }>`
  && {
    color: #00a2e0;
    font-size: ${(p) => p.theme["xs-font-size"]};
    font-weight: ${(p) => p.theme["font-weight-600"]};
    letter-spacing: 0.21px;
    line-height: 15px;
    text-align: center;
    margin-top: 8px;
    cursor: pointer;

    &:hover {
      color: ${(p) => p.theme.lightBlue};
    }

    ${(p) =>
      p.disabled &&
      css`
        color: ${(p) => p.theme.typography.color.disabled};
        cursor: default;

        &:hover {
          color: ${(p) => p.theme.typography.color.disabled};
        }
      `}
  }
`;

export const StyledInput = styled.input`
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: ${(props) => props.theme.dropDown["border"]};
  border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  background-color: ${(props) => props.theme.white};
  padding: 0 14px;
  margin-top: 8px;
  outline: 0 !important;

  &.invalid {
    color: ${(p) => p.theme.errorColor};
    border: ${(p) => p.theme["error-border"]};
    background-color: ${(p) => p.theme.red};
  }
`;

export const StyledInputDisable = styled.input`
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: ${(props) => props.theme.dropDown["border"]};
  border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  background-color: #f1f2f4;
  padding: 0 14px;
  margin-top: 8px;
  outline: 0 !important;
  color: #8a94a6;
`;

const Title = styled.div`
  font-size: ${(p) => p.theme["xxl-font-size"]} !important;
`;

const SubTitle = styled.div`
  color: ${(p) => p.theme.color} !important;
  margin: 8px 8px auto 8px;
  font-weight: normal;
  text-align: center;
`;

const UnderTitle = styled.div`
  margin: 8px 8px 0 8px;
// color: ${(p) => p.theme.errorColor} !important;
  color: #c7053f !important;
  font-weight: normal;
  text-align: center;
`;


const StyledTypography = styled(Typography)`
  color: #53627c;
`;

const ReplaceImg = styled.img`
  width: 12px;
  height: 12px;
  margin: ${(p) => p.theme.spacing(0, 0, 0, 0.5)};
  position: relative;
  top: -1px;
  cursor: pointer;
`;
