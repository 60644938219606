import React from 'react';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import UpgradeTable from 'components/Common/UpgradeTable';
import { Button, Skeleton, Typography } from 'components/Common';
import Card from './components/Card';
import Footer from './components/Footer';
import Page from './components/Page';

type Props = {
  t: any;
} & StateProps & RouteComponentProps;

const ActorUpgradeTable: React.FC<Props> = ({
  t,
  history,
  location,
  currencySymbol,
  yearlyPerMonthCharge,
  postCurrencySymbol,
  bdSubmissionCharge
}) => {
  const handleUpgrade = () => {
    history.push({
      pathname: '/actor/select-membership',
      search: location.search
    });
  };

  let charge = yearlyPerMonthCharge?.toString() ?? "";
  if (currencySymbol === "€" && yearlyPerMonthCharge) {
    charge = yearlyPerMonthCharge.toString().replace('.', ',');
  }

  return (
    <StyledPage>
      <Typography align="center" color="white" component="h1" variant="h1">
        {t('Upgrade to Pro and take control of your career!')}
      </Typography>
      <Subheading align="center" color="white" variant="h4">
        {yearlyPerMonthCharge ? t(
          'To access the Working Actor previously recorded sessions, you must be a Pro member. Get access to all the education account Casting Workbook has to offer plus the robust pro features in Casting Workbook and Actor App for as little as {{currencySymbol}}{{charge}}/mo.',
          { currencySymbol, charge, postCurrencySymbol }
        ) : [...Array(3)].map((_, index) => <Skeleton key={index} />)}
      </Subheading>
      <StyledCard>
        <UpgradeTable />
        <Button onClick={handleUpgrade}>
          {t('Upgrade Now')}
        </Button>
      </StyledCard>
      <StyledFooter />
    </StyledPage>
  );
};

const StyledPage = styled(Page)`
  padding: ${(p) => p.theme.spacing(4, 0)};

  @media all and (max-width: 520px) {
    padding: ${(p) => p.theme.spacing(4, 2)};
  }
`;

const StyledCard = styled(Card)`
  width: 800px;
  padding: ${(p) => p.theme.spacing(4, 10)};

  @media all and (max-width: 520px) {
    width: 100%;
    padding: ${(p) => p.theme.spacing(4, 2)};
  }
`;

const Subheading = styled(Typography)`
  width: 800px;
  margin: ${(p) => p.theme.spacing(2, 0, 5)};

  @media all and (max-width: 520px) {
    width: 100%;
    margin: ${(p) => p.theme.spacing(1, 0, 4)};
  }
`;

const StyledFooter = styled(Footer)`
  margin-top: ${(p) => p.theme.spacing(3)};
`;

type StateProps = {
  currencySymbol: string;
  yearlyPerMonthCharge: number;
  postCurrencySymbol?: string,
  bdSubmissionCharge?: any
};

const mapStateToProps = (state: any) => {
  let postCurrencySymbol = "";
  if (state.premiumModel.postCurrencySymbol) {
    postCurrencySymbol = " " + state.premiumModel.postCurrencySymbol.toString();
  }
  return {
    currencySymbol: state.premiumModel.currencySymbol,
    yearlyPerMonthCharge: state.premiumModel.yearlyPerMonthCharge,
    postCurrencySymbol: postCurrencySymbol,
    bdSubmissionCharge: state.premiumModel.bdSubmissionCharge
  }
};

export default withI18n()(connect(
  mapStateToProps,
  null
)(withRouter(ActorUpgradeTable)));
