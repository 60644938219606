import React from "react";
import { connect } from "react-redux";
import ActorCreatePhoneStep from "./ActorCreatePhoneStep";
import { iRootState } from "../../../store";
import ActorVerifyEmailStep from "./ActorVerifyEmailStep";
import CwbLabsActorCreateVerifyStep from "../../CWBLabs/ActorCreateVerifyStep";
import qs from "query-string";

interface Props extends StateProps {
  t?: any;
  history?: any;
  location?: any;
}
const ActorCreateVerifyStep: React.FC<Props> = ({
  selectPhone,
  location,
  history,
}) => {
  return qs.parse(location.search).cwblabs ? (
    <CwbLabsActorCreateVerifyStep history={history} location={location} />
  ) : selectPhone ? (
    <ActorCreatePhoneStep history={history} location={location} />
  ) : (
    <ActorVerifyEmailStep history={history} location={location} />
  );
};

interface StateProps {
  selectPhone: boolean;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    selectPhone: state.actorModel.selectPhone,
  };
}

export default connect(mapStateToProps)(ActorCreateVerifyStep);
