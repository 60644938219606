import React from "react";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";

import { emailValidation, passwordValidation } from "../../helpers/validations";
import {
  ICastingUser,
  ICastingError,
} from "../../shared/api/dtos/ICastingDtos";
import { iRootState } from "../../store";
import {
  Section,
  SectionTitle,
  StyledInput,
  Divider,
  StyledBlueBtn,
  Spinner,
  SmallTextError,
} from "../Common/StyledComponents";
import PasswordInput from "../Common/PasswordInput";
import TermUsePrivacy from "../Common/TermUsePrivacy";
import { NameMaxLength, PasswordLength } from "../../helpers";
import { nameRegex } from "helpers/validations";

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location?: any;
}

interface IState {
  fields: ICastingUser;
  isFormSubmitClicked: boolean;
}

// TODO: Move style and JSDOM out of container compnent  into Presentational Components
class CastingUserStep extends React.Component<IProps, IState> {
  public state: IState = {
    fields: {
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      password: ''
    } as ICastingUser,
    isFormSubmitClicked: false,
  };

  checkValidation() {
    const { t } = this.props;
    let fields: ICastingUser = this.state.fields;
    let errors: ICastingError = {} as ICastingError;

    //Name
    if (!fields.firstName || fields.firstName.trim().length < 2)
      errors.FirstName = !fields.firstName
        ? t("First Name is required")
        : t("Minimum 2 characters");
    if (fields.firstName && !nameRegex.test(fields.firstName))
      errors.FirstName = t("Name can only contain letters, spaces, -, ' and `.");

    //Last Name
    if (!fields.lastName || fields.lastName.trim().length < 2)
      errors.LastName = !fields.lastName
        ? t("Last Name is required")
        : t("Minimum 2 characters");
    if (fields.lastName && !nameRegex.test(fields.lastName))
      errors.LastName = t("Name can only contain letters, spaces, -, ' and `.");

    if (emailValidation(fields.email, t))
      errors.Email = emailValidation(fields.email, t);

    if (passwordValidation(fields.password, PasswordLength, t))
      errors.Password = passwordValidation(fields.password, PasswordLength, t);

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  handleChange = (e: any) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  createAccount = async (e: any) => {
    e.preventDefault();
    this.setState({ isFormSubmitClicked: true });
    let fields: ICastingUser = this.state.fields;
    if (!this.checkValidation()) return;
    await this.props.saveCastingAccount(fields);
    this.moveToNext();
  };

  moveToNext = () => {
    if (this.props.errors.errorMessage)
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/casting/1",
        search: this.props.location.search
      });
    }
  };

  render() {
    const { t, isLoading } = this.props;
    const fields: ICastingUser = this.state.fields;
    const errors: ICastingError = this.props.errors;

    return (
      <Wrapper>
        <form
          name="registerForm"
          className="registerForm"
          onSubmit={this.createAccount}
          style={{ width: "100%" }}
        >
          <fieldset>
            <Section>
              <SectionTitle>{t("First Name")}</SectionTitle>
              <StyledInput
                name="firstName"
                type="text"
                className={errors.FirstName ? "invalid" : ""}
                maxLength={NameMaxLength}
                onChange={this.handleChange}
                value={fields.firstName}
              />
              <SmallTextError className="error">
                <span>{errors.FirstName}</span>
              </SmallTextError>
            </Section>
            <Section>
              <SectionTitle>{t("Last Name")}</SectionTitle>
              <StyledInput
                type="text"
                name="lastName"
                maxLength={NameMaxLength}
                className={errors.LastName ? "invalid" : ""}
                onChange={this.handleChange}
                value={fields.lastName}
              />
              <SmallTextError className="error">
                <span>{errors.LastName}</span>
              </SmallTextError>
            </Section>
            <Section>
              <SectionTitle>{t("Company (Optional)")}</SectionTitle>
              <StyledInput
                type="text"
                name="company"
                maxLength={NameMaxLength}
                onChange={this.handleChange}
                value={fields.company}
              />
            </Section>
            <Divider></Divider>
            <Section>
              <SectionTitle>{t("Email")}</SectionTitle>
              <StyledInput
                type="text"
                name="email"
                autoComplete="off"
                className={errors.Email ? "invalid" : ""}
                onChange={this.handleChange}
                value={fields.email}
              />
              <SmallTextError className="error">
                <span>{errors.Email}</span>
              </SmallTextError>
            </Section>
            <Section>
              <SectionTitle>{t("Password")}</SectionTitle>
              <PasswordInput
                name="password"
                autoComplete="new-password"
                className={errors.Password ? "invalid" : ""}
                onChange={this.handleChange}
                value={fields.password}
              />
              {!errors.Password && (
                <SmallTextError>
                  <span>{t("Must contain at least 6 characters, 1 letter and 1 number")}</span>
                </SmallTextError>
              )}
              <SmallTextError className="error">
                <span>{errors.Password}</span>
              </SmallTextError>
            </Section>
            <Section>
              <StyledBlueBtn type="submit" id="submit" disabled={this.props.isLoading}>
                {t("Create Account")}
                {isLoading && (
                  <>
                    &nbsp;
                    <Spinner src="../images/spinner.svg" />
                  </>
                )}
              </StyledBlueBtn>
            </Section>
          </fieldset>
        </form>
        <TermUsePrivacy />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  & input {
    font-size: ${p => p.theme["s-font-size"]};
  }
`;

interface StateProps {
  errors: ICastingError;
  isLoading: boolean;
}

const mapState = (state: iRootState) => ({
  errors: state.castingModel.errors,
  isLoading: state.castingModel.isLoading,
});

interface DispatchProps {
  saveCastingAccount: (fields: ICastingUser) => void;
  setErrors: (errors: ICastingError) => void;
}

// TODO: review have dispath => type rematch.Dispatch
const mapDispatch = (dispatch: any) => ({
  saveCastingAccount: dispatch.castingModel.saveCastingAccount,
  setErrors: dispatch.castingModel.setErrors,
});

export default withI18n()(connect(mapState, mapDispatch)(CastingUserStep));
