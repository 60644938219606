import * as http from '../http';

const api = `${process.env.REACT_APP_REG_Api}/Performer`;

export const getPerformerTypes = () => {
  return http.get(`${api}/types`);
};

export const createPerformer = (
  payload: {
    performerTypeIds: number[];
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }
) => {
  return http.post(`${api}${window.location.search}`, payload);
};
