import React, { MouseEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

type Props = {
  id?: string;
  className?: string;
  href?: string;
  rel?: string;
  startIcon?: ReactNode;
  target?: string;
  variant?: 'primary' | 'secondary' | 'primaryBold' | 'secondaryBold'
  onClick?: (event: MouseEvent) => void;
};

const Link: React.FC<Props> = ({
  children,
  id,
  className,
  href,
  rel,
  startIcon,
  target,
  variant = 'primary',
  onClick
}) => {
  const props = {
    id,
    className,
    href,
    rel,
    startIcon,
    target,
    variant,
    onClick
  };

  return (
    <StyledA {...props}>
      {startIcon}
      {children}
    </StyledA>
  );
};

const StyledA = styled.a<Props>`
  width: max-content;
  height: max-content;
  cursor: pointer;
  font-size: ${p => p.theme.typography.fontSize.link};
  line-height: ${p => p.theme.typography.lineHeight.link};

  ${p => p.variant === 'primary' && css`
    color: ${p => p.theme.palette.brand.blue};
    font-weight: ${p => p.theme.typography.fontWeight.normal};
    text-decoration: underline;

    &:hover {
      color: ${p => p.theme.palette.brand.blue};
    }
  `}

  ${p => p.variant === 'secondary' && css`
    color: ${p => p.theme.palette.grey[7]};
    font-weight: ${p => p.theme.typography.fontWeight.normal};
    text-decoration: underline;

    &:hover {
      color: ${p => p.theme.palette.grey[7]};
    }
  `}

  ${p => p.variant === 'primaryBold' && css`
    color: ${p => p.theme.palette.brand.blue};
    font-weight: ${p => p.theme.typography.fontWeight.bold};

    &:hover {
      color: ${p => p.theme.palette.brand.blue};
      text-decoration: none;
    }
  `}

  ${p => p.variant === 'secondaryBold' && css`
    color: ${p => p.theme.palette.grey[7]};
    font-weight: ${p => p.theme.typography.fontWeight.bold};

    &:hover {
      color: ${p => p.theme.palette.grey[7]};
      text-decoration: none;
    }
  `}

  ${p => p.startIcon && css`
    & > :first-child {
      margin: ${p => p.theme.spacing('-0.5px', 1, '0.5px', 0)};
    }

    ${p => p.variant === 'primary' && css`
      font-weight: ${p => p.theme.typography.fontWeight.bold};
      text-decoration: none;

      &:hover { text-decoration: none; }
    `}
  `}
`;

export default Link;
