import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import Footer from "../Common/Footer";
import Modal from "../Common/Modal";
import PageContainer from "../Common/PageContainer";
import { getCurUserSignUp } from "../../helpers";

interface IProps extends RouteComponentProps, StateProps {
  t: any;
}

const CastingProAccountStep: React.FC<IProps> = ({ t, location }) => {
  const site = process.env.REACT_APP_CWB_Site;
  const loginUrl = `${site}/login/${location.search}`;

  const [remainingTime, setRemainingTime] = useState<number>(19);

  useEffect(() => {
    if (remainingTime === 0) {
      window.location.assign(loginUrl);
    } else {
      setTimeout(() => {
        setRemainingTime(remainingTime - 1);
      }, 1000);
    }
  }, [remainingTime, loginUrl]);

  const getUserEmail = () => {
    const cachedUser = getCurUserSignUp();
    if (!cachedUser || !cachedUser.castingUser) return "";

    return cachedUser.castingUser.email;
  };

  return (
    <PageContainer>
      <Wrapper>
        <StyledTitle>{t(`You're Almost There!`)}</StyledTitle>
        <SubTitle>{t(`We've sent a verification email to`)}</SubTitle>
        <BoldText>{getUserEmail()}</BoldText>
        <div>
          <p>{t("You must verify your email to login to your account.")}</p>
          <span>
            {t(
              "One of our representatives will be in touch shortly to get your Pro Casting Account fully activated so you can post breakdowns and get started! In the meantime you can"
            )}
            &nbsp;<a href={loginUrl}>{t("login to your account")}</a>&nbsp;
            {t("right away with limited access.")}
          </span>
        </div>
        <div style={{ marginTop: "16px" }}>
          {t("You will automatically be redirected to the login screen, in")}
          &nbsp;<span>{remainingTime}</span>&nbsp;
          {t("seconds.")}
        </div>
      </Wrapper>
      <Footer />
    </PageContainer>
  );
};

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;

  * {
    color: ${(p) => p.theme.color};
    font-size: ${(p) => p.theme["s-font-size"]};
  }

  max-width: 480px;
  min-width: 480px;
  background-color: ${(p) => p.theme.white};
  padding: 40px 80px;

  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 40px;
  }
`;

const StyledTitle = styled.div`
  color: ${(p) => p.theme.darkGrey} !important;
  font-size: ${(p) => p.theme["xxl-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
`;

const SubTitle = styled.div`
  margin-top: 8px;
  text-align: center;
`;

const BoldText = styled.div`
  font-weight: ${(p) => p.theme["font-weight-600"]};
  margin: 16px 0;
`;

interface StateProps {
  email: string;
}

const mapStateToProps = (state: any): StateProps => ({
  email: state.castingModel.email,
});

export default withI18n()(
  withRouter(connect(mapStateToProps, null)(CastingProAccountStep))
);
