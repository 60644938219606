import React, { useEffect, useState } from "react";
import { withI18n } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { Modal, PageContainer } from "../Common";
import { connect } from "react-redux";
import { iRootState } from "../../store";
import { IPaymentDetailsDto } from "../../shared/api/dtos/IPaymentDetailsDto";
// @ts-ignore
import { CWBLoadingCrest } from "cwb-react";
import { ICustomerPortalDto } from "../../shared/api/dtos/ICustomerPortalDto";
import * as Sentry from "@sentry/react";
import { fallbackLoginRedirect } from "../../helpers/redirect"
import qs from "query-string";

interface IProps extends StateProps, DispatchProps, RouteComponentProps<any> {
  t?: any;
}

const ActorPaymentDetails: React.FC<IProps> = ({
  t,
  getPaymentDetails,
  getCustomerPortal,
  paymentDetails,
  customerPortal,
  location
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function getPmtDetailsAndPortal() {
      try {
        setIsLoading(true);
        await getPaymentDetails();
        await getCustomerPortal();
        setIsLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        const queryString = qs.stringify({
          ...qs.parse(location.search),
          page: "upgrade",
        });

        setTimeout(() => fallbackLoginRedirect(queryString), 1000);
      }
    }
    getPmtDetailsAndPortal();
  }, []);
  return (
    <PageContainer>
      <Wrapper>
        <StyledTitle>{t("Payment Details")}</StyledTitle>

        {isLoading && (
          <CWBLoadingCrest customLogoSrc="/images/icon-cwb-logo-grey.svg" />
        )}

        {!isLoading && paymentDetails && paymentDetails.paymentCard && (
          <>
            <PaymentDetailsUl>
              <FlexRow>
                <Label>{t("Payment Method:")}</Label>
                <Text>
                  {`
                ${paymentDetails.paymentCard.brand} 
                ${paymentDetails.paymentCard.last4} 
                Expires: ${paymentDetails.paymentCard.expMonth} / ${paymentDetails.paymentCard.expYear}
              `}
                  {customerPortal && customerPortal.sessionLink && (
                    <ChangeLink
                      href={customerPortal.sessionLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      change
                    </ChangeLink>
                  )}
                </Text>
              </FlexRow>
              <FlexRow>
                <Label>{t("Name on Card:")}</Label>
                <Text>{`
                ${paymentDetails.paymentCard.name}
              `}</Text>
              </FlexRow>
              <FlexRow>
                <Label>{t("Email Address:")}</Label>
                <Text>{`
                ${paymentDetails.emailAddress}
              `}</Text>
              </FlexRow>
              <FlexRow>
                <Label>{t("Billing Address:")}</Label>
                <Text>
                  {`
                ${paymentDetails.billingAddress}
              `}
                </Text>
              </FlexRow>
            </PaymentDetailsUl>
            {customerPortal && customerPortal.sessionLink && (
              <UpdateBtnSection>
                <ButtonLink
                  className="btn"
                  href={customerPortal.sessionLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Update Payment Details
                </ButtonLink>
              </UpdateBtnSection>
            )}
          </>
        )}
        {!isLoading &&
          <>
            {(!paymentDetails || !paymentDetails.paymentCard) && (
              <>
                <PaymentDetailsUl>
                  <FlexRow>
                    <Label>{t("Payment Method:")}</Label>
                    <Text>{t("No payment details available.")}</Text>
                  </FlexRow>
                </PaymentDetailsUl>
              </>
            )}
            {customerPortal && customerPortal.sessionLink && (
              <UpdateBtnSection>
                <ButtonLink
                  className="btn"
                  href={customerPortal.sessionLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Update Payment Details
                </ButtonLink>
              </UpdateBtnSection>
            )}
          </>
        }
      </Wrapper>
    </PageContainer>
  );
};

// TODO: replace below with useDispatch / redux hook
// https://levelup.gitconnected.com/rematch-with-hooks-50a8380c46e4
interface StateProps {
  paymentDetails: IPaymentDetailsDto;
  customerPortal: ICustomerPortalDto;
}

const mapStateToProps = (state: iRootState) => {
  return {
    paymentDetails: state.stripeCustomerModel.paymentDetails,
    customerPortal: state.stripeCustomerModel.customerPortal,
  };
};

interface DispatchProps {
  getPaymentDetails: () => IPaymentDetailsDto;
  getCustomerPortal: () => ICustomerPortalDto;
}

const mapDispatchToProps = (dispatch: any) => ({
  getPaymentDetails: dispatch.stripeCustomerModel.getCustomerPaymentDetails,
  getCustomerPortal: dispatch.stripeCustomerModel.getCustomerPortal,
});

export default withI18n()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ActorPaymentDetails))
);

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex-wrap: wrap;
  align-items: center;

  * {
    color: ${(p) => p.theme.color} !important;
    font-size: ${(p) => p.theme["s-font-size"]};
  }

  max-width: 550px;
  min-width: 550px;
  background-color: ${(p) => p.theme.white};
  padding: 20px 30px;

  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 40px;
  }
`;

const StyledTitle = styled.div`
  color: ${(p) => p.theme.darkGrey} !important;
  font-size: ${(p) => p.theme["xxl-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
`;

const FlexRow = styled.li`
  display: flex;
  justify-content: flex-end;
  padding: 4px 0 0 11px;
`;

const PaymentDetailsUl = styled.ul`
  min-width: 305px;
  background-color: #f8f8f9;
  border-radius: 4px;
  padding: 8px 10px 8px 10px;
  margin-top: 24px;
  list-style-type: none;
  max-width: 490px;
  word-break: break-word;
`;

const Label = styled.label`
  padding: 4px 16px 0px 0;
  font-weight: bolder;
  min-width: 135px;
  font-size: 14px !important;
`;

const Text = styled.label`
  flex: 1;
  margin-top: 6px;
  font-weight: 500;
`;

const ChangeLink = styled.a`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;

  letter-spacing: 0.374px;

  color: #00aaff !important;
  padding: 0 0 0 10px;
`;

export const UpdateBtnSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const ButtonLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  font-weight: 600 !important;

  outline: 0 !important;
  border: 1px solid ${(p) => p.theme.lightBlue} !important;
  border-radius: ${(p) => p.theme.dropDown["border-radius"]};

  box-shadow: none !important;
  padding: 9px 16px !important;
  cursor: pointer;

  background: #2ed2ff !important;
  color: white !important;

  &:hover {
    background: #00aaff !important;
    border: 1px solid ${(p) => p.theme.btnDarkBlue} !important;
    color: white !important;
  }
`;
