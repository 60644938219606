import React, { useState, useEffect, useCallback } from "react";
import { withI18n } from "react-i18next";
import { Redirect, RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { Button, Link, Modal, Typography } from "../../Common";
import WarningIcon from "../../../images/warning.svg";
import { connect } from "react-redux";
import { iRootState } from "store";
import { IPurchaseAttempt } from "shared/api/dtos/IStripeDto";
// @ts-ignore
import { getCurUser, getWebSessionKeyFromAuthToken, getSessionDataFromAuthToken, getAuthToken } from 'cwb-react'
import { getWebSessionKey } from "helpers";

type Props = {
  t: any;
} & RouteComponentProps & DispatchProps;

const ModalUpgradePaymentRequiresAction: React.FC<Props> = ({
  t,
  history,
  location,
  purchase,
  clientId
}) => {

  const [disableButton, setDisableButton] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [remainingTime, setRemainingTime] = useState<number>(15);
  const redirect = useCallback(() => {

    let targetId = clientId;
    if (!targetId) {
      const user = getCurUser();
      if (user && user.id) {
        targetId = user.id.userId
      }

      if (!targetId) {
        const data: any = getSessionDataFromAuthToken(getAuthToken());
        if (data) {
          targetId = data.UserId || data.userId
        }
      }
    }

    if (!targetId || !purchase?.serviceId) {
      history.push({
        pathname: '/actor/payment-declined',
        search: location.search
      });
      return;
    }

    let url = `${process.env.REACT_APP_Api}/reg/Redirect/invoice/incomplete/${targetId}/product/${purchase.serviceId}`;

    let webSessionKey = getWebSessionKey();
    if (!webSessionKey) {
      webSessionKey = getWebSessionKeyFromAuthToken();
    }

    if (webSessionKey)
      url = url + `?k=${webSessionKey}`;

    if (purchase.couponCode) {
      if (url.indexOf('?') > -1)
        url = url + '&';
      else
        url = url + '?';
      url = url + `couponCode=${purchase.couponCode}`;
    }

    window.top.location.href = url;
  }, []);

  useEffect(() => {
    if (remainingTime === 3) {
      setDisableButton(false);
    } else if (remainingTime == 2) {
      setIsRedirecting(true);
      redirect();
    } else if (remainingTime == 0) {
      return;
    }
    let expire = setTimeout(() => {
      const copy = remainingTime;
      setRemainingTime(copy - 1);
    }, 1000);

    return () => expire && clearTimeout(expire);
  }, [remainingTime]);

  return (
    <>
      <StyledModal>
        <Typography align="center" component="h1" variant="h2">
          {t("Your payment requires confirmation")}
        </Typography>
        <StyledImg src={WarningIcon} />
        <Typography align="center" color="darkGrey" variant="h4">
          {t(
            `We attempted to process your payment but it requires verification.`
          )} &nbsp;
          {t('You will be automatically redirected to a secure payment page in')}
          &nbsp;<span>{remainingTime}</span>&nbsp;
          {t('seconds.')}
        </Typography>
        <StyledButton disabled={disableButton || isRedirecting}
          onClick={() => {
            if (isRedirecting)
              return;

            if (remainingTime > 2) {
              setRemainingTime(2);
            }
            setIsRedirecting(true);
            redirect()
          }
          }>
          {t("Pay now")}
        </StyledButton>
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(p) => p.theme.spacing(10, 19, 12)};
`;

const StyledImg = styled.img`
  margin: ${(p) => p.theme.spacing(2, 0, 4)};
`;

const StyledLink = styled(Link)`
  font-size: 16px;

  &&,
  &&:hover {
    text-decoration: underline;
  }
`;

const StyledButton = styled(Button)`
  min-width: 200px;
  margin-top: ${(p) => p.theme.spacing(5)};
`;


interface DispatchProps {
  purchase: IPurchaseAttempt;
  clientId: any;
}

const mapStateToProps = (state: iRootState) => ({
  purchase: state.stripeModel.purchase,
  clientId: state.actorModel.clientId,
});

export default withI18n()(withRouter(connect(
  mapStateToProps,
  null
)(ModalUpgradePaymentRequiresAction)));