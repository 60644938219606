import React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  className?: string;
  variant?: 'primary' | 'secondary' | 'error'
};

const Card: React.FC<Props> = ({
  children,
  className,
  variant = 'primary'
}) => {
  const props = { className, variant };
  return (
    <StyledDiv {...props}>
      {children}
    </StyledDiv>
  );
};

const StyledDiv = styled.div<Props>`
  width: 340px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  padding: ${(p) => p.theme.spacing(3, 3, 4)};
  background-color: #FFFFFF;
  border-top: 6px solid;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  ${(p) => p.variant === 'primary' && css`
    border-color: #02B8F9;
  `}

  ${(p) => p.variant === 'secondary' && css`
    border-color: ${(p) => p.theme.palette.brand.red};
  `}

  ${(p) => p.variant === 'error' && css`
    border-color: ${(p) => p.theme.palette.system.error};
  `}
`;

export default Card;
