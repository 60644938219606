import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withI18n } from 'react-i18next';
import styled from 'styled-components'
import { Button, ButtonGroup, Typography } from '../Common';
import qs from "query-string";
// @ts-ignore
import { CWBModal } from 'cwb-react';

interface IProps extends  RouteComponentProps {
  t: any;
  history: any;
  location: any;
  onClose: () => void;
  handleUpgrade:() => void;
  toHomePage:() => void;
  currencySymbol:string;
}

const DeclineOfferModal: React.FC<IProps> = ({
  t,
  history,
  location,
  onClose: handleClose,
  handleUpgrade,
  toHomePage,
  currencySymbol
}) => {

  let charge = '3.00';
  if(currencySymbol === "€"){
    charge = '3,00';
  }

  return (
    <StyledCWBModal
      backdrop="static"
      isOpen={true}
      title={(
        <Typography
          key={0}
          align="left"
          component="span"
          variant="h2"
        >
          {t('Are you sure you don’t want the discount?')}
        </Typography>
      )}
      buttons={(
        <ButtonGroup>
          <Button onClick={handleUpgrade}>
            {t('Take the offer')}
          </Button>
          <Button
            variant="secondary"
            onClick={toHomePage}
          >
            {t('Decline the offer')}
          </Button>
        </ButtonGroup>
      )}
      hideModal={toHomePage}
    >
      <Typography
        color="medGrey"
        component="span"
      >
        {t(`You’ll lose access to all of our premium features and activities, but we’ll keep your materials and you’ll be able to apply for jobs on an a-la-carte basis ({{currencySymbol}}{{charge}} per job submission)`, {currencySymbol, charge})}
      </Typography>
      &nbsp;
    </StyledCWBModal>
  );
};

const StyledCWBModal = styled(CWBModal)`
  & .modal-header { justify-content: space-between; }
  & .modal-body { text-align: left; }
  & .modal-footer .modal-buttons {
    justify-content: flex-start;
    margin: ${p => p.theme.spacing(0, 0, 1)};
  }
  border-top:6px solid #00AAFF;
  top:350px;
  max-width: 600px !important;
`;



export default withI18n()(withRouter(DeclineOfferModal));
