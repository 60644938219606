import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import qs from "query-string";
import { RouteComponentProps } from "react-router-dom";
import Membership from "../../Common/Membership";
import MembershipOneTime from "components/Common/MembershipOneTime";
import { getCountryCode, getRegionCode } from "../../../helpers";
import { iRootState } from "../../../store";
import {
  IStripeCharge,
  IStripeChargeProduct,
} from "../../../shared/api/dtos/IStripeDto";
import { upgradeOneTime } from "shared/api/Stripe.api";
import MembershipTrialUpgrade from "components/Common/MembershipTrialUpgrade";
import { ISubscriptionService } from "shared/api/dtos/IServicesDto";
import MembershipProduct from "components/Common/MembershipProduct";

interface Props extends RouteComponentProps, StateProps, DispatchProps {}

const ActorUpgradePayment: React.FC<Props> = ({
  history,
  location,
  authenticatedUser,
  getServices,
  getOneTimeServices,
  upgradeFreemium,
  setServiceCodeError,
  actorProfile,
  getTrialUpgradeServices,
  upgradeAddOn,
  trialUpgradeServices,
  upgradeFreemiumProduct,
}) => {
  const [isSpecial, setIsSpecial] = useState(false);
  const [monthlyDefault, setMonthDefault] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [isHomeKit, setIsHomeKit] = useState(false);
  const [product, setProduct] = useState("");

  useEffect(() => {
    const isHomeKit = qs.parse(location.search).product === "homekit";
    setIsHomeKit(isHomeKit);
    setProduct(qs.parse(location.search).product as string);

    // const redirectParams = qs.stringify({
    //   ...qs.parse(location.search),
    // });
    // if (isHomeKit) {
    //   setServiceCodeError(2);
    //   history.push(`/register/offer-not-valid?${redirectParams}`);
    // }
    if (qs.parse(location.search).monthly === "1") {
      setMonthDefault(true);
    }
    //check valid special service
    const special = qs.parse(location.search).product === "special";
    if (special) {
      (async () => {
        const isValid = await getOneTimeServices();
        if (isValid) {
          setIsSpecial(true);
        }
      })();
    }
  }, [location, history, setServiceCodeError, getOneTimeServices]);

  useEffect(() => {
    if (
      actorProfile &&
      actorProfile.servicesPlan &&
      actorProfile.servicesPlan.isTrial
    )
      setIsTrial(true);
  }, [actorProfile]);

  const fetchServices = async () => {
    await getServices();
  };

  const fetchTrialUpgradeServices = async () => {
    await getTrialUpgradeServices();
  };

  const handleSubmit = async (apiPayload: IStripeCharge) => {
    await upgradeFreemium(apiPayload);
  };

  const handleTrialSubmit = async (apiPayload: IStripeCharge) => {
    if (!trialUpgradeServices.length) await fetchTrialUpgradeServices();

    const serviceSelected = trialUpgradeServices.find(
      (x) => x.serviceId == apiPayload.serviceId
    );
    if (serviceSelected.hasOneTimeFee && serviceSelected.durationMonths == 12) {
      await upgradeAddOn(apiPayload); // Add On only applied to yearly plan
      return;
    }

    await upgradeFreemium(apiPayload);
  };

  const handleOneTimeSubmit = async (apiPayload: IStripeCharge) => {
    await upgradeOneTime(apiPayload);
  };

  const handleSubmitProduct = async (apiPayload: IStripeChargeProduct) => {
    await upgradeFreemiumProduct(apiPayload);
  };

  const moveToNext = (serviceId: number) => {
    history.push({
      pathname: "/actor/payment-processing",
      search: location.search,
      state: {
        successPath: "/actor/upgrade-completed",
        serviceId,
      },
    });
  };

  const moveToNextProduct = (serviceId: number) => {
    history.push({
      pathname: "/actor/payment-processing",
      search: location.search,
      state: {
        successPath: "/actor/homekit-completed",
        serviceId,
      },
    });
  };

  const promoCode = qs.parse(location.search).promo;

  const isUsa = getRegionCode() === "US" || getCountryCode() === "US" ;

  const createMembershipComponent = () => {
    if (isHomeKit && isUsa) {
      return (
        <MembershipProduct
          country={getCountryCode()}
          provinceId={authenticatedUser.provinceId}
          onSubmit={(apiPayload: any) => handleSubmitProduct(apiPayload)}
          moveToNext={moveToNextProduct}
          clientId={authenticatedUser.id.userId}
          product={product}
          promoCode={promoCode}
        />
      );
    } else if (!isSpecial && !isTrial) {
      return (
        <Membership
          country={getCountryCode()}
          provinceId={authenticatedUser.provinceId}
          getServices={fetchServices}
          onSubmit={(apiPayload) => handleSubmit(apiPayload)}
          moveToNext={moveToNext}
          clientId={authenticatedUser.id.userId}
          promoCode={promoCode}
          monthlyDefault={monthlyDefault}
        />
      );
    } else if (!isSpecial && isTrial) {
      return (
        <MembershipTrialUpgrade
          country={getCountryCode()}
          provinceId={authenticatedUser.provinceId}
          getServices={fetchTrialUpgradeServices}
          onSubmit={(apiPayload) => handleTrialSubmit(apiPayload)}
          moveToNext={moveToNext}
          clientId={authenticatedUser.id.userId}
          promoCode={promoCode}
          monthlyDefault={monthlyDefault}
        />
      );
    } else {
      return (
        <MembershipOneTime
          country={getCountryCode()}
          provinceId={authenticatedUser.provinceId}
          onSubmit={(apiPayload) => handleOneTimeSubmit(apiPayload)}
          moveToNext={moveToNext}
        />
      );
    }
  };

  return createMembershipComponent();
};

interface StateProps {
  authenticatedUser: any;
  actorProfile: any;
  trialUpgradeServices: ISubscriptionService[];
}

const mapStateToProps = (state: iRootState) => ({
  authenticatedUser: state.appModel.authenticatedUser,
  actorProfile: state.appModel.actorProfile,
  trialUpgradeServices: state.servicesModel.trialUpgradeServices,
});

interface DispatchProps {
  getServices: () => void;
  getOneTimeServices: () => boolean;
  upgradeFreemium: (apiPayload: IStripeCharge) => void;
  upgradeAddOn: (apiPayload: IStripeCharge) => void;
  setServiceCodeError: (error: number) => void;
  getTrialUpgradeServices: () => void;
  upgradeFreemiumProduct: (apiPayload: IStripeChargeProduct) => void;
}

const mapDispatchToProps = (dispatch: any) => ({
  getServices: dispatch.servicesModel.getPaidServicesClient,
  getOneTimeServices: dispatch.servicesModel.getOneTimeServices,
  upgradeFreemium: dispatch.stripeModel.upgradeFreemium,
  upgradeAddOn: dispatch.stripeTrialModel.upgradeAddOn,
  setServiceCodeError: dispatch.actorModel.setServiceCodeError,
  getTrialUpgradeServices: dispatch.servicesModel.getTrialUpgradeServices,
  upgradeFreemiumProduct: dispatch.stripeModel.upgradeFreemiumProduct,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActorUpgradePayment);
