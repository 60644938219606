const fallbackLoginRedirect = (qs?: string) => {
    if (!qs || qs === "?") {
        window.location.replace(`${process.env.REACT_APP_CWB_Actor_Login}`)
    }
    if (qs[0] === "?") {
        window.location.replace(`${process.env.REACT_APP_CWB_Actor_Login}${qs}`)
    } else {
        window.location.replace(`${process.env.REACT_APP_CWB_Actor_Login}?${qs}`)
    }
}

export { fallbackLoginRedirect }