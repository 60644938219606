import React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
};

const Page: React.FC<Props> = ({ children, className }) => {
  return (
    <StyledDiv className={className}>{children}</StyledDiv>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  min-height: calc(100vh);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-top: ${(p) => p.theme.spacing('80px')};
  background-color: #040F1C;
`;

export default Page;
