import { createModel } from "@rematch/core";
import * as StripeTrialApi from "../shared/api/StripeTrial.api";
import {
  ISubscriptionResponse,
  IStripeCharge,
  IStripePaymentError,
} from "../shared/api/dtos/IStripeDto";
import { cleanObjectProperties } from "../helpers";
import i18next from "i18next";
// @ts-ignore
import * as Sentry from "@sentry/react";

const stripeTrialModel = createModel({
  state: {
    subscriptionDetails: {} as ISubscriptionResponse,
    errors: {} as IStripePaymentError,
    isLoading: false,
  },
  reducers: {
    updateSubscriptionDetails(state, data: ISubscriptionResponse) {
      return { ...state, subscriptionDetails: data };
    },
    setErrors: (state: any, errors: IStripePaymentError) => ({
      ...state,
      errors,
    }),
    setIsLoading: (state: any, isLoading: boolean) => ({
      ...state,
      isLoading,
    }),
  },
  effects: (dispatch: any) => ({
    async upgradeAddOn(dto: IStripeCharge) {
      cleanObjectProperties(dto);
      return await StripeTrialApi.upgradeAddOn(dto)
        .then((resp: any) => {
          dispatch.stripeTrialModel.updateSubscriptionDetails(resp.data);
          return resp;
        })
        .catch((resp) => {
          Sentry.captureException(resp);
          const { status, data } = resp.response;
          if (
            status === 504 ||
            status === 530 ||
            (status >= 400 && status < 500) ||
            !data
          ) {
            throw resp;
          }

          if (data.message) {
            dispatch.stripeTrialModel.setErrors({
              errorMessage: data.message,
            });
          } else {
            dispatch.stripeTrialModel.setErrors({
              errorMessage: i18next.t(
                "Cannot process the request. An unexpected error occurred."
              ),
            });
          }
        })
        .finally(() => {
          dispatch.stripeTrialModel.setIsLoading(false);
        });
    },
  }),
});

export default stripeTrialModel;
