import React from "react";
import styled from "styled-components";
import { withI18n } from "react-i18next";
import * as SharedStyle from "../Common/StyledComponents";

interface IProps {
  t?: any;
}

const CastingSellingFeatures: React.FC<IProps> = ({ t }) => {
  return (
    <>
      <TitleWrapper>
        <MainTile>{t("Casting & Production")}</MainTile>
      </TitleWrapper>
      <StyledP>
        {t(
          "Why use Casting Workbook? Imagine… every single process is linked to the next, instantly, seamlessly."
        )}
      </StyledP>
      <TitleWrapper>
        <Title>
          <SharedStyle.SellingFeatureIcon src="../images/files_labeled_outlined.svg" />
          <div>{t("Breakdowns")}</div>
        </Title>
      </TitleWrapper>
      <SharedStyle.SellingFeatureP>
        {t(
          "Post locally or everywhere, receive submissions from curated agent lists. Then, schedule auditions or shortlist & share or request self-tapes. Finally, share selects with teams and decide on your cast with integrated notes & choices. Voila!"
        )}
      </SharedStyle.SellingFeatureP>
      <TitleWrapper>
        <Title>
          <SharedStyle.SellingFeatureIcon src="../images/laptop.svg" />
          <div>{t("iSAM - Taping Sessions made simple")}</div>
        </Title>
      </TitleWrapper>
      <SharedStyle.SellingFeatureP>
        {t(
          "From submissions to shortlists to schedules, push one button and open your laptop session. From there you can tape, encode and upload before leaving the room. We also give you free session runner apps for polaroids & check-ins as well as our companion app for remote viewing your session materials."
        )}
      </SharedStyle.SellingFeatureP>
      <TitleWrapper>
        <Title>
          <SharedStyle.SellingFeatureIcon src="../images/group_equal.svg" />
          <div>{t("TeamShare & Watermarked Scripts & Sides")}</div>
        </Title>
      </TitleWrapper>
      <SharedStyle.SellingFeatureP>
        {t(
          "Collaborate and Share with your teams. Make quick selects and pull reports for callbacks and bookings. Also, Pull sides, markup, watermark and download or distribute to agents & actors in minutes using Smart Script."
        )}
      </SharedStyle.SellingFeatureP>
    </>
  );
};

export default withI18n()(CastingSellingFeatures);

const TitleWrapper = styled.div`
  width: 100%;
`;

const MainTile = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  color: #0a1f44 !important;
  margin-bottom: 12px;
`;

const Title = styled.div`
  display: flex;
  font-size: 21px;
  font-weight: 600;
  line-height: 29px;
  color: #364866 !important;
  margin-bottom: 12px;
`;

const StyledP = styled.p`
  font-size: 14px;
  color: #364866 !important;
  line-height: 25px;
`;
