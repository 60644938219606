import React, { ChangeEvent, FocusEvent, KeyboardEvent } from "react";
import qs from "query-string";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import Select from "react-select";
import { Button, TextInput, Typography } from "components/Common";
import ErrorIcon from "images/clear_circle.svg";
import { emailRegex, passwordRegex } from "helpers/validations";
// @ts-ignore
import * as Sentry from "@sentry/react";
import { timedSentrySendThenErrorPage } from "helpers";

type Props = {
  t: any;
  className?: string;
} & DispatchProps &
  RouteComponentProps;

type State = {
  errors: {
    performerType: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  };
  form: {
    performerType: any[];
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  };
  isLoading: boolean;
  options: any[];
  serverError: string;
};

const getSelectStyles = (hasError?: boolean) => ({
  control: (provided: any) => ({
    ...provided,
    height: "35px",
    minHeight: "35px",
    ...(hasError && { borderColor: "#E5084A" }),
    boxShadow: "none",
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    height: "33px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  input: (provided: any) => ({
    ...provided,
    margin: "0",
  }),
  multiValueRemove: (provided: any) => {
    return {
      ...provided,
      ":hover": {
        color: "unset",
        backgroundColor: "unset",
        cursor: "pointer",
      },
    };
  },
  option: (provided: any) => ({
    ...provided,
    padding: "7px 12px",
    fontSize: "14px",
    fontWeight: "normal",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: "normal",
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    height: "33px",
    flexFlow: "row nowrap",
    padding: "0 8px",
  }),
});

class PerformerSignUpForm extends React.Component<Props, State> {
  state = {
    errors: {
      performerType: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    form: {
      performerType: [],
      firstName: "",
      lastName: "",
      email: "",
      password: "",
    },
    isLoading: true,
    options: [],
    serverError: "",
  } as State;

  async componentDidMount() {
    try {
      const result = await this.props.getPerformerTypes();
      this.setState({ isLoading: false, options: result });
    } catch (e) {
      timedSentrySendThenErrorPage(e);
    }
  }

  getErrorMessage = (name: string, value: any) => {
    const { t } = this.props;
    switch (name) {
      case "performerType":
        return value?.length ? "" : t("Performer type is required.");
      case "firstName":
        return !value
          ? t("First name is required.")
          : value.length < 2
          ? `${t("Minimum 2 characters")}.`
          : "";
      case "lastName":
        return !value
          ? t("Last name is required.")
          : value.length < 2
          ? `${t("Minimum 2 characters")}.`
          : "";
      case "email":
        return !value
          ? t("Email is required.")
          : !emailRegex.test(value)
          ? t("Email is invalid.")
          : "";
      case "password":
        return !value
          ? t("Password is required.")
          : !passwordRegex.test(value)
          ? t("Must contain at least 6 characters, 1 letter and 1 number")
          : "";
    }
  };

  validate = (eventTarget: HTMLInputElement) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [eventTarget.name]: this.getErrorMessage(
          eventTarget.name,
          typeof eventTarget.value === "string"
            ? eventTarget.value.trim()
            : eventTarget.value
        ),
      },
    });
  };

  validateAll = () => {
    const fields = { ...this.state.form };
    const newErrors = { ...this.state.errors } as any;

    for (const [key, value] of Object.entries(fields)) {
      const errorMessage = this.getErrorMessage(key, value);
      newErrors[key] = errorMessage;
    }

    this.setState({ errors: newErrors });
    return !Boolean(
      Object.values(newErrors).reduce((acc: any, cur: any) => acc + cur)
    );
  };

  handleChangeSelect = (newSelectState: any) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          performerType: newSelectState,
        },
      },
      () => {
        if (this.state.errors.performerType) {
          this.validate({
            name: "performerType",
            value: newSelectState,
          } as HTMLInputElement);
        }
      }
    );
  };

  handleChange = (e: ChangeEvent) => {
    const eventTarget = e.target as HTMLInputElement;
    this.setState(
      {
        form: {
          ...this.state.form,
          [eventTarget.name]: eventTarget.value,
        },
      },
      () => {
        if ((this.state.errors as any)[eventTarget.name]) {
          this.validate(eventTarget);
        }
      }
    );
  };

  validateAllAndSubmit = async () => {
    if (!this.validateAll()) return;

    try {
      await this.props.createPerformer({
        performerTypeIds: this.state.form.performerType.map((option) => {
          return option.value;
        }),
        firstName: this.state.form.firstName,
        lastName: this.state.form.lastName,
        email: this.state.form.email,
        password: this.state.form.password,
      });
      this.props.history.push({
        pathname: "/performer/completed",
        search: this.props.location.search,
      });
    } catch (e) {      
      if (e.error) {
        if (e.errors[0].field === "Email") {
          this.setState({
            errors: {
              ...this.state.errors,
              email: e.errors[0].message,
            },
          });
        } else {
          Sentry.captureException(e);
          this.setState({ serverError: e.errors[0].message });
        }
      }
      this.props.history.push({
        pathname: "/performer/failed",
        search: this.props.location.search,
      });
    }
  };

  render() {
    const { t, location, className } = this.props;
    const { errors, form, isLoading, options, serverError } = this.state;

    const canSubmit = !Boolean(
      Object.values(errors).reduce((acc: any, cur: any) => acc + cur)
    );

    const params = qs.stringify({
      cwblabs: true,
      redirect: "cwblabs",
      ...qs.parse(location.search),
    });

    return (
      <StyledDiv className={className}>
        <StyledLabel>
          <Typography color="darkGrey" variant="bodyBold">
            {t("Performer type")}
          </Typography>
          <Select
            styles={getSelectStyles(Boolean(errors.performerType))}
            isLoading={isLoading}
            isMulti
            name="performerType"
            value={form.performerType}
            options={options.map((opt) => ({ ...opt, label: t(opt.label) }))}
            placeholder={t("Select")}
            onMenuClose={() =>
              this.validate({
                name: "performerType",
                value: this.state.form.performerType,
              } as any)
            }
            onChange={(newSelectState: any) =>
              this.handleChangeSelect(newSelectState)
            }
          />
        </StyledLabel>
        {Boolean(errors.performerType) && (
          <div style={{ display: "flex", marginTop: "8px" }}>
            <img
              style={{ margin: "0 4px 1px 0" }}
              alt="error icon"
              src={ErrorIcon}
            />
            <Typography color="error" variant="captionBold">
              {errors.performerType}
            </Typography>
          </div>
        )}
        <TextInput
          error={Boolean(errors.firstName)}
          errorMessage={errors.firstName}
          label={t("First name")}
          name="firstName"
          placeholder={t("Enter Details")}
          value={form.firstName}
          onBlur={(e: FocusEvent) =>
            this.validate(e.target as HTMLInputElement)
          }
          onChange={(e: ChangeEvent) => this.handleChange(e)}
        />
        <TextInput
          error={Boolean(errors.lastName)}
          errorMessage={errors.lastName}
          label={t("Last name")}
          name="lastName"
          placeholder={t("Enter Details")}
          value={form.lastName}
          onBlur={(e: FocusEvent) =>
            this.validate(e.target as HTMLInputElement)
          }
          onChange={(e: ChangeEvent) => this.handleChange(e)}
        />
        <TextInput
          error={Boolean(errors.email)}
          errorMessage={errors.email}
          label={t("Email")}
          name="email"
          placeholder={t("Enter Details")}
          value={form.email}
          onBlur={(e: FocusEvent) =>
            this.validate(e.target as HTMLInputElement)
          }
          onChange={(e: ChangeEvent) => this.handleChange(e)}
        />
        <TextInput
          error={Boolean(errors.password)}
          errorMessage={errors.password}
          label={t("Password")}
          name="password"
          placeholder={t("Enter Details")}
          type="password"
          value={form.password}
          onBlur={(e: FocusEvent) =>
            this.validate(e.target as HTMLInputElement)
          }
          onChange={(e: ChangeEvent) => this.handleChange(e)}
          onKeyDown={(e: KeyboardEvent) => {
            e.key === "Enter" && this.validateAllAndSubmit();
          }}
        />
        <StyledButton disabled={!canSubmit} onClick={this.validateAllAndSubmit}>
          {t("Sign me up!")}
        </StyledButton>
        <StyledTypography color="error" variant="caption">
          {serverError}
        </StyledTypography>
        <LoginContainer>
          <Typography component="span" variant="captionBold">
            {t("Already a member?")}
          </Typography>
          &nbsp;
          <Link href={`${process.env.REACT_APP_CWB_Site}/login/?${params}`}>
            {t("Login")}
          </Link>
        </LoginContainer>
      </StyledDiv>
    );
  }
}

const StyledDiv = styled.div`
  padding: ${(p) => p.theme.spacing(4, 3, 3)};

  & > label:not(:first-of-type) {
    margin-top: ${(p) => p.theme.spacing(2)};
    font-size: 14px;
    font-weight: 600;
  }

  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 40px;
  }
`;

const StyledLabel = styled.label`
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing(0)};

  & > p {
    margin-bottom: ${(p) => p.theme.spacing(1)};
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin: ${(p) => p.theme.spacing(3, 0, 2)};
  padding: ${(p) => p.theme.spacing(1, 2)};
  background-color: #02b8f9;
`;

const StyledTypography = styled(Typography)`
  margin: ${(p) => p.theme.spacing(-1, 0, 1)};
`;

const LoginContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`;

const Link = styled.a`
  && {
    color: #02b8f9;
    font-size: 12px;
    font-weight: bold;

    &:hover {
      color: #02b8f9;
      filter: brightness(1.15);
    }
  }
`;

type DispatchProps = {
  getPerformerTypes: () => any;
  createPerformer: (payload: {
    performerTypeIds: number[];
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) => any;
};

const mapDispatch = (dispatch: any) => ({
  getPerformerTypes: dispatch.performerModel.getPerformerTypes,
  createPerformer: dispatch.performerModel.createPerformer,
});

export default withI18n()(
  connect(null, mapDispatch)(withRouter(PerformerSignUpForm))
);
