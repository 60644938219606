import React, { useEffect, useState } from "react";
import qs from "query-string";
import { withI18n } from "react-i18next";
import { connect } from "react-redux";
import { Link as ReactRouterLink, RouteComponentProps } from "react-router-dom";
import {
  cleanCache,
  getCountryCode,
  timedSentrySendThenErrorPage,
} from "../../../../../helpers/index";
import styled from "styled-components";
import { Typography } from "components/Common";
import ActorTrialSignUpForm from "./ActorTrialSignUpForm";
import ActorSignUpForm from "../../../../CWBLabs/components/ActorSignUpForm";
import Card from "../../../../CWBLabs/components/Card";
import * as ServicesAPI from "../../../../../shared/api/Services.api";
import HeaderComponent from "../../../../HeaderComponent";
// @ts-ignore
import { CWBLoadingCrest } from "cwb-react";
import * as Sentry from "@sentry/react";
import moment from "moment";

type Props = {
  t: any;
} & StateProps &
  DispatchProps &
  RouteComponentProps;

const ActorTrialSignUp: React.FC<Props> = ({
  t,
  location,
  currencySymbol,
  history,
  yearlyPerMonthCharge,
  getSubscriptionServicesWithCountryCode,
  countryCode,
  setRequesterCountryCode,
  setForceRequesterCountryCode,
  setTrialServicesWorkflow
}) => {
  const fallbackOnError = "US";

  const [trialValid, setTrialValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [trialEndDate, setTrialEndDate] = useState("");
  let redirectParams = qs.stringify({
    ...qs.parse(location.search),
  });

  if (qs.parse(location.search).bd) {
    redirectParams = qs.stringify({
      page: "openbd",
      ...qs.parse(window.location.search),
    });
  }

  useEffect(() => {
    cleanCache();
  }, []);
  useEffect(() => {
    let countryCode = null;
    const fetchSubscriptionServices = async (code: string) => {
      try {
        await getSubscriptionServicesWithCountryCode(code);
      } catch (e) {
        timedSentrySendThenErrorPage(e);
      }
    };
    (async () => {
      try {
        const qsArgs = qs.parse(location.search)
        if (qsArgs.dev && qsArgs.ctry) {
          countryCode = qs.parse(location.search).ctry as string;
          setForceRequesterCountryCode(countryCode);
        }

        if (!countryCode && !getCountryCode()) {
          (async () => {
            try {
              await setRequesterCountryCode(fallbackOnError);
            } catch (e) {
              console.error(e);
              Sentry.captureException(e);
            }
          })();
        }

        if (countryCode || getCountryCode()) {
          let isDev = false;
          if (qs.parse(location.search).dev === "1") isDev = true;
          const ServiceResponse = await ServicesAPI.getTrialService(
            countryCode || getCountryCode(),
            isDev
          );
          if (
            ServiceResponse.status === 200 &&
            ServiceResponse.data &&
            ServiceResponse.data.serviceId
          ) {
            setTrialServicesWorkflow(true);
            setTrialValid(true);
            const expire = ServiceResponse.data.trialExpirationDate;
            const end = ServiceResponse.data.trialEndDate;
            let date = "2022-11-03";
            if (expire && end) {
              date = moment(expire).isBefore(moment(end)) ? expire : end;
            } else if (end) {
              date = end;
            }
            setTrialEndDate(moment(date).format("MMM Do, YYYY"));
            setLoading(false);
            fetchSubscriptionServices(countryCode || getCountryCode());
          } else {
            history.push(`/register?${redirectParams}`);
            setTrialServicesWorkflow(false);
          }
        }
      } catch (err) {
        Sentry.captureException(err);
        console.log(err);
      }
    })();
  }, [countryCode, redirectParams, history, location.search]);

  const params = qs.stringify({
    ...qs.parse(location.search),
  });

  return (
    <>
      <HeaderComponent />
      {loading ? (
        <StyledDiv>
          <CWBLoadingCrest customLogoSrc="/images/icon-cwb-logo-grey.svg" />
        </StyledDiv>
      ) : (
        <StyledDiv>
          <Typography align="center" component="h1" variant="h1">
            {t("Not a member yet?")}
          </Typography>
          <Subheading align="center" variant="h1">
            {t("Sign-up for our Free Trial until {{trialEndDate}}", {
              trialEndDate,
            })}
          </Subheading>
          <div style={{ display: "flex" }}>
            <Typography align="center" component="h4" variant="h4">
              {t(
                "Apply to this job and many others on Casting Workbook. New to Casting Workbook?"
              )}
            </Typography>
            <a
              href="https://home.castingworkbook.com/for-actors/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledTypography align="center" color="link" variant="h4">
                {t("Click here to find out more")}
              </StyledTypography>
            </a>
          </div>
          <StyledCard>
            <StyledTrialActorSignUpForm
              currencySymbol={currencySymbol}
              yearlyPerMonthCharge={yearlyPerMonthCharge}
              trialValid={trialValid}
            />
          </StyledCard>
          <Footnote align="center">
            {t(
              "Are you a Casting Director or Agent? Learn more about how Casting Workbook can support you."
            )}
          </Footnote>
          <LinksContainer>
            <StyledReactRouterLink to={`/register/agent?${params}`}>
              {t("Agents & Managers")}
            </StyledReactRouterLink>
            <StyledReactRouterLink to={`/register/casting?${params}`}>
              {t("Casting Directors")}
            </StyledReactRouterLink>
          </LinksContainer>
        </StyledDiv>
      )}
    </>
  );
};

const StyledDiv = styled.div`
  width: 889px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: ${(p) => p.theme.spacing(10, "auto", 0)};

  @media all and (max-width: 520px) {
    width: unset;
    margin-top: ${(p) => p.theme.spacing(3)};
    padding: ${(p) => p.theme.spacing(0, 2)};
  }
`;

const Subheading = styled(Typography)`
  margin: ${(p) => p.theme.spacing(0, 0, 2)};

  @media all and (max-width: 520px) {
    margin: ${(p) => p.theme.spacing(0, 0, 2)};
    padding: 20px;
  }
`;

const StyledCard = styled(Card)`
  width: 427px;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  margin: ${(p) => p.theme.spacing(4, 0, 4)};
  padding: 0;

  @media all and (max-width: 520px) {
    flex-flow: column nowrap;
  }
`;

const StyledTypography = styled(Typography)`
  margin-left: ${(p) => p.theme.spacing(1)};
  cursor: pointer;
`;

const StyledTrialActorSignUpForm = styled(ActorTrialSignUpForm)`
  width: 100%;

  @media all and (max-width: 520px) {
    width: 100%;
  }
`;

const StyledActorSignUpForm = styled(ActorSignUpForm)`
  width: 100%;

  @media all and (max-width: 520px) {
    width: 100%;
  }
`;

const Footnote = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(2)};
  line-height: 19px;
`;

const LinksContainer = styled.div`
  margin-bottom: ${(p) => p.theme.spacing(6)};

  & > a + a {
    margin-left: ${(p) => p.theme.spacing(6)};
  }
`;

const StyledReactRouterLink = styled(ReactRouterLink)`
  && {
    color: #02b8f9;
    font-weight: bold;

    &:hover {
      color: #02b8f9;
      filter: brightness(1.15);
    }
  }
`;

type StateProps = {
  currencySymbol: string;
  mediaLimit: number;
  yearlyPerMonthCharge: number;
  numPhotos: number;
  countryCode: string;
};

const mapStateToProps = (state: any) => ({
  currencySymbol: state.premiumModel.currencySymbol,
  mediaLimit: state.premiumModel.mediaLimit,
  numPhotos: state.premiumModel.headshot.premium,
  yearlyPerMonthCharge: state.premiumModel.yearlyPerMonthCharge,
  countryCode: state.referencesModel.countryCode 
});

type DispatchProps = {
  getSubscriptionServicesWithCountryCode: (code: string) => void;
  setRequesterCountryCode: (fallback?: string | null) => void;
  setForceRequesterCountryCode: (countryCode: string) => void;
  setTrialServicesWorkflow: (trialServicesWorkflow: boolean) => void;
};

const mapDispatchToProps = (dispatch: any) => ({
  getSubscriptionServicesWithCountryCode:
    dispatch.premiumModel.getSubscriptionServicesWithCountryCode,
  setRequesterCountryCode: dispatch.referencesModel.setRequesterCountryCode,
  setForceRequesterCountryCode: dispatch.referencesModel.setForceRequesterCountryCode,
  setTrialServicesWorkflow: dispatch.actorModel.setTrialServicesWorkflow
});

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorTrialSignUp)
);
