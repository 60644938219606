import axios from "axios";
import { IStripeCharge } from "./dtos/IStripeDto";
// @ts-ignore
import { getAuthConfig } from "cwb-react";
import { getTrackingData } from "helpers";

export const upgradeAddOn = (dto: IStripeCharge) => {
  dto.trackingData = getTrackingData();
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_REG_Api}/StripeTrialSubscription/upgrade/add-on`,
    headers: {
      "Content-Type": "application/json",
      CWB: "1",
      ...getAuthConfig().headers,
    },
    data: dto,
    withCredentials: true,
  });
};
