import React from "react";
import { withI18n } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import styled, { css } from "styled-components";
import { Button, Typography } from "components/Common";
import { Spinner } from "components/Common/StyledComponents";
import Card from "./components/Card";
import Footer from "./components/Footer";
import Page from "./components/Page";
import { connect } from "react-redux";
import { IActorError, IActorPhone } from "shared/api/dtos/IActorDto";
import { IGroupedCountry } from "helpers/ICountryList";
import { iRootState } from "./../../store";
import { phoneValidation } from "helpers/validations";
import { getCountryCode } from "helpers";
import qs from "querystring";

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location?: any;
}

interface IState {
  isFormSubmitClicked: boolean;
  fields: IActorPhone;
  customError?: string;
}

class ActorCreatePhoneStep extends React.Component<IProps, IState> {
  public state: IState = {
    isFormSubmitClicked: false,
    fields: { phone: "" },
  };

  async componentDidMount() {
    if (!this.props.countryCode && !getCountryCode()) {
      const qsArgs = this.props.location.search ? { ...qs.parse(this.props.location.search) } as any : {};
      if (qsArgs.dev && qsArgs.ctry) {
        await this.props.setForceRequesterCountryCode(qsArgs.ctry)
      } else {
        try {
          await this.props.setRequesterCountryCode();
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  checkValidation() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IActorError;
    if (!fields.phone) {
      errors.Phone = t("Invalid Phone Number");
    } else if (phoneValidation(fields.phone, t))
      errors.Phone = phoneValidation(fields.phone, t);

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  checkNumber() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IActorError;

    if (!fields.phone || fields.phone.match(/^[0-9`]+$/i)) {
      this.props.setErrors(errors);
      return true;
    }

    errors.Phone = t("Only numbers are accepted");
    fields.phone = "";
    this.setState({ fields });
    this.props.setErrors(errors);
    return false;
  }

  handleChange = (phoneNumber: string) => {
    this.setState({
      fields: {
        ...this.state.fields,
        phone: phoneNumber,
      },
    });
    this.checkNumber();
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  getNumber = (): string => {
    return `+${this.state.fields.phone}`;
  };

  handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      this.createAccount(event);
    }
  };

  createAccount = async (e: any) => {
    e.preventDefault();
    if (this.props.isLoading) return;
    this.setState({ ...this.state, customError: '', isFormSubmitClicked: true });
    if (!this.checkValidation()) return;
    await this.props.createActorPhone({ phone: this.getNumber() });
    this.moveToNext();
  };

  moveToNext = () => {
    if (this.props.errors.errorMessage) {
      this.setState({ ...this.state, customError: this.props.errors.errorMessage })
    } else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/actor/2",
        search: this.props.location.search,
      });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  changeVerifyMethod = () => {
    this.props.setSelectPhone(false);
  };
  render() {
    const { t, countryCode, errors, isLoading } = this.props;
    return (
      <StyledPage>
        <StyledCard>
          <Typography align="center" component="h1" gutterBottom variant="h2">
            {t("Let’s add your phone number for your security")}
          </Typography>
          <Typography align="center" color="medGrey">
            {t(
              `We'll send you a confirmation code to verify your phone number.`
            )}
          </Typography>
          <Form onSubmit={this.createAccount}>
            <FormLabel>
              {t("Phone Number")}
              <PhoneInput
                containerStyle={{ marginTop: "8px" }}
                country={countryCode ? countryCode.toLowerCase() : ""}
                value={this.state.fields.phone}
                onChange={this.handleChange}
                onEnterKeyPress={this.handleKeyPress}
                inputProps={{
                  autoFocus: true,
                }}
              />
            </FormLabel>
            <Typography color="error" variant="captionBold">
              {errors.Phone}
            </Typography>

            {this.state.customError && (
              <Typography color="error" variant="captionBold">
                {this.state.customError}
              </Typography>
            )}
            <StyledTypography align="center" color="medGrey">
              {t(
                `We only use this to verify your account. We'll never contact you without your permission.`
              )}
            </StyledTypography>
            <StyledButton
              disabled={isLoading}
              startIcon={
                isLoading && (
                  <Spinner
                    className="spinner-width"
                    src="../images/spinner.svg"
                  />
                )
              }
            >
              {t("Send Confirmation Code")}
            </StyledButton>
          </Form>
          <StyledAnchor onClick={this.changeVerifyMethod}>
            {t("Verify By Email")}
          </StyledAnchor>
        </StyledCard>
        <StyledFooter />
      </StyledPage>
    );
  }
}

const StyledPage = styled(Page)`
  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding-top: 8px;
    border: none;
  }
`;

const StyledCard = styled(Card)`
  width: 480px;
  padding: ${(p) => p.theme.spacing(4, 10)};

  @media all and (max-width: 520px) {
    width: 100%;
    padding: 40px;
    border-top: 0;
    border-radius: 0;
  }
`;

const Form = styled.form`
  width: 100%;
`;

const FormLabel = styled.label`
  width: 100%;
  color: ${(p) => p.theme.typography.color.darkGrey};
  margin-top: ${(p) => p.theme.spacing(2)};
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`;

const StyledTypography = styled(Typography)`
  background-color: #f8f8f9;
  margin: ${(p) => p.theme.spacing(2, 0, 3)};
  padding: ${(p) => p.theme.spacing(2)};
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

const StyledFooter = styled(Footer)`
  margin-top: ${(p) => p.theme.spacing(3)};
`;

const StyledAnchor = styled.a<{ disabled?: boolean }>`
  && {
    color: #00a2e0;
    font-size: ${(p) => p.theme["xs-font-size"]};
    font-weight: ${(p) => p.theme["font-weight-600"]};
    letter-spacing: 0.21px;
    line-height: 15px;
    text-align: center;
    margin-top: 8px;
    cursor: pointer;

    &:hover {
      color: ${(p) => p.theme.lightBlue};
    }

    ${(p) =>
    p.disabled &&
    css`
        color: ${(p) => p.theme.typography.color.disabled};
        cursor: default;

        &:hover {
          color: ${(p) => p.theme.typography.color.disabled};
        }
      `}
  }
`;

interface StateProps {
  errors: IActorError;
  fields: IActorPhone;
  groupCountryList: IGroupedCountry[];
  isLoading: boolean;
  countryCode: string;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    errors: state.actorModel.errors,
    fields: state.actorModel.fields,
    groupCountryList: state.referencesModel.groupCountryList,
    isLoading: state.actorModel.isLoading,
    countryCode: state.referencesModel.countryCode,
  };
}

interface DispatchProps {
  setErrors: (errors: IActorError) => void;
  createActorPhone: (fields: IActorPhone) => void;
  getGroupCountryList: () => void;
  setSelectPhone: (phoneSelected: boolean) => void;
  setRequesterCountryCode: (fallback?: string | null) => void;
  setForceRequesterCountryCode: (countryCode : string) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    setErrors: dispatch.actorModel.setErrors,
    createActorPhone: dispatch.actorModel.createActorPhone,
    getGroupCountryList: dispatch.referencesModel.getGroupCountryList,
    setSelectPhone: dispatch.actorModel.setSelectPhone,
    setRequesterCountryCode: dispatch.referencesModel.setRequesterCountryCode,
    setForceRequesterCountryCode: dispatch.referencesModel.setForceRequesterCountryCode
  };
}

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorCreatePhoneStep)
);
