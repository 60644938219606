import React from "react";
import qs from "query-string";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withI18n } from "react-i18next";
import { CardElement, injectStripe } from "react-stripe-elements";
import styled, { css } from "styled-components";
import BackButton from "../Common/BackButton";
import Footer from "../Common/Footer";
import Modal from "../Common/Modal";
import PageContainer from "../Common/PageContainer";
import Tooltip from "../Common/Tooltip";
import {
  Section,
  QuestionSection,
  SectionTitle,
  StyledInput,
  SmallTextError,
  StyledBlueBtn,
  SmallText,
  Spinner,
  CheckboxWrapperProduct,
  StyledCheckbox,
} from "../Common/StyledComponents";
import CWBLabsFooter from "components/CWBLabs/components/Footer";
import Typography from "../Common/Typography";
import { iRootState } from "../../store";
import {
  IProductSubscriptionService,
  ISubscriptionService,
} from "../../shared/api/dtos/IServicesDto";
import {
  IStripeResponse,
  IStripeCharge,
  ICreditCard,
  ISubscriptionResponse,
  IStripePaymentError,
  IStripePaymentForm,
  IMailingAddress,
  IStripeChargeProduct,
} from "../../shared/api/dtos/IStripeDto";
import { postalCodeValidation } from "../../helpers/validations";
import { timedSentrySendThenErrorPage, toFixedTrunc } from "helpers";
import {
  IDiscount,
  IDiscountWithClientId,
} from "shared/api/dtos/IPromoCodeDto";
import grungeEffect from "../../images/grunge.png";

interface IProps extends StateProps, DispatchProps, RouteComponentProps {
  t?: any;
  stripe?: any;
  country: string;
  provinceId: number;
  onSubmit: (apiPayload: IStripeChargeProduct) => void;
  moveToNext: (serviceId: number) => void;
  // onSkip?: () => void;
  clientId?: number;
  product: any;
  promoCode?: any;
}

interface IState {
  fields: any;
  mailingFields: any;
  subtotal: string;
  shippingFee: string;
  total: string;
  currencySymbol: string;
  sameMailing: Boolean;
  discountApplied: boolean;
  discountAmount: Number;
  discountInputDisabled: boolean;
  discountMessage: string;
  discount: any;
}

class Membership extends React.Component<IProps, IState> {
  state: IState = {
    fields: {
      name: "",
      postalCode: "",
      serviceId: 0,
      couponCode: "",
      addressLine1: "",
    } as IStripePaymentForm,
    mailingFields: {
      city: "",
      state: "",
      addressLine1: "",
      zipCode: "",
    } as IMailingAddress,
    subtotal: "0.00",
    shippingFee: "0.00",
    total: "0.00",
    currencySymbol: "$",
    sameMailing: false,
    discountMessage: "",
    discount: null,
    discountApplied: false,
    discountAmount: 0,
    discountInputDisabled: false,
  };

  async componentDidMount() {
    try {
      if (this.props.product === "homekit") {
        await this.getService(189);
      }
      this.setService();
      if (this.props.promoCode) {
        await this.applyPromoCode();
      }
    } catch (e) {
      timedSentrySendThenErrorPage(e);
    }
  }

  /* static getDerivedStateFromProps(props: any, state: any) {
    if (
      props.serviceList !== state.serviceList ||
      props.subscriptionDetails !== state.subscriptionDetails
    ) {
      return {
        serviceList: props.serviceList,
        subscriptionDetails: props.subscriptionDetails,
      };
    }
    return null;
  } */

  applyPromoCode = async () => {
    let fields = this.state.fields;
    fields["couponCode"] = this.props.promoCode;
    this.setState({ fields });
    const discountWithClientId: IDiscountWithClientId = {
      code: this.props.promoCode,
      clientId: this.props.clientId,
    };
    if (this.props.clientId) {
      const discountResponse = await this.props.getDiscountByClientId(
        discountWithClientId
      );
      this.setState(
        {
          discount: discountResponse,
          fields: discountResponse.isValid
            ? this.state.fields
            : { ...this.state.fields, couponCode: "" },
          discountMessage: discountResponse.isValid
            ? this.props.t("Valid code: {{code}}", {
                code: this.props.promoCode,
              })
            : this.props.t("Invalid code: {{code}}", {
                code: this.props.promoCode,
              }),
          discountInputDisabled: discountResponse.isValid ? true : false,
        },
        () => {
          this.setTotal();
        }
      );
    } else {
      const discountResponse = await this.props.getDiscount(
        this.props.promoCode
      );
      this.setState(
        {
          discount: discountResponse,
          fields: discountResponse.isValid
            ? this.state.fields
            : { ...this.state.fields, couponCode: "" },
          discountMessage: discountResponse.isValid
            ? this.props.t("Valid code: {{code}}", {
                code: this.props.promoCode,
              })
            : this.props.t("Invalid code: {{code}}", {
                code: this.props.promoCode,
              }),
          discountInputDisabled: discountResponse.isValid ? true : false,
        },
        () => {
          this.setTotal();
        }
      );
    }
  };

  setTotal = () => {
    const { discount } = this.state;
    const service = this.props.productService;
    if (!service)
      return;
    const isYearly = service.durationMonths === 12;
    let total = "";

    if (discount && discount.isValid && isYearly) {
      const { code } = discount;
      const message = this.props.t("Valid code: {{code}}", { code });
      this.setState({
        discountMessage: message,
        discountApplied: true,
      });
      this.getDiscountedTotal(discount, service);
    } else if (discount && discount.isValid && !isYearly) {
      const { code } = discount;
      const message = this.props.t(
        "Valid code: {{code}} only applies to yearly service",
        { code }
      );
      this.setState({
        discountMessage: message,
        discountApplied: false,
      });
      total = this.getTotal(service);
      this.updatePrice(
        this.getSubTotal(service),
        this.getTax(service.price, service.tax).toFixed(2),
        total
      );
    } else {
      total = this.getTotal(service);
      this.setState({
        discountApplied: false,
      });
      this.updatePrice(
        this.getSubTotal(service),
        this.getTax(service.price, service.tax).toFixed(2),
        total
      );
    }
  };

  getDiscountedTotal = (
    coupon: IDiscount,
    service: IProductSubscriptionService
  ): string => {
    if (!coupon || !service) return service.price.toFixed(2);

    let price = service.price;

    let discountAmount = coupon.discountAmount;

    if (coupon.isDiscountPercent)
      discountAmount = (price / 100) * coupon.discountAmount;

    let newPrice = price - discountAmount;
    const tax = this.getTax(newPrice, service.tax);
    const total = (newPrice + tax).toFixed(2);

    this.updatePrice(price.toFixed(2), tax.toFixed(2), total);
    this.setState({
      discountAmount,
    });
    return total;
  };

  getTax = (price: number, tax: number): number => {
    if (!price || !tax) return 0;

    return (price * tax) / 100;
  };

  getService = async (serviceId: number) => {
    await this.props.getProductService(serviceId);
  };

  setService = () => {
    let fields = this.state.fields;
    const service = this.props.productService;
    fields.serviceId = service.serviceId;
    this.setState({ fields });
    const total = this.getTotal(service);
    if (service.currency === "EUR") this.setState({ currencySymbol: "€" });
    this.updatePrice(
      this.getSubTotal(service),
      this.getShippingFee(service).toFixed(2),
      total
    );
  };

  getShippingFee = (service: IProductSubscriptionService): number => {
    if (!service.hasOneTimeFee) return 0;
    else {
      return service.oneTimeFee;
    }
  };

  getSubTotal = (service: IProductSubscriptionService): string => {
    if (!service || !service.price) return "0.00";
    return service.price.toFixed(2);
  };

  getTotal = (service: IProductSubscriptionService): string => {
    if (!service || !service.price) return "0.00";
    const oneTimeFee = service.hasOneTimeFee ? service.oneTimeFee : 0;
    return (service.price + oneTimeFee).toFixed(2);
  };

  updatePrice = (subtotal: string, shippingFee: string, total: string) => {
    this.setState({ total });
    this.setState({ shippingFee });
    this.setState({ subtotal });
  };

  handleChange = async (e: any) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    // if (e.target.name === "postalCode") {
    //   await this.handleOnBlurPostal(e);
    // }
  };

  // handleOnBlurPostal = async (e: any) => {
  //   if (!e || !e.target || !e.target.value) return;
  //   await this.getService(e.target.value);
  //   this.setTotal();
  // };

  handleMailingChange = async (e: any) => {
    let mailingFields = this.state.mailingFields;
    mailingFields[e.target.name] = e.target.value;
    this.setState({ mailingFields });
  };

  handleSameMailingClick = () => {
    this.setState({
      sameMailing: !this.state.sameMailing,
    });
  };

  checkValidation(): boolean {
    const { t } = this.props;
    let fields: IStripePaymentForm = this.state.fields;
    let mailingFields: IMailingAddress = this.state.mailingFields;
    const { sameMailing } = this.state;
    let errors = {} as IStripePaymentError;

    if (!fields.name) errors.name = t("Name on Card field is required");

    if (!fields.addressLine1) errors.addressLine1 = t("Address is required");

    if (!fields.postalCode)
      errors.addressZip = t("Postal / Zip Code field is required");

    const validationError = postalCodeValidation(
      fields.postalCode.replace(/\s/g, ""),
      t
    );
    if (validationError) errors.addressZip = validationError;

    if (!sameMailing) {
      if (!mailingFields.addressLine1)
        errors.mailingAddressLine1 = t("Address is required");

      if (!mailingFields.city) errors.mailingCity = t("City is required");

      if (!mailingFields.state) errors.mailingState = t("State is required");

      if (!mailingFields.zipCode)
        errors.mailingZipCode = t("Postal / Zip Code field is required");

      const validationError = postalCodeValidation(
        mailingFields.zipCode.replace(/\s/g, ""),
        t
      );
      if (validationError) errors.mailingZipCode = validationError;
    }

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  handleSubmit = async () => {
    if (this.props.isLoading || !this.checkValidation()) return;
    let fields = this.state.fields;
    let mailingFields = this.state.mailingFields;

    try {
      this.props.setIsLoading(true);
      let { token, error } = await this.props.stripe.createToken();

      if (error && error.message) {
        let errors = {} as IStripePaymentError;
        // TODO: how to translate erorr message? check i18 doc
        errors.errorMessage = error.message;
        this.props.setErrors(errors);
        this.props.setIsLoading(false);
        return;
      }

      const stripeResponse: IStripeResponse = token;

      const apiPayload = {} as IStripeChargeProduct;
      apiPayload.token = stripeResponse.id;

      apiPayload.card = {} as ICreditCard;
      apiPayload.card.brand = stripeResponse.card.brand;
      apiPayload.card.expMonth = stripeResponse.card.exp_month;
      apiPayload.card.expYear = stripeResponse.card.exp_year;
      apiPayload.card.last4 = stripeResponse.card.last4;

      apiPayload.card.name = fields.name;
      apiPayload.card.addressZip = fields.postalCode.replace(/\s/g, "");
      apiPayload.card.addressLine1 = fields.addressLine1;
      apiPayload.couponCode = fields.couponCode;
      apiPayload.serviceId = fields.serviceId;
      apiPayload.provinceId = this.props.provinceId;

      apiPayload.hasMailingAddress = this.state.sameMailing ? false : true;

      if (!this.state.sameMailing) {
        apiPayload.mailingAddress = {} as IMailingAddress;
        apiPayload.mailingAddress.city = mailingFields.city;
        apiPayload.mailingAddress.addressLine1 = mailingFields.addressLine1;
        apiPayload.mailingAddress.zipCode = mailingFields.zipCode;
        apiPayload.mailingAddress.state = mailingFields.state;
      }

      await this.props.onSubmit(apiPayload);

      this.moveToNext(apiPayload.serviceId);
    } catch (e) {
      timedSentrySendThenErrorPage(e);
    }
  };

  moveToNext = (serviceId: number) => {
    if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.moveToNext(serviceId);
    }
  };

  handleDiscountClick = async (e: any) => {
    if (!this.state.fields.couponCode) return;
    const inputCode = this.state.fields.couponCode;
    if (this.props.clientId) {
      const discountWithClientId: IDiscountWithClientId = {
        code: inputCode,
        clientId: this.props.clientId,
      };
      const discountResponse = await this.props.getDiscountByClientId(
        discountWithClientId
      );
      this.setState(
        {
          discount: discountResponse,
          fields: discountResponse.isValid
            ? this.state.fields
            : { ...this.state.fields, couponCode: "" },
          discountMessage: discountResponse.isValid
            ? this.props.t("Valid code: {{code}}", { code: inputCode })
            : this.props.t("Invalid code: {{code}}", { code: inputCode }),
          discountInputDisabled: discountResponse.isValid ? true : false,
        },
        () => {
          this.setTotal();
        }
      );
    } else {
      const discountResponse = await this.props.getDiscount(inputCode);
      this.setState(
        {
          discount: discountResponse,
          fields: discountResponse.isValid
            ? this.state.fields
            : { ...this.state.fields, couponCode: "" },
          discountMessage: discountResponse.isValid
            ? this.props.t("Valid code: {{code}}", { code: inputCode })
            : this.props.t("Invalid code: {{code}}", { code: inputCode }),
          discountInputDisabled: discountResponse.isValid ? true : false,
        },
        () => {
          this.setTotal();
        }
      );
    }
  };

  cancelDiscountClick = async (e: any) => {
    if (!this.state.fields.couponCode) return;
    this.setState(
      {
        discount: null,
        fields: { ...this.state.fields, couponCode: "" },
        discountMessage: "",
        discountApplied: false,
        discountInputDisabled: false,
      },
      () => {
        this.setTotal();
      }
    );
  };
  parseCurrencyString = (price: string): string => {
    if (this.state.currencySymbol === "€") {
      return price.replace(".", ",");
    } else {
      return price;
    }
  };

  getDiscountedTotalWithoutTax = (
    coupon: IDiscount,
    service: IProductSubscriptionService
  ): string => {
    if (!coupon || !service) return service.price.toFixed(2);

    let price = service.price;

    let discountAmount = coupon.discountAmount;

    if (coupon.isDiscountPercent)
      discountAmount = (price / 100) * coupon.discountAmount;

    let newPrice = price - discountAmount;

    return newPrice.toFixed(2);
  };

  getMonthlyPrice = (discount: IDiscount): string => {
    let price = 0;

    const service = this.props.productService;

    if (service.price <= 1) price = service.price;

    let cost = service.price;
    if (discount && discount.isValid) {
      let discountedTotal = this.getDiscountedTotalWithoutTax(
        discount,
        service
      );
      let toNumber = discountedTotal ? Number(discountedTotal) : cost;
      cost = toNumber;
    }

    price = Number(cost / service.durationMonths);
    price = toFixedTrunc(price, 2);
    if (this.state.currencySymbol === "€") {
      return price.toString().replace(".", ",");
    } else {
      return price.toString();
    }
  };

  getDiscountedPercent = (): string => {
    const { discount } = this.state;
    const service = this.props.productService;
    if (!discount || !service) return "";

    if (discount.isDiscountPercent) return discount.discountAmount.toFixed(0);

    let price = service.price;
    let discountAmount = discount.discountAmount;
    return ((discountAmount / price) * 100).toFixed(0);
  };

  render() {
    const { t, location, history } = this.props;
    const { sameMailing } = this.state;
    const errors: IStripePaymentError = this.props.errors;
    const fields: IStripePaymentForm = this.state.fields;
    const mailingFields: IMailingAddress = this.state.mailingFields;
    const serv: IProductSubscriptionService = this.props.productService;
    const currencySymbol = this.state.currencySymbol;

    const isTablet = window.matchMedia("(max-width: 768px)").matches;
    const currentArgs = qs.parse(location.search);
    const isCWBLabs = currentArgs.cwblabs;

    let messageColor = "";

    if (
      this.state.discount &&
      this.state.discount.isValid &&
      this.state.discountApplied
    ) {
      messageColor = "light-green";
    } else if (this.state.discount && this.state.discount.isValid) {
      messageColor = "orange";
    } else if (this.state.discount && !this.state.discount.isValid) {
      messageColor = "red";
    }

    const buildDiscount = () => {
      if (!this.state.discountAmount) return `${currencySymbol}0.00`;
      return `${currencySymbol}${this.parseCurrencyString(
        this.state.discountAmount.toFixed(2)
      )}`;
    };

    const onStandardPlansClick = (ev :any) => {
      ev.preventDefault();
      let params = JSON.parse(JSON.stringify(currentArgs));
      delete params.product;
      delete params.address;
      history.push(location.pathname + '?' + qs.stringify(params));
    }

    const getCurrentUrlWithoutHomeKit = () => {
      let params = JSON.parse(JSON.stringify(currentArgs));
      delete params.product;
      delete params.address;
      delete params.hs;
      return location.pathname + '?' + qs.stringify(params);
    }

    const discountPercent = this.getDiscountedPercent();

    const isHomeKit = this.props.product === "homekit";
    let isUsa = this.props.country.toLocaleUpperCase().trim() == "US"
                    || (qs.parse(this.props.location.search).ctry && qs.parse(this.props.location.search).ctry
                                                                      .toString()
                                                                      .toLocaleUpperCase().trim() == "US");
    if (!isUsa && isHomeKit) {
      if (qs.parse(this.props.location.search).dev) {
        isUsa = true;
      }
    }

    return (
      <StyledPageContainer isCWBLabs={isCWBLabs}>
        <BackButton width={isTablet ? 690 : 880} />
        <Wrapper isCWBLabs={isCWBLabs}>
          <StyledTitle>{t("Start your Membership")}
          {
            (isHomeKit && this.props.productService.serviceId === 189 && isUsa) ? 
            <>
              {" "}{t("and")} <br/>
              {t("get a Home-Kit for FREE!")}
            </> : 
            <></>
          }
          </StyledTitle>
          <RowColumnWrapper>
            <Row>
              <ColumnBilling>
                <BillingWrapper>
                  <Section className="margin-bottom-none">
                    <SectionLargeTitle>{t("Billing")}</SectionLargeTitle>
                  </Section>
                  <Section>
                    <SectionTitle>{t("Name on card")}</SectionTitle>
                    <StyledInput
                      name="name"
                      type="text"
                      className={errors.Name ? "invalid" : ""}
                      maxLength={256}
                      onChange={this.handleChange}
                      value={fields.name}
                    />
                    <SmallTextError className="error">
                      <span>{errors.name}</span>
                    </SmallTextError>
                  </Section>
                  <Section>
                    {/* <SectionTitle>{t("Card Details")}</SectionTitle> */}
                    <CardElement
                      hidePostalCode={true}
                      className="form-control"
                    />
                    <SmallTextError className="error">
                      <span>{errors.errorMessage}</span>
                    </SmallTextError>
                  </Section>
                  <Section>
                    <SectionTitle>{t("Billing Address")}</SectionTitle>
                    <StyledInput
                      name="addressLine1"
                      type="text"
                      className={errors.Name ? "invalid" : ""}
                      maxLength={256}
                      onChange={this.handleChange}
                      value={fields.addressLine1}
                    />
                    <SmallTextError className="error">
                      <span>{errors.addressLine1}</span>
                    </SmallTextError>
                  </Section>
                  <MailingSection>
                    <SectionTitle>
                      {t("Billing Postal / Zip Code")}
                    </SectionTitle>
                    <StyledInput
                      name="postalCode"
                      type="text"
                      className={errors.Name ? "invalid" : ""}
                      maxLength={256}
                      onChange={this.handleChange}
                      value={fields.postalCode}
                      // onBlur={this.handleOnBlurPostal}
                    />
                    <SmallTextError className="error">
                      <span>{errors.addressZip}</span>
                    </SmallTextError>
                  </MailingSection>
                  {/* <Section>
                    <CheckboxWrapperProduct
                      onClick={this.handleSameMailingClick}
                    >
                      <StyledCheckbox
                        className="small-cb"
                        isChecked={sameMailing}
                      />
                      <StyledLabel>
                        {t("Mailing Address same as billing address")}
                      </StyledLabel>
                    </CheckboxWrapperProduct>
                  </Section> */}
                  {!sameMailing && (
                    <>
                      <Section>
                        <SectionTitle>{t("Mailing Address")}</SectionTitle>
                        <StyledInput
                          name="addressLine1"
                          type="text"
                          className={errors.Name ? "invalid" : ""}
                          maxLength={256}
                          onChange={this.handleMailingChange}
                          value={mailingFields.addressLine1}
                        />
                        <SmallTextError className="error">
                          <span>{errors.mailingAddressLine1}</span>
                        </SmallTextError>
                      </Section>

                      <MailingSection>
                        <SectionTitle>{t("Mailing City")}</SectionTitle>
                        <StyledInput
                          name="city"
                          type="text"
                          className={errors.Name ? "invalid" : ""}
                          maxLength={256}
                          onChange={this.handleMailingChange}
                          value={mailingFields.city}
                        />
                        <SmallTextError className="error">
                          <span>{errors.mailingCity}</span>
                        </SmallTextError>
                      </MailingSection>

                      <MailingWrapper>
                        <MailingSection>
                          <SectionTitle>{t("Mailing State")}</SectionTitle>
                          <StyledInput
                            name="state"
                            type="text"
                            className={errors.Name ? "invalid" : ""}
                            maxLength={256}
                            onChange={this.handleMailingChange}
                            value={mailingFields.state}
                          />
                          <SmallTextError className="error">
                            <span>{errors.mailingState}</span>
                          </SmallTextError>
                        </MailingSection>
                        <MailingSection>
                          <SectionTitle>{t("Mailing Postal")}</SectionTitle>
                          <StyledInput
                            name="zipCode"
                            type="text"
                            className={errors.Name ? "invalid" : ""}
                            maxLength={256}
                            onChange={this.handleMailingChange}
                            value={mailingFields.zipCode}
                          />
                          <SmallTextError className="error">
                            <span>{errors.mailingZipCode}</span>
                          </SmallTextError>
                        </MailingSection>
                      </MailingWrapper>
                    </>
                  )}

                  <InfoText>
                    {t(
                      `By completing this purchase, I will enter into Agreement with Casting Workbook Services, Inc. ("Casting Workbook"). For the price of my enrolment, my photograph(s) and other information will be placed online in Casting Workbook, on my agent's roster (if represented) or the Talent Scout database (if unrepresented). I understand that my photograph(s) and other portfolio materials will be made available to casting director members and talent agent members (where applicable) for the duration of my membership.`
                    )}
                  </InfoText>
                  <InfoText>
                    {t(
                      `I understand and agree that Casting Workbook makes no guarantee or promise, either expressed or implied, that I will obtain work, but only that my photograph(s) and other information supplied by myself will be made available to industry professionals utilizing the Casting Workbook database.`
                    )}
                  </InfoText>
                  <Section>
                    <StyledBlueBtn
                      disabled={this.props.isLoading}
                      onClick={this.handleSubmit}
                    >
                      {t("Complete Purchase")}
                      {this.props.isLoading && (
                        <Spinner
                          src="../images/spinner.svg"
                          className="spinner-width"
                        />
                      )}
                    </StyledBlueBtn>
                  </Section>
                </BillingWrapper>
              </ColumnBilling>
              <ColumnMembership>
                <MembershipContainer>
                  <MembershipWrapper>
                    <Section className="margin-bottom-none">
                      <SectionLargeTitleService>
                        {t("Actor Membership")}
                      </SectionLargeTitleService>
                    </Section>
                    
                    <Section className="margin-bottom-none">
                      {serv && (
                        <>
                          <PriceBox>
                            <div>
                              {/* <PriceTitle>{t("ANNUAL PLAN")}</PriceTitle> */}
                              <PriceTitle>{serv.description}*</PriceTitle>
                              
                            {(isHomeKit && this.props.productService.serviceId === 189) 
                                ? (<StyledHomeKitLink target="_blank" href="https://home.castingworkbook.com/homekit-info/">{t("What's a Home-Kit?")}</StyledHomeKitLink>)
                              : (<></>)}
                            </div>
                            <PriceTotal>
                              {currencySymbol}
                              {this.getMonthlyPrice(this.state.discount)}
                              <PriceTotalSub>{t("/month")}</PriceTotalSub>
                            </PriceTotal>

                            <PriceBill>
                              <b>({t("billed annually")})</b>
                            </PriceBill>

                            {this.state.discountApplied && (
                              <PriceBill>{t("One time discount")}</PriceBill>
                            )}

                          </PriceBox>
                        </>
                      )}

                      <PriceTip>
                        <>{t("*While supplies lasts.")} &nbsp;</>
                        {!serv || serv.durationMonths !== 1 && (
                          <>{t("*Recurring billing.")}</>
                        )}
                      </PriceTip>

                    </Section>
                    {this.state.discountApplied && discountPercent && (
                      <FloatingDiv>
                        <DiscountStamp>
                          {discountPercent}
                          {"%   " + t("OFF")}
                        </DiscountStamp>
                      </FloatingDiv>
                    )}
                  </MembershipWrapper>   
                     {(isHomeKit && this.props.productService.serviceId === 189) 
                              ? (<AdditionalStyledLink onClick={(ev) => onStandardPlansClick(ev)} href={getCurrentUrlWithoutHomeKit()}>{t("Looking for other plans?")}</AdditionalStyledLink>)
                              : (<></>)}             
                  <Divider />
                  <MembershipWrapper>
                    <Section>
                      <SectionTitle>{t("Discount Code")}</SectionTitle>
                      <WrapperDiscount>
                        <InputDiscount
                          value={this.state.fields.couponCode}
                          name="couponCode"
                          onChange={this.handleChange}
                          disabled={this.state.discountInputDisabled}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              this.handleDiscountClick(e);
                            }
                          }}
                        />
                        {!this.state.discountInputDisabled ? (
                          <ButtonDiscount onClick={this.handleDiscountClick}>
                            {t("Apply")}
                          </ButtonDiscount>
                        ) : (
                          <ButtonDiscount onClick={this.cancelDiscountClick}>
                            {t("Cancel")}
                          </ButtonDiscount>
                        )}
                      </WrapperDiscount>
                    </Section>
                    <Section>
                      <SmallText>
                        <span
                          className={
                            /* this.state.fields.couponCode ? "light-green" : "red" */
                            messageColor
                          }
                        >
                          {" "}
                          {this.state.discountMessage}
                        </span>
                      </SmallText>
                    </Section>
                  </MembershipWrapper>
                  <Divider />
                  <MembershipWrapper>
                    <PriceTextWrapper>
                      <PriceTextBox>
                        <PriceTextLeft>{t("Subtotal")}</PriceTextLeft>
                        <PriceTextRight>{`${currencySymbol}${this.state.subtotal}`}</PriceTextRight>
                        <PriceTextClear></PriceTextClear>
                      </PriceTextBox>
                    </PriceTextWrapper>
                  </MembershipWrapper>

                  {/* Home Kit - Removed Shipping Fee */}
                  {/* <MembershipWrapper>
                    <PriceTextWrapper>
                      <PriceTextBox>
                        <PriceTextLeft>{t("Shipping Fee")}</PriceTextLeft>
                        <PriceTextRight>{`${currencySymbol}${this.state.shippingFee}`}</PriceTextRight>
                        <PriceTextClear></PriceTextClear>
                      </PriceTextBox>
                    </PriceTextWrapper>
                  </MembershipWrapper> */}
                  {this.state.discountApplied && (
                    <>
                      <Divider />
                      <MembershipWrapper>
                        <PriceTextWrapper>
                          <PriceTextBox>
                            <PriceTextLeft>{t('Discount')}</PriceTextLeft>
                            <PriceTextRight>{`${buildDiscount()}`}</PriceTextRight>
                            <PriceTextClear></PriceTextClear>
                          </PriceTextBox>
                        </PriceTextWrapper>
                      </MembershipWrapper>
                    </>
                  )}
                  <DueTodayDivider />
                  <DueTodayWrapper>
                    <PriceTextWrapper>
                      <PriceTextBox>
                        <PriceTextLeft>{t("Due Today")}</PriceTextLeft>
                        <PriceTextRight>{`${currencySymbol}${this.state.total}`}</PriceTextRight>
                        <PriceTextClear></PriceTextClear>
                      </PriceTextBox>
                    </PriceTextWrapper>
                  </DueTodayWrapper>
                </MembershipContainer>
              </ColumnMembership>
            </Row>
          </RowColumnWrapper>
        </Wrapper>
        {isCWBLabs ? <StyledCWBLabsFooter /> : <Footer />}
      </StyledPageContainer>
    );
  }
}

interface StateProps {
  subscriptionDetails: ISubscriptionResponse;
  errors: IStripePaymentError;
  isLoading: boolean;
  actorProfile: any;
  productService: IProductSubscriptionService;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    subscriptionDetails: state.stripeModel.subscriptionDetails,
    errors: state.stripeModel.errors,
    isLoading: state.stripeModel.isLoading,
    actorProfile: state.appModel.actorProfile,
    productService: state.servicesModel.productService,
  };
}

interface DispatchProps {
  getDiscount: (code: string) => Promise<IDiscount>;
  createStripeSubscription: (dto: IStripeCharge) => void;
  setErrors: (errors: IStripePaymentError) => void;
  setIsLoading: (loading: boolean) => any;
  getProductService: (serviceId: number) => void;
  getDiscountByClientId: (
    discountWithClientId: IDiscountWithClientId
  ) => Promise<IDiscount>;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    getDiscount: dispatch.promoCodeModel.getDiscount,
    getDiscountByClientId: dispatch.promoCodeModel.getDiscountByClientId,
    createStripeSubscription: dispatch.stripeModel.createStripeSubscription,
    setErrors: dispatch.stripeModel.setErrors,
    setIsLoading: dispatch.stripeModel.setIsLoading,
    getProductService: dispatch.servicesModel.getProductServices,
  };
}

const StyledPageContainer = styled(PageContainer)`
  ${(p) =>
    p.isCWBLabs &&
    css`
      background-color: #040f1c;

      @media all and (max-width: 520px) {
        padding: ${(p) => p.theme.spacing(4, 2)};
        border: none;

        & > div:first-of-type {
          display: none;
        }
      }
    `}
`;

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  * {
    color: ${(p) => p.theme.color};
    font-size: ${(p) => p.theme["s-font-size"]};
  }

  width: 880px;
  padding: 40px 60px;

  @media all and (max-width: 768px) {
    width: 690px;
    margin: 0;
    padding: 24px 24px 40px;
  }

  @media all and (max-width: 520px) {
    width: 100%;
    margin: 0;
    padding: 24px 16px 40px;

    ${(p) =>
      p.isCWBLabs &&
      css`
        border-top: 6px solid #02b8f9;
        border-radius: 4px;
      `}
  }
`;

const RowColumnWrapper = styled.div`
  height: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media all and (max-width: 520px) {
    flex-direction: column-reverse;
  }
`;

const ColumnBilling = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 12px;
  min-width: 420px;
  background-color: #f2f2f7;
  border-radius: 4px;
  padding: 8px 40px 8px;
  margin-top: 24px;
  margin-right: 3%;
  height: fit-content;

  @media all and (max-width: 768px) {
    min-width: 360px;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media all and (max-width: 520px) {
    min-width: unset;
    margin: 16px 0;
    padding: 12px 16px;
  }
`;

const ColumnMembership = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  width: 100%;
  justify-content: start;
  // align-items: center;
  background-color: ${(p) => p.theme.white};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  margin-top: 24px;
  height: 100%;
`;

const MembershipContainer = styled.div`
  border: ${(props) => props.theme.dropDown["border"]} !important;
  border-radius: ${(props) => props.theme.dropDown["border-radius"]};
`;

const StyledTitle = styled.div`
  text-align: center;
  color: ${(p) => p.theme.darkGrey} !important;
  font-size: ${(p) => p.theme["xxl-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
`;

const AdditionalStyledLink = styled.a`
  margin: 0 auto;
  display: table;
  position: relative;
  top: -12px;
  font-size: 10px;
`

const StyledHomeKitLink = styled.a`
  margin: 0 auto;
  display: table;
  text-decoration: underline !important;
  font-size: 10px;
`

const SectionLargeTitle = styled.div`
  color: ${(p) => p.theme.darkGrey} !important;
  font-size: ${(p) => p.theme["xl-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
`;

const SectionLargeTitleService = styled.div`
  color: ${(p) => p.theme.darkGrey} !important;
  font-size: ${(p) => p.theme["xl-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  text-align: center;
`;

const BillingWrapper = styled.div``;

const MembershipWrapper = styled.div`
  padding: 8px 40px 18px;

  @media all and (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media all and (max-width: 520px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const DueTodayWrapper = styled.div`
  padding: 8px 40px 18px;
  background-color: #f2f2f7;

  @media all and (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media all and (max-width: 520px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const InfoText = styled.div`
  margin-top: 24px;
  font-size: 9px !important;
`;

const PriceTextWrapper = styled.div`
  width: 100%;
  padding: 0;
  margin: 7px 0 0 0;
`;

const PriceTextBox = styled.div`
  overflow: auto;
  margin: 0;
`;

const PriceTextLeft = styled.div`
  float: left;
`;

const PriceTextRight = styled.div`
  float: right;
`;

const PriceTextClear = styled.div`
  clear: both;
`;

const WrapperDiscount = styled.div`
  margin-top: 10px;
  display: flex;
`;

const InputDiscount = styled.input`
  padding: 6px;
  font-size: 15px;
  border: 1px solid grey;
  float: left;
  flex: 1;
  border: 1px solid #d3dde9;
  border-right: none;
  border-radius: 4px 0 0 4px;
`;

const ButtonDiscount = styled.button`
  float: left;
  width: auto;
  padding: 6px 12px;
  background: white;
  color: #2196f3 !important;
  font-size: 15px;
  /* border: 1px solid grey; */
  /* border-left: none; */
  cursor: pointer;
  border: 1px solid #2196f3 !important;
  border-radius: 0 4px 4px 0;
  border-left: none;
`;

const Divider = styled.div`
  margin: 4px 0 4px 0;
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.dropDown["border-color"]};
`;

const DueTodayDivider = styled.div`
  margin: 8px 0 0 0;
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.dropDown["border-color"]};
`;

const RadioWrapper = styled.button`
  background-color: ${(p) => p.theme.white};
  text-align: left;
  &:focus {
    outline: none;
  }
  & + & {
    margin-top: 8px;
  }

  display: inline-block;
  position: relative;
  line-height: ${(props) => props.theme.formField["font-size"]};

  .fake-radio-button {
    fill: white;
  }

  input:disabled:checked + div svg .styled-radio-button-center {
    fill: ${(props) => props.theme.formField.disabled["color"]};
  }

  .styled-radio-button-outline {
    stroke: ${(props) => props.theme.formField.outline};
  }

  input:not(:disabled):hover + div svg .styled-radio-button-outline,
  input:not(:disabled):checked + div svg .styled-radio-button-outline {
    stroke: ${(props) => props.theme.lightBlue};
  }

  input:checked + div svg .styled-radio-button-center {
    fill: ${(props) => props.theme.lightBlue};
  }

  input:focus + div svg {
    box-shadow: 0 0 6px rgba(25, 99, 246, 0.6);
    border-radius: 100%;
  }

  input:disabled + div,
  input:disabled,
  input:disabled ~ label {
    cursor: not-allowed;
  }

  input:disabled + div svg .styled-radio-button-outline {
    stroke: ${(props) => props.theme.formField.disabled["color"]};
  }

  border: 1px solid #d3dde9 !important;
  border-radius: 4px;
  padding: 8px 8px 0;
  width: 100%;
`;

const RadioInput = styled.input`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 15px;
  height: 15px;
  z-index: 100;
  padding: 0px;
  margin: 0px;
  vertical-align: middle;
`;

const RadioLabelTitle = styled.label`
  color: ${(p) => p.theme.formField["color"]};
  font-family: ${(props) => props.theme["font-family"]};
  font-size: ${(props) => props.theme.formField["font-size"]};
  font-weight: ${(props) => props.theme.formField["font-weight"]};
  margin-left: 32px;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
`;

const RadioLabel = styled.label`
  color: ${(p) => p.theme.formField["color"]};
  font-family: ${(props) => props.theme["font-family"]};
  font-size: 12px;
  font-weight: 100;
  margin-left: 32px;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
`;

const RadioStyledInput = () => (
  <StyledInputWrapper
    dangerouslySetInnerHTML={{
      __html: `<svg width="15" height="15" viewBox="0 0 15 15" style="overflow: visible">
    <g stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-69.000000, -293.000000)">
        <g transform="translate(69.000000, 268.000000)">
          <g transform="translate(0.000000, 25.000000)">
            <circle fill="#FFFFFF" cx="7.5" cy="7.5" r="7.5"></circle>
            <path class="styled-radio-button-outline" d="M7.5,15 C11.6421356,15 15,11.6421356 15,
              7.5 C15,3.35786438 11.6421356,0 7.5,0 C3.35786438,0 0,3.35786438 0,7.5 C0,
              11.6421356 3.35786438,15 7.5,15 Z M7.5,14 C11.0898509,14 14,11.0898509 14,
              7.5 C14,3.91014913 11.0898509,1 7.5,1 C3.91014913,1 1,3.91014913 1,7.5 C1,
              11.0898509 3.91014913,14 7.5,14 Z" fill="#AFAFAF"></path>
            <circle fill="white" cx="7.5" cy="7.5" r="3.5" class="styled-radio-button-center"></circle>
          </g>
        </g>
      </g>
    </g>
  </svg>`,
    }}
  />
);

const StyledInputWrapper = styled.div`
  height: 15px;
  width: 15px;
  position: absolute;
  z-index: 1;
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 4px 5px;
`;

const StyledCWBLabsFooter = styled(CWBLabsFooter)`
  margin-top: ${(p) => p.theme.spacing(3)};
`;

const StyledLabel = styled(Typography)`
  margin-left: ${(p) => p.theme.spacing(1)};
  font-size: ${(p) => p.theme["xxs-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  color: ${(p) => p.theme.color} !important;
  line-height: 16px;
`;

const MailingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  &.margin-bottom-none {
    margin-bottom: 0;
  }
`;

const MailingSection = styled.div`
  width: 45%;
  margin: 0 0 0 0;

  &.margin-bottom-none {
    margin-bottom: 0;
  }
`;

const PriceBox = styled.div`
  background: rgba(46, 210, 255, 0.05);
  /* Brand/Med-Blue */
  border: 1px solid #00aaff;
  box-sizing: border-box;
  border-radius: 4px;
  height: 157px;
  width: 200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0px;
`;

const PriceTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;

const PriceTotalSub = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
`;

const PriceTotal = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */
  text-align: center;
  margin: 8px 0px 10px;
`;

const PriceBill = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  text-align: center;
`;

const PriceTip = styled.div`
  font-size: 9px;
  line-height: 18px;
  /* identical to box height, or 225% */

  text-align: center;
  color: #53627c;
  margin-top: 4px;
`;

const FloatingDiv = styled.div`
  position:absolute;
`

const DiscountStamp = styled.span`
  color: #555;
  font-size: 3rem;
  font-weight: 700;
  border: 0.25rem solid #555;
  display: inline-block;
  padding: 0 10px 0 10px;
  text-transform: uppercase;
  border-radius: 10px;
  font-family: "Courier";
  -webkit-mask-image: url(${grungeEffect});
  -webkit-mask-size: 944px 604px;

  color: #d23;
  border: 6px solid #d23;
  transform: rotate(20deg);
  -webkit-mask-position: 2rem 3rem;
  font-size: 16px;
  position: relative;
  top: -166px;
  left: 192px;
  margin: -16px;
`;

export default withI18n()(
  injectStripe(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(Membership))
  )
);
