import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withI18n } from 'react-i18next';
import styled from 'styled-components'
import { Button, ButtonGroup, Typography } from '../Common';
import { SmallText, StyledInput } from '../Common/StyledComponents';
// @ts-ignore
import { CWBModal } from 'cwb-react';
import { emailValidation } from '../../helpers/validations';

interface IProps extends StateProps, DispatchProps, RouteComponentProps {
  t: any;
  history: any;
  location: any;
  onClose: () => void;
}

const EmailUpgradeModal: React.FC<IProps> = ({
  t,
  history,
  location,
  actorProfile,
  onClose: handleClose,
  setActorProfile,
  updateEmail
}) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const checkValidation = () => {
    const error = emailValidation(email, t);
    setError(error);
    return !error;
  };

  const handleClick = async () => {
    if (loading || !checkValidation()) return;

    setLoading(true);
    await updateEmail({ clientId: actorProfile.clientId, email });
    setActorProfile({ ...actorProfile, email });
    handleClose();
  };

  return (
    <StyledCWBModal
      isOpen={true}
      title={(
        <Typography
          key={0}
          align="left"
          component="span"
          variant="h2"
        >
          {t('Enter your email to upgrade')}
        </Typography>
      )}
      buttons={(
        <ButtonGroup>
          <Button onClick={() => handleClick()}>
            {t('Submit')}
          </Button>
          <Button
            variant="secondary"
            onClick={handleClose}
          >
            {t('Cancel')}
          </Button>
        </ButtonGroup>
      )}
      hideModal={() => handleClose()}
    >
      <Typography
        color="medGrey"
        component="span"
      >
        {t(`In order to upgrade your account online, an email address is required. Don't have an email address? No problem - just give us a call and we can get you upgraded.`)}
      </Typography>
      &nbsp;
      <StyledA href="https://home.castingworkbook.com/contacts/">
        {t('Contact us')}
      </StyledA>
      <StyledDiv>
        <Typography
          color="darkGrey"
          component="span"
          variant="bodyBold"
        >
          {t('Email Address')}
        </Typography>
        <StyledInput
          className={error ? 'invalid' : ''}
          type="text"
          placeholder={t('Enter email address')}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <SmallText className="error">
          <span>{error}</span>
        </SmallText>
      </StyledDiv>
    </StyledCWBModal>
  );
};

const StyledCWBModal = styled(CWBModal)`
  & .modal-header { justify-content: space-between; }
  & .modal-body { text-align: left; }
  & .modal-footer .modal-buttons {
    justify-content: flex-start;
    margin: ${p => p.theme.spacing(0, 0, 1)};
  }
`;

const StyledA = styled.a`
  color: ${p => p.theme.palette.misc.darkBlue};
  font-size: 14px;
`;

const StyledDiv = styled.div`
  margin: ${p => p.theme.spacing(2, 0, 1)};
  & input { font-size: 13px; }
`;

interface StateProps {
  actorProfile: any;
}

const mapStateToProps = (state: any) => ({
  actorProfile: state.appModel.actorProfile
});

interface DispatchProps {
  setActorProfile: (actorProfile: any) => any;
  updateEmail: (payload: {
    clientId: number;
    email: string;
  }) => void;
}

const mapDispatchToProps = (dispatch: any) => ({
  setActorProfile: dispatch.appModel.setActorProfile,
  updateEmail: dispatch.actorModel.updateEmail
});

export default withI18n()(connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmailUpgradeModal)));
