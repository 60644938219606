import { createModel } from '@rematch/core';
import * as PromoCodeAPI from '../shared/api/PromoCode.api';
import { IAutoDiscount, IDiscount, IDiscountWithClientId} from '../shared/api/dtos/IPromoCodeDto';
// @ts-ignore
import * as Sentry from "@sentry/react";

const promoCodeModel = createModel({
  state: {},
  reducers: {},
  effects: (dispatch: any) => ({
    getDiscount: async (code: string): Promise<IDiscount> => {
      try {
        const response = await PromoCodeAPI.getDiscount(code);
        return response.data;
      } catch (e) {
        Sentry.captureException(e);
        return { isValid: false } as IDiscount;
      }
    },
    getDiscountByClientId:async (dto:IDiscountWithClientId): Promise<IDiscount> => {
      try {
        const response = await PromoCodeAPI.getDiscountByClientId(dto);
        return response.data;
      } catch (e) {
        Sentry.captureException(e);
        return { isValid: false } as IDiscount;
      }
    },
    tryFindPromoCodes: async (clientId:number) : Promise<IAutoDiscount> => {
      try {
        const response = await PromoCodeAPI.getAutoDiscountByClientId({clientId: clientId});
        return response.data;
      } catch (e) {
        Sentry.captureException(e);
        return { isValid: false } as IAutoDiscount;
      }
    }
  })
});

export default promoCodeModel;
