import React from "react";
import { ThemeProvider } from "styled-components";

const spacingHelper = (...args: (string | number)[]): string => {
  const SCALING = 8;

  const parseNum = (num: number): string => `${num * SCALING}px`;

  const getSpacing = (args: (string | number)[]) => {
    const newArgs: string[] = args.map((arg: string | number): string => {
      return typeof arg === 'number' ? parseNum(arg) : arg;
    });

    return newArgs.reduce((acc: string, cur: string): string => {
      return acc.concat(` ${cur}`);
    }).trim();
  };

  if (args.length > 4) {
    throw new Error('spacing received too many arguments');
  } else if (args.length < 1) {
    throw new Error('spacing received not enough arguments');
  }

  return getSpacing(args);
};

const palette = {
  brand: {
    darkBlue: '#0A1F44',
    blue: '#00AAFF',
    lightBlue: '#2ED2FF',
    red: '#FF1870'
  },
  grey: {
    '0': '#FCFCFD',
    '1': '#F8F9FB',
    '2': '#F1F2F4',
    '3': '#C9CED6',
    '4': '#A6AEBC',
    '5': '#8A94A6',
    '6': '#53627C',
    '7': '#364866',
    '8': '#0A1F44'
  },
  system: {
    success: '#0BB07B',
    successDark: '#1F8651',
    error: '#E5084A',
    errorDark: '#C7053F',
    warning: '#FCB704',
    warningDark: '#B36204'
  },
  button: {
    normal: '#0F9BF0',
    hover: '#0892E5',
    pressed: '#0584D7',
    disabled: '#C9CED6'
  },
  common: {
    black: '#000000',
    white: '#FFFFFF',
    grey: '#D1D5DD',
    transparent: 'transparent'
  },
  misc: {
    boxShadow: '#E0E0E0',
    darkBlue: '#0F9BF0',
    disabled: '#D1D5DD'
  }
};

const CWBRTheme = {
  palette: { ...palette },
  typography: {
    color: {
      white: palette.common.white,
      black: palette.grey[8],
      darkGrey: palette.grey[7],
      medGrey: palette.grey[6],
      lightGrey: palette.grey[5],
      success: palette.system.successDark,
      error: palette.system.errorDark,
      link: palette.misc.darkBlue,
      disabled: palette.grey[3]
    },
    fontFamily: `'Open Sans', sans-serif`,
    fontSize: {
      h1: '24px',
      h2: '20px',
      h3: '18px',
      h4: '16px',
      h5: '16px',
      body: '14px',
      bodyBold: '14px',
      caption: '12px',
      captionBold: '12px',
      button: '14px',
      link: '14px'
    },
    fontWeight: {
      normal: 'normal',
      bold: 'bold',
      h1: 'bold',
      h2: 'bold',
      h3: 'bold',
      h4: 'normal',
      h5: 'bold',
      body: 'normal',
      bodyBold: 'bold',
      caption: 'normal',
      captionBold: 'bold'
    },
    lineHeight: {
      h1: '36px',
      h2: '30px',
      h3: '24px',
      h4: '22px',
      h5: '22px',
      body: '18px',
      bodyBold: '18px',
      caption: '18px',
      captionBold: '18px',
      button: '20px',
      link: '19px'
    }
  },
  spacing: spacingHelper,
  // CSS attributes
  "font-family": "'Open Sans', sans-serif",

  //Font Sizes
  "xxl-font-size": "20px",
  "xl-font-size": "18px",
  "lg-font-size": "16px",
  "font-size": "14px",
  "s-font-size": "13px",
  "xs-font-size": "12px",
  "xxs-font-size": "11px",
  "placeholder-font": "10px",
  "sm-cb-font": "8px",

  //Font-weights
  "font-weight": "bold",
  "font-weight-800": "800",
  "font-weight-600": "600",

  //colors
  black: "#000000",
  color: "#495A6E",
  blue: "#0F9BF0",
  green: "#0BB07B",
  darkBlue: "#00AAFF",
  lightBlue: "#2ED2FF",
  btnBlue: "#03C0FA",
  btnDarkBlue: "#00A2E0",
  placeHolderColor: "#8A94A6",
  white: "#FFFFFF",
  red: "#FEF6F6",
  errorColor: "#AF5756",
  darkGrey: "#122640",
  darkerGrey: "#364866",
  lightestGrey: "#FCFCFD",
  lighterGrey: "#F1F2F4",
  lightGrey: "#DEE3EB",
  backgroundColor: "#EDF0F6",
  "disabled-grey": "#C9CED6",
  "red-border": "1px solid #FF1870",
  "error-border": "1px solid #AF5756",

  dropDown: {
    "font-size": "12px",
    "font-weight": "600",
    border: "1px solid #D3DDE9",
    "border-radius": "4px",
    "border-color": "#D3DDE9"
  },

  // Sub-themes
  formField: {
    color: "#0A1F44",
    "font-size": "13px",
    "font-weight": 600,

    outline: "#C9CED6",

    disabled: {
      color: "#E1E4E8"
    }
  }
};

export const withTheme = (children: any) => (
  <ThemeProvider theme={CWBRTheme}>{children}</ThemeProvider>
);

export default CWBRTheme;
