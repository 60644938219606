import React from "react";
import qs from "query-string";
import CWBLabsActorVerifyPhoneStep from "components/CWBLabs/ActorVerifyPhoneStep";
import ActorVerifyPhoneStep from "./ActorVerifyPhoneStep";

interface Props {
  t?: any;
  history?: any;
  location?: any;
}
const ActorVerifyPhoneStepHOC: React.FC<Props> = ({ location, history }) => {
  return qs.parse(location.search).cwblabs ? (
    <CWBLabsActorVerifyPhoneStep history={history} location={location} />
  ) : (
    <ActorVerifyPhoneStep history={history} location={location} />
  );
};

export default ActorVerifyPhoneStepHOC;
