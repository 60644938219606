import React, { MouseEvent } from 'react';
import styled from 'styled-components';

type Props = {
  id?: string;
  className?: string;
  disabled?: boolean;
  src: string;
  type?: 'submit' | 'button' | 'reset';
  onClick?: (event: MouseEvent) => void;
};

const IconButton: React.FC<Props> = ({
  children,
  id,
  className,
  disabled = false,
  src,
  type = 'submit',
  onClick
}) => {
  const props = {
    id,
    className,
    disabled,
    type,
    onClick
  };

  return (
    <StyledButton {...props}>
      <StyledImg src={src} />
    </StyledButton>
  );
};

const StyledButton = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: ${p => p.theme.palette.common.transparent};
  border: none;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;

export default IconButton;
