import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, RouteProps } from "react-router-dom";
import { iRootState } from "../store";
import styled, { css } from "styled-components";
import qs from "query-string";
import CWBLabsHeader from "./CWBLabs/components/Header";
import HeaderComponent from "./HeaderComponent";
import { setLanguage, bridgeAuthentication, seamlessAuthentication } from "../service/UserService";
// @ts-ignore
import { getCurUser, CWBLoadingCrest } from "cwb-react";
import { setWebSessionKey } from "../helpers";
import * as Sentry from "@sentry/react";
import { fallbackLoginRedirect } from "../helpers/redirect"

interface Props extends RouteProps, StateProps, DispatchProps {}

const BridgeAuthRoute: React.FC<Props> = ({
  setAuthenticatedUser,
  authenticatedUser,
  component: Component,
  ...rest
}) => {

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const urlRemoveQueryString = () => {
    let url =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    window.history.pushState({ path: url }, "", url);
  };

  useEffect(() => {
    (async function attemptAuth() {
      try {
        if (!authenticatedUser || authenticatedUser.id.userTypeId !== 8) {
          const o = qs.parse(rest.location.search);

          if(o && o.k) {
            setWebSessionKey(o.k as string);
            urlRemoveQueryString();
          }

          if (!(await bridgeAuthentication(o.k as string)))
            await seamlessAuthentication();

          const user = getCurUser();
          if (!user || user.id.userTypeId !== 8) fallbackLoginRedirect();

          await setLanguage();

          setAuthenticatedUser(user);
          setIsLoading(false);
        } else {
          setAuthenticatedUser(getCurUser());
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        setTimeout(() => fallbackLoginRedirect(), 1000);
      }
    })();
  }, []);

  const params = qs.parse(rest.location.search);
  const showHeader = !params.hs;
  const isCWBLabs = params.cwblabs;

  return (
    <>
      {showHeader && (isCWBLabs ? (<CWBLabsHeader />) : (<HeaderComponent />))}
      <Wrapper showHeader={showHeader}>
        {isLoading && !authenticatedUser ? (
          <CWBLoadingCrest customLogoSrc="/images/icon-cwb-logo-grey.svg" />
        ) : (
          <Route component={Component} {...rest} />
        )}
      </Wrapper>
    </>
  );
};

const Wrapper: any = styled.div`
  display: flex;
  padding-top: 60px;

  ${(props: any) =>
    !props.showHeader &&
    css`
      padding-top: 0;
    `}

  @media all and (max-width: 520px) {
    flex-direction: column;
  }

  @media all and (max-width: 768px) {
    padding-top: 0;
  }
`;

interface StateProps {
  authenticatedUser: any;
}

const mapStateToProps = (state: iRootState) => ({
  authenticatedUser: state.appModel.authenticatedUser,
});

interface DispatchProps {
  setAuthenticatedUser: (user: any) => any;
}

const mapDispatchToProps = (dispatch: any) => ({
  setAuthenticatedUser: dispatch.appModel.setAuthenticatedUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(BridgeAuthRoute);
