import React, {useEffect, useState }from 'react';
import qs from 'query-string';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  TrialFooter,
  Modal,
  PageContainer,
  Typography
} from '../Common';

interface Props extends StateProps, RouteComponentProps {
  t: any;
}

const OfferNotValid: React.FC<Props> = ({
  t,
  location,
  serviceCodeError
}) => {

  const [errorText, setErrorText] = useState("")
  useEffect(() => {
    // no service code
    if(serviceCodeError === 1){
      setErrorText("The offer code you provided is not valid")
    }
    // expired
    if(serviceCodeError === 2){
      setErrorText("This offer is expired");
    }
    // restricted to country
    if(serviceCodeError === 3){
      setErrorText("This offer can not be applied in your residence");
    }

  },[serviceCodeError])

  const redirectToHome = () => {
    const baseUrl = process.env.REACT_APP_CWB_Site;
/*     const params = qs.stringify({
      ...(qs.parse(location.search))
    }); */
    window.location.replace(`${baseUrl}`);
  }

  return <PageContainer>
      <StyledModal>
        <Typography
          align="center"
          component="h1"
          variant="h2"
        >
         {t("This offer is not available")}
        </Typography>
        <StyledTypography
          align="center"
          color="darkGrey"
        >
          {errorText}
        </StyledTypography>
        <StyledButton onClick={redirectToHome}>
          {t('Go to Homepage')}
        </StyledButton>
        <TrialFooter />
      </StyledModal>
    </PageContainer>
    ;
};

const StyledModal = styled(Modal)`
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${p => p.theme.spacing(11, 18, 3)};
`;


const StyledTypography = styled(Typography)`
  margin: ${p => p.theme.spacing(3,0)};
  font-size: 16px;
  line-height: 22px;

  & > span {
    color: ${p => p.theme.palette.misc.darkBlue};
    text-decoration: underline;
  }
`;

const StyledButton = styled(Button)`
margin-bottom:${p => p.theme.spacing(3)}
`;

interface StateProps {
  serviceCodeError:number;
}

const mapStateToProps = (state: any) => ({
  serviceCodeError:state.actorModel.serviceCodeError
});

export default withI18n()(withRouter(connect(
  mapStateToProps,
  null
)(OfferNotValid)));