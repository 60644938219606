import React, {useCallback } from "react";
import qs from "query-string";
import { withI18n } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";
import PageContainer from "../../Common/PageContainer";
import { iRootState } from "../../../store";
import Card from '../../CWBLabs/components/Card';
import CheckmarkCircle from 'images/checkmark_circle.svg';
import ProductOne from 'images/product-one.svg';
import ProductTwo from 'images/product-two.svg';
import ProductThree from 'images/product-three.svg';
import { Button, Typography } from 'components/Common';


interface IProps extends StateProps {
  t: any;
  history: any;
  location: any;
}

const ActorUpgradeProductComplete: React.FC<IProps> = ({
  history,
  location,
  t,
  sessionData,
  authenticatedUser
}) => {

  const redirect = useCallback(() => {
    const queryString = qs.stringify({
      tk: sessionData.WebSessionKey,
      cid: authenticatedUser.id.userId
    });
    window.top.location.href = 
      `${process.env.REACT_APP_CWB_Site}/actorsworkbook/default.asp?${queryString}`
    ;
  }, [authenticatedUser, sessionData.WebSessionKey]);


  return  <PageContainer>
       <StyledDiv>
            <StyledCard>
              <Icon src={CheckmarkCircle} />
              <TitleTypography align="center" component="h1" variant="h2">
                {t("That’s it, you’re done!")}
              </TitleTypography>
              <StyledTypography align="center">
                {t("Thank you for signing up with Casting Workbook.")}
              </StyledTypography>
              <StyledTypography align="center">
                {t("Please enjoy your pro membership.")}
              </StyledTypography>
              <Wrapper>
                <Feature>
                  <IconBackground color='#E14C8A'>
                      <ProductIcon src={ProductOne} />
                  </IconBackground>
                  <Detail>
                    <DetailTitle>{t("Pro Membership")}</DetailTitle>
                    <DetailContent>{t("Enjoy access to our premium features.")}</DetailContent>
                  </Detail>
                </Feature>
                {/* <Feature>
                  <IconBackground color='#EDC253'>
                      <ProductIcon src={ProductTwo} />
                  </IconBackground>
                  <Detail>
                    <DetailTitle>{t(`"Better Auditions & Self-Tapes" Course`)}</DetailTitle>
                    <DetailContent>{t("with Casting Director Sharon Lieblein. We’ll email you with the details soon.")}</DetailContent>
                  </Detail>
                </Feature> */}
                <Feature>
                  <IconBackground color='#3D93CD'>
                      <ProductIcon src={ProductThree} />
                  </IconBackground>
                  <Detail>
                    <DetailTitle>{t("Total Actor’s Homekit")}</DetailTitle>
                    <DetailContent>{t("We’ll send you an email with the order info within the next 2 business days.")}</DetailContent>
                  </Detail>
                </Feature>
              </Wrapper>
              <StyledButton onClick={redirect}>
                {t("Continue to Casting Workbook")}
              </StyledButton>
            </StyledCard>
          </StyledDiv>
    </PageContainer>
};

const StyledDiv = styled.div`
  width: 480px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: ${(p) => p.theme.spacing(5, 'auto', 0)};

  @media all and (max-width: 520px) {
    width: unset;
    margin-top: ${(p) => p.theme.spacing(3)};
    padding: ${(p) => p.theme.spacing(0, 2)};
  }
`;

const StyledCard = styled(Card)`
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing(5)};
  padding-top: ${(p) => p.theme.spacing(5)};
  padding-left: ${(p) => p.theme.spacing(5)};
  padding-right: ${(p) => p.theme.spacing(5)};
  border-color:#0BB07B;

  @media all and (max-width: 520px) {
    padding-left: ${(p) => p.theme.spacing(3)};
    padding-right: ${(p) => p.theme.spacing(3)};
  }
`;

const Icon = styled.img`
  margin-bottom: ${(p) => p.theme.spacing(1)};
`;


const StyledTypography = styled(Typography)`
  line-height: 18px;
  color: #122640;
  letter-spacing: 0.374px;
`;

const TitleTypography = styled(Typography)`
  margin: ${(p) => p.theme.spacing(0, 0, 1)};
  color: #122640;
`;

const StyledButton = styled(Button)`
  background-color: #0F9BF0;
  width:100%;

  @media all and (max-width: 520px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
margin: ${(p) => p.theme.spacing(4, 0, 0)};
display:flex;
flex-direction:column;
width:100%;
`;

const Feature = styled.div`
width:100%;
display:flex;
margin: ${(p) => p.theme.spacing(0, 0, 5)};
`;

const IconBackground = styled.div`
width:50px;
height:50px;
border-radius:50%;
display:flex;
justify-content:center;
align-items:center;
background-color:${(p) => p.color};
margin-right: ${(p) => p.theme.spacing(2)};
`;

const ProductIcon = styled.img`
width:30px;
height:30px;
`;

const Detail = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
width:334px;
`;

const DetailTitle = styled(Typography)`
font-weight: bold;
font-size: 13px;
line-height: 18px;
color: #00AAFF;
`;

const DetailContent = styled(Typography)`
font-size: 13px;
line-height: 18px;
font-weight:400;
`;

interface StateProps {
  authenticatedUser: any;
  sessionData: any;
}

const mapStateToProps = (state: iRootState) => ({
  authenticatedUser: state.appModel.authenticatedUser,
  sessionData: state.appModel.sessionData
});

export default withI18n()(connect(
  mapStateToProps,
  null
)(ActorUpgradeProductComplete));