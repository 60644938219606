import React from 'react';
import { getI18n, withI18n } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Typography } from 'components/Common';
import Card from './components/Card';
import Header from './components/Header';
import Footer from './components/Footer';
import Page from './components/Page';
import ErrorCircle from 'images/error.svg';

type Props = {
  t: any;
} & RouteComponentProps;

const ActorPaymentDeclined: React.FC<Props> = ({ t, history, location }) => {
  const isLangSpanish = getI18n().language === "es";
  const support = isLangSpanish
    ? process.env.REACT_APP_CWB_Support_es
    : process.env.REACT_APP_CWB_Support;

  return (
    <>
      <Header />
      <Page>
        <StyledDiv>
          <StyledCard variant="error">
            <Icon src={ErrorCircle} />
            <Typography align="center" component="h1" variant="h3">
              {t('Unable to upgrade your account')}
            </Typography>
            <HorizontalSeparator />
            <StyledTypography align="center">
              {t('Please try again. If the problem persists, contact our')}
              &nbsp;
              <Link
                href={support}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('support team')}
              </Link>
              .
            </StyledTypography>
            <StyledButton
              onClick={() => history.push({
                pathname: '/actor/upgrade-to-pro',
                search: location.search
              })}
            >
              {t('Try Again')}
            </StyledButton>
          </StyledCard>
          <Footer />
        </StyledDiv>
      </Page>
    </>
  );
};

const StyledDiv = styled.div`
  width: 450px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: ${(p) => p.theme.spacing(5, 'auto', 0)};

  @media all and (max-width: 520px) {
    width: unset;
    margin-top: ${(p) => p.theme.spacing(3)};
    padding: ${(p) => p.theme.spacing(0, 2)};
  }
`;

const StyledCard = styled(Card)`
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing(3)};
  padding-top: ${(p) => p.theme.spacing(2.5)};
  padding-left: ${(p) => p.theme.spacing(7)};
  padding-right: ${(p) => p.theme.spacing(7)};

  @media all and (max-width: 520px) {
    padding-left: ${(p) => p.theme.spacing(3)};
    padding-right: ${(p) => p.theme.spacing(3)};
  }
`;

const Icon = styled.img`
  margin-bottom: ${(p) => p.theme.spacing(1)};
`;

const HorizontalSeparator = styled.div`
  width: 72px;
  height: 3px;
  margin: ${(p) => p.theme.spacing(1, 0, 2)};
  background: ${(p) => p.theme.palette.system.error};
`;

const StyledTypography = styled(Typography)`
  margin: ${(p) => p.theme.spacing(0, 0, 2)};
  line-height: 18px;
`;

const Link = styled.a`
  && {
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledButton = styled(Button)`
  width: 80%;
  margin-top: ${(p) => p.theme.spacing(2)};
  padding: ${(p) => p.theme.spacing(1, 2)};
  background-color: ${(p) => p.theme.palette.system.error};

  &:hover {
    background-color: ${(p) => p.theme.palette.system.errorDark};
  }

  @media all and (max-width: 520px) {
    width: 100%;
  }
`;

export default withI18n()(withRouter(ActorPaymentDeclined));
