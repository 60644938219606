import React from 'react';
import qs from 'query-string';
import { withI18n } from 'react-i18next';
import { Link as ReactRouterLink, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Typography } from 'components/Common';
import Card from './components/Card';
import Header from './components/Header';
import Page from './components/Page';
import MovieIcon from 'images/movie.svg';
import PlayCircleOutlinedIcon from 'images/play_circle_outlined.svg';
import { setBrowserLang } from 'helpers';

type Props = {
  t: any;
} & RouteComponentProps;

const UserTypeSelect: React.FC<Props> = ({ t, history, location }) => {
  const params = qs.stringify({
    cwblabs: true,
    redirect: 'cwblabs',
    ...(qs.parse(location.search))
  });

  setBrowserLang('es');

  return (
    <>
      <Header />
      <Page>
        <StyledDiv>
          <Typography
            align="center"
            color="white"
            component="h1"
            variant="h1"
          >
            {t('Choose your account type')}
          </Typography>
          <Subheading
            align="center"
            color="white"
            variant="h2"
          >
            {t(
              'Before we get started we will need to know if you are an actor or other type of performer.'
            )}
          </Subheading>
          <CardsContainer>
            <Card>
              <Icon src={MovieIcon} />
              <Typography variant="h3">
                {t('Professional Actor')}
              </Typography>
              <UserTypeDescription align="center" color="medGrey">
                {t(
                  'Choose this option if you are a professional or aspiring voice, theatre, on-camera or commercial actor.'
                )}
              </UserTypeDescription>
              <ActorButton
                onClick={() => history.push({
                  pathname: '/register/actor',
                  search: `?${params}`
                })}
              >
                {t('I am an Actor')}
              </ActorButton>
            </Card>
            <Card variant="secondary">
              <Icon src={PlayCircleOutlinedIcon} />
              <Typography variant="h3">
                {t('Performer')}
              </Typography>
              <UserTypeDescription align="center" color="medGrey">
                {t(
                  'Choose this option if you do not act but are another type of performer such as a dancer, singer, model, etc.'
                )}
              </UserTypeDescription>
              <PerformerButton
                onClick={() => history.push({
                  pathname: '/register/performer',
                  search: `?${params}`
                })}
              >
                {t('I am a Performer')}
              </PerformerButton>
            </Card>
          </CardsContainer>
          <LoginContainer>
            <Typography color="white" component="span" variant="h5">
              {t('Already a member?')}
            </Typography>
            &nbsp;
            <Link href={`${process.env.REACT_APP_CWB_Site}/login/?${params}`}>
              {t('Login')}
            </Link>
          </LoginContainer>
          <Footnote align="center" color="white">
            {t(
              'Are you a Casting Director or Agent? Learn more about how Casting Workbook can support you.'
            )}
          </Footnote>
          <LinksContainer>
            <StyledReactRouterLink to={`/register/agent?${params}`}>
              {t('Agents & Managers')}
            </StyledReactRouterLink>
            <StyledReactRouterLink to={`/register/casting?${params}`}>
              {t('Casting Directors')}
            </StyledReactRouterLink>
          </LinksContainer>
        </StyledDiv>
      </Page>
    </>
  );
};

const StyledDiv = styled.div`
  width: 696px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: ${(p) => p.theme.spacing(5, 'auto', 0)};

  @media all and (max-width: 520px) {
    width: unset;
    margin-top: ${(p) => p.theme.spacing(3)};
    padding: ${(p) => p.theme.spacing(0, 2)};
  }
`;

const Subheading = styled(Typography)`
  margin: ${(p) => p.theme.spacing(2, 0, 5)};
  font-weight: normal;

  @media all and (max-width: 520px) {
    margin: ${(p) => p.theme.spacing(1, 0, 4)};
  }
`;

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  @media all and (max-width: 520px) {
    flex-direction: column;
    align-items: center;

    & > div + div {
      margin-top: ${(p) => p.theme.spacing(2)};
    }
  }
`;

const Icon = styled.img`
  margin-bottom: ${(p) => p.theme.spacing(1)};
`;

const buttonStyles = `
  width: 100%;

  &:hover {
    filter: brightness(0.95);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

const ActorButton = styled(Button)`
  ${buttonStyles}
  background-color: #02B8F9;

  &:hover, &:focus, &:active {
    background-color: #02B8F9;
  }
`;

const PerformerButton = styled(Button)`
  ${buttonStyles}
  background-color: #FF1870;

  &:hover, &:focus, &:active {
    background-color: #FF1870;
  }
`;

const UserTypeDescription = styled(Typography)`
  margin: ${(p) => p.theme.spacing(1, 0, 3)};
  line-height: 19px;
`;

const LoginContainer = styled.div`
  margin: ${(p) => p.theme.spacing(5, 0, 6)};
`;

const linkStyles = `
  && {
    color: #02B8F9;
    font-weight: bold;

    &:hover {
      color: #02B8F9;
      filter: brightness(1.15);
    }
  }
`;

const Link = styled.a`
  ${linkStyles}
`;

const StyledReactRouterLink = styled(ReactRouterLink)`
  ${linkStyles}
`;

const Footnote = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(2)};
  line-height: 19px;
`;

const LinksContainer = styled.div`
  & > a + a {
    margin-left: ${(p) => p.theme.spacing(6)};
  }

  @media all and (max-width: 520px) {
    margin-bottom: ${(p) => p.theme.spacing(6)};
  }
`;

export default withI18n()(UserTypeSelect);
