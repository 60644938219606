import * as http from "../http";
// @ts-ignore
import { getAuthConfig } from "cwb-react";
import {
  ICountryDto,
  ICountryCitiesDto,
  IIpDetailsDto,
} from "./dtos/IReferencesDto";

const api = `${process.env.REACT_APP_REG_Api}/References`;

export const getCountries = () => {
  return http.get<ICountryDto[]>(`${api}/countries`);
};

export const getCountriesWithCities = () => {
  return http.get<ICountryCitiesDto[]>(`${api}/CitiesWithCountry`);
};

export const getSupportedCitiesCountries = () => {
  return http.get<ICountryCitiesDto[]>(`${api}/supported-cities-countries`);
};

export const getRequesterCountryCode = (forcedFallback?: string | null) => {
  return http.get<IIpDetailsDto>(
    `${api}/country/code?forcedFallback=${forcedFallback}`
  );
};

export const refresh = () => {
  return http.get(`${api}/refresh`, {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      CWB: "1",
      ...getAuthConfig().headers,
    },
  });
};
