import React, { ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import qs from 'query-string';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Skeleton, RegisterTextInput, Typography } from 'components/Common';
import { Spinner } from 'components/Common/StyledComponents';
import { emailRegex, passwordRegex } from 'helpers/validations';
import { IActorCredentials, IActorError } from 'shared/api/dtos/IActorDto';
import SpinnerIcon from 'images/spinner.svg';
// @ts-ignore
import * as Sentry from "@sentry/react";
import TermUsePrivacy from "../../Common/TermUsePrivacy";

type Props = {
  t: any;
  className?: string;
  currencySymbol: string;
  yearlyPerMonthCharge: number;
} & StateProps & DispatchProps & RouteComponentProps;

type State = {
  errors: {
    email: string;
    password: string;
  };
  form: {
    email: string;
    password: string;
  };
  isLoading: boolean;
};

class ActorSignUpForm extends React.Component<Props, State> {
  state = {
    errors: {
      email: '',
      password: ''
    },
    form: {
      email: '',
      password: ''
    },
    isLoading: false
  } as State;

  getErrorMessage = (name: string, value: any) => {
    const { t } = this.props;
    switch (name) {
      case 'email':
        return !value
          ? t('Email is required.')
          : !emailRegex.test(value)
          ? t('Email is invalid.')
          : '';
      case 'password':
        return !value
          ? t('Password is required.')
          : !passwordRegex.test(value)
          ? t('Must contain at least 6 characters, 1 letter and 1 number')
          : '';
    }
  };

  validate = (eventTarget: HTMLInputElement) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [eventTarget.name]: this.getErrorMessage(
          eventTarget.name,
          eventTarget.value.trim()
        )
      }
    });
  };

  validateAll = () => {
    const fields = { ...this.state.form };
    const newErrors = { ...this.state.errors } as any;

    for (const [key, value] of Object.entries(fields)) {
      const errorMessage = this.getErrorMessage(key, value);
      newErrors[key] = errorMessage;
    }

    this.setState({ errors: newErrors });
    return !Boolean(
      Object.values(newErrors).reduce((acc: any, cur: any) => acc + cur)
    );
  };

  handleChange = (e: ChangeEvent) => {
    const eventTarget = e.target as HTMLInputElement;
    this.setState({
      form: {
        ...this.state.form,
        [eventTarget.name]: eventTarget.value
      }
    }, () => {
      if ((this.state.errors as any)[eventTarget.name]) {
        this.validate(eventTarget);
      }
    });
  };

  validateAllAndSubmit = async () => {
    if (!this.validateAll()) return;

    try {
      this.setState({ isLoading: true });
      await this.props.createActorAccount(this.state.form);
    } catch (e) {
      console.error(e);
      Sentry.captureException(e)
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    }

    if (this.props.errors.errorMessage) {
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    } else if (
      !this.props.errors ||
      (
        Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object
      )
    ) {
      const params = qs.stringify({
        cwblabs: true,
        redirect: 'cwblabs',
        ...(qs.parse(this.props.location.search))
      });
      this.props.history.push(`/actor/1?${params}`);
    }
  };

  render() {
    const {
      t,
      location,
      className,
      currencySymbol,
      yearlyPerMonthCharge
    } = this.props;
    const { errors, form, isLoading } = this.state;

    const canSubmit = !Boolean(
      Object.values(errors).reduce((acc: any, cur: any) => acc + cur)
    );

    const params = qs.stringify({
      cwblabs: true,
      redirect: 'cwblabs',
      ...(qs.parse(location.search))
    });

    let charge = yearlyPerMonthCharge?.toString()??"";
  if(currencySymbol === "€" && yearlyPerMonthCharge){
    charge = yearlyPerMonthCharge.toString().replace('.',',');
  }

    return (
      <StyledDiv className={className}>
          <Typography gutterBottom variant="h3">
            {t(`Let's Create Your Account!`)}
          </Typography>
          <StyledTypographyTop>
          {t("Take your career to the next level with opportunities from casting directors around the world.")}
        </StyledTypographyTop>
          <RegisterTextInput
            error={Boolean(errors.email)}
            errorMessage={errors.email}
            label={t('Email')}
            name="email"
            placeholder={t('Enter Details')}
            value={form.email}
            onBlur={(e: FocusEvent) => this.validate(
              e.target as HTMLInputElement
            )}
            onChange={(e: ChangeEvent) => this.handleChange(e)}
          />
          <RegisterTextInput
            error={Boolean(errors.password)}
            errorMessage={errors.password}
            label={t('Password')}
            name="password"
            placeholder={t('Enter Details')}
            type="password"
            value={form.password}
            onBlur={(e: FocusEvent) => this.validate(
              e.target as HTMLInputElement
            )}
            onChange={(e: ChangeEvent) => this.handleChange(e)}
            onKeyDown={(e: KeyboardEvent) => {
              e.key === 'Enter' && this.validateAllAndSubmit()
            }}
          />
          <StyledTip>
          {t("Must contain at least 6 characters, 1 letter and 1 number")}
        </StyledTip>
          <StyledButton
            disabled={!canSubmit || isLoading}
            startIcon={isLoading && (
              <Spinner className="spinner-width" src={SpinnerIcon} />
            )}
            onClick={this.validateAllAndSubmit}
          >
            {t('Create')}
          </StyledButton>
          <TermUsePrivacy />
      </StyledDiv>
    );
  }
}

const StyledDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding: ${(p) => p.theme.spacing(3.5, 10, 3)};
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  border-top:#2ED2FF solid 6px;
  align-items:center;
  position:rel

  & label {
    margin-top: ${(p) => p.theme.spacing(2)};
  }

  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 40px;
  }


`;

const StyledTypography = styled(Typography)`
font-size:17px;
width:100%;
text-align:left;
font-weight:600;
`;


const StyledTypographyTop = styled(Typography)`
  margin-top: ${(p) => p.theme.spacing(2)};
  color: #495A6E;
  font-size:13px;
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

const StyledTip = styled(Typography)`
margin-bottom: ${(p) => p.theme.spacing(1)};
font-size:11px;
line-height:15px;
margin-top: ${(p) => p.theme.spacing(1)};
color: #495A6E;
width:100%;
text-align:left;
`;


const StyledButton = styled(Button)`
  margin: ${(p) => p.theme.spacing(3, 0, 2)};
  padding: ${(p) => p.theme.spacing(1, 2)};
  background-color: #02B8F9;
  width:100%;
`;




type StateProps = {
  errors: IActorError;
};


const mapStateToProps = (state: any) => ({
  errors: state.actorModel.errors
});

type DispatchProps = {
  createActorAccount: (fields: IActorCredentials) => void;
};

const mapDispatchToProps = (dispatch: any) => ({
  createActorAccount: dispatch.actorModel.createActorAccount
});

export default withI18n()(connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActorSignUpForm)));
