import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

type LabelPositionType = 'top' | 'right' | 'bottom' | 'left';

type Props = {
  id?: string;
  className?: string;
  checked: boolean;
  labelPosition?: LabelPositionType;
  name?: string;
  onChange: (event: any) => void;
  hideMembershipToggle?: boolean
};

const Toggle: React.FC<Props> = ({
  id,
  className,
  checked,
  labelPosition = 'right',
  name = '',
  hideMembershipToggle,
  onChange: handleChange
}) => {
  const handleClick = (checked: boolean) => {
    handleChange({
      target: { name, checked }
    });
  };
  if (hideMembershipToggle) {
    return <Wrapper
      id={id}
      className={className}
      labelPosition={labelPosition}
    ></Wrapper>
  }
  return (
    <Wrapper
      id={id}
      className={className}
      labelPosition={labelPosition}
    >
      <Outer
        checked={checked}
        onClick={() => handleClick(!checked)}
      >
        <Inner checked={checked} />
      </Outer>
      <StyledInput
        defaultChecked={checked}
        name={name}
        type="checkbox"
      />
    </Wrapper>
  );
}

const Wrapper = styled.div<{ labelPosition: LabelPositionType; }>`
  display: flex;
  margin:0 12px;

  ${p => p.labelPosition === 'top' && css`
    flex-direction: column-reverse;
  `}

  ${p => p.labelPosition === 'right' && css`
    flex-direction: row;
  `}

  ${p => p.labelPosition === 'bottom' && css`
    flex-direction: column;
  `}

  ${p => p.labelPosition === 'left' && css`
    flex-direction: row-reverse;
  `}
`;

const Outer = styled.div<{ checked: boolean; }>`
  width: 30px;
  height: 18px;
  position: relative;
  display: flex;
  align-items: center;
  background-color: ${p => p.theme.palette.grey[3]}
  border-radius: 60px;
  cursor: pointer;
  transition: all 150ms;
  background-color: ${p => p.theme.palette.brand.lightBlue}
`;

const Inner = styled.div<{ checked: boolean }>`
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(125%, 25%);
  background-color: ${p => p.theme.palette.common.white};
  border-radius: 50%;
  transition: all 150ms;

  ${p => p.checked && css`
    transform: translate(25%, 25%);
  `}
`;

const StyledInput = styled.input`
  display: none;
`;


export default Toggle;
