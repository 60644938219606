import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { StyledInput } from './StyledComponents';

type Props = {
  id?: string;
  className?: string;
  autoComplete?: string;
  name?: string;
  value: string;
  onChange: (event: any) => void;
};

const PasswordInput: React.FC<Props> = (props) => {
  const [hidden, setHidden] = useState<boolean>(true);

  return (
    <Wrapper>
      <StyledInput
        type={hidden ? 'password' : 'text'}
        {...props}
      />
      <StyledImg
        alt="password hidden"
        src="../../images/eye-closed.svg"
        show={hidden}
        onClick={() => setHidden(!hidden)}
      />
      <StyledImg
        alt="password visible"
        src="../../images/eye-open.svg"
        show={!hidden}
        onClick={() => setHidden(!hidden)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

const StyledImg = styled.img<{ show: boolean; }>`
  && {
    width: 24px;
    height: 24px;
  }

  position: absolute;
  top: 50%;
  right: 0;
  cursor: pointer;
  transform: translate(-40%, -32%);

  ${p => !p.show && css`
    display: none;
  `}
`;

export default PasswordInput;
