import React, { ChangeEvent, FocusEvent, KeyboardEvent } from "react";
import qs from "query-string";
import { withI18n } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { Button, TextInput, Typography } from "components/Common";
import { Spinner } from "components/Common/StyledComponents";
import { emailRegex, passwordRegex } from "helpers/validations";
import { IActorCredentials, IActorError } from "shared/api/dtos/IActorDto";
import SpinnerIcon from "images/spinner.svg";
// @ts-ignore
import * as Sentry from "@sentry/react";
import { timedSentrySendThenErrorPage } from "helpers";

type Props = {
  t: any;
  className?: string;
  currencySymbol: string;
  yearlyPerMonthCharge: number;
  trialValid?: any;
} & StateProps &
  DispatchProps &
  RouteComponentProps;

type State = {
  errors: {
    email: string;
    password: string;
  };
  form: {
    email: string;
    password: string;
  };
  isLoading: boolean;
};

class ActorSignUpForm extends React.Component<Props, State> {
  state = {
    errors: {
      email: "",
      password: "",
    },
    form: {
      email: "",
      password: "",
    },
    isLoading: false,
  } as State;

  getErrorMessage = (name: string, value: any) => {
    const { t } = this.props;
    switch (name) {
      case "email":
        return !value
          ? t("Email is required.")
          : !emailRegex.test(value)
          ? t("Email is invalid.")
          : "";
      case "password":
        return !value
          ? t("Password is required.")
          : !passwordRegex.test(value)
          ? t("Must contain at least 6 characters, 1 letter and 1 number")
          : "";
    }
  };

  validate = (eventTarget: HTMLInputElement) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [eventTarget.name]: this.getErrorMessage(
          eventTarget.name,
          eventTarget.value.trim()
        ),
      },
    });
  };

  validateAll = () => {
    const fields = { ...this.state.form };
    const newErrors = { ...this.state.errors } as any;

    for (const [key, value] of Object.entries(fields)) {
      const errorMessage = this.getErrorMessage(key, value);
      newErrors[key] = errorMessage;
    }

    this.setState({ errors: newErrors });
    return !Boolean(
      Object.values(newErrors).reduce((acc: any, cur: any) => acc + cur)
    );
  };

  handleChange = (e: ChangeEvent) => {
    const eventTarget = e.target as HTMLInputElement;
    this.setState(
      {
        form: {
          ...this.state.form,
          [eventTarget.name]: eventTarget.value,
        },
      },
      () => {
        if ((this.state.errors as any)[eventTarget.name]) {
          this.validate(eventTarget);
        }
      }
    );
  };

  validateAllAndSubmit = async () => {
    const { trialValid } = this.props;
    if (!this.validateAll()) return;

    try {
      this.setState({ isLoading: true });
      await this.props.createActorAccount(this.state.form);
    } catch (e) {
      console.error(e);
      timedSentrySendThenErrorPage(e);
    }

    if (this.props.errors.errorMessage) {
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    } else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      let params = "";
      if (trialValid) {
        params = qs.stringify({
          trial: 1,
          ...qs.parse(this.props.location.search),
        });
      } else {
        params = qs.stringify({
          ...qs.parse(this.props.location.search),
        });
      }
      this.props.history.push(`/actor/1?${params}`);
    }
  };

  render() {
    const { t, location, className } = this.props;
    const { errors, form, isLoading } = this.state;

    const canSubmit = !Boolean(
      Object.values(errors).reduce((acc: any, cur: any) => acc + cur)
    );

    const params = qs.stringify({
      ...qs.parse(location.search),
    });

    const backParams = qs.stringify({
      ...qs.parse(location.search),
    });

    return (
      <StyledDiv className={className}>
        <div>
          <TitleTypography gutterBottom variant="h3">
            {t("Get the Pro trial now!")}
          </TitleTypography>
          <ResponsiveTypography>
            {t("*No credit card necessary to apply")}
          </ResponsiveTypography>
          <ResponsiveP>
            <DetailLink href={`/actor/trial-detail?${backParams}`} onClick={(ev) => { ev.preventDefault(); this.props.history.push(`/actor/trial-detail?${backParams}`); }}>
              {t("What will I get with my free trial")}
            </DetailLink>
          </ResponsiveP>
          <TextInput
            error={Boolean(errors.email)}
            errorMessage={errors.email}
            label={t("Email")}
            name="email"
            placeholder={t("Enter Details")}
            value={form.email}
            onBlur={(e: FocusEvent) =>
              this.validate(e.target as HTMLInputElement)
            }
            onChange={(e: ChangeEvent) => this.handleChange(e)}
          />
          <TextInput
            error={Boolean(errors.password)}
            errorMessage={errors.password}
            label={t("Password")}
            name="password"
            placeholder={t("Enter Details")}
            type="password"
            value={form.password}
            onBlur={(e: FocusEvent) =>
              this.validate(e.target as HTMLInputElement)
            }
            onChange={(e: ChangeEvent) => this.handleChange(e)}
            onKeyDown={(e: KeyboardEvent) => {
              e.key === "Enter" && this.validateAllAndSubmit();
            }}
          />
          {!Boolean(errors.password) && (
            <Typography variant="caption">
              {t("Must contain at least 6 characters, 1 letter and 1 number")}
            </Typography>
          )}
          <StyledTypography variant="bodyBold">
            {t("Let’s create your account")}
          </StyledTypography>
        </div>
        <div>
          <StyledButton
            disabled={!canSubmit}
            startIcon={
              isLoading && (
                <Spinner className="spinner-width" src={SpinnerIcon} />
              )
            }
            onClick={this.validateAllAndSubmit}
          >
            {t("Next")}
          </StyledButton>
          <LoginContainer>
            <Typography component="span" variant="captionBold">
              {t("Already a member?")}
            </Typography>
            &nbsp;
            <Link href={`${process.env.REACT_APP_CWB_Site}/login/?${params}`}>
              {t("Login")}
            </Link>
          </LoginContainer>
        </div>
      </StyledDiv>
    );
  }
}

const StyledDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding: ${(p) => p.theme.spacing(3, 7, 3)};

  & label {
    margin-top: ${(p) => p.theme.spacing(2)};
  }

  @media all and (max-width: 520px) {
    padding-top: ${(p) => p.theme.spacing(3)};
  }
`;

const StyledTypography = styled(Typography)`
  margin-top: ${(p) => p.theme.spacing(2)};
`;

const ResponsiveTypography = styled(Typography)`
  text-align: center;
`;

const ResponsiveP = styled.p`
  text-align: center;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin: ${(p) => p.theme.spacing(8, 0, 2)};
  padding: ${(p) => p.theme.spacing(1, 2)};
  background-color: #02b8f9;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`;

const Link = styled.a`
  && {
    color: #02b8f9;
    font-size: 12px;
    font-weight: bold;

    &:hover {
      color: #02b8f9;
      filter: brightness(1.15);
    }
  }
`;

const DetailLink = styled.a`
  && {
    color: #00aaff;
    font-size: 14px;
    font-weight: normal;
    text-decoration: underline;

    &:hover {
      color: #00aaff;
    }
  }
`;

const TitleTypography = styled(Typography)`
  text-align: center;
`;

type StateProps = {
  errors: IActorError;
};

const mapStateToProps = (state: any) => ({
  errors: state.actorModel.errors,
});

type DispatchProps = {
  createActorAccount: (fields: IActorCredentials) => void;
};

const mapDispatchToProps = (dispatch: any) => ({
  createActorAccount: dispatch.actorModel.createActorAccount,
});

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ActorSignUpForm))
);
