import React from "react";
import qs from "query-string";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withI18n } from "react-i18next";
import styled, { css } from "styled-components";

interface Props extends RouteComponentProps {
  t?: any;
  className?: string;
  width: number;
}

const BackButton: React.FC<Props> = ({
  children,
  history,
  location,
  t,
  className,
  width
}) => {
  const goBack = () => {
    history.goBack();
  };

  const isCWBLabs = qs.parse(location.search).cwblabs;

  return (
    <Container
      className={className}
      isCWBLabs={isCWBLabs}
      width={width}
    >
      <Button onClick={() => goBack()}>
        &lt; {t("Back")}
      </Button>
    </Container>
  );
};

const Container = styled<any, "div">("div")`
  width: ${p => p.width}px;
  margin: -23px 0 5px;
  color: ${p => p.theme.color};

  ${(p) => p.isCWBLabs && css`
    color: white;
  `}

  @media all and (max-width: 520px) {
    width: 100%;
  }
`;

const Button = styled.div`
  width: fit-content;
  font-size: ${p => p.theme["s-font-size"]};
  cursor: pointer;
`;

export default withI18n()(withRouter(BackButton));
