import axios from "axios";
import { IStripeCharge, IStripeChargeProduct } from "./dtos/IStripeDto";
// @ts-ignore
import { getAuthConfig } from "cwb-react";
import { getTrackingData } from "helpers";

export const createStripeSubscription = (
  actorSignUpId: number,
  dto: IStripeCharge
) => {
  if (!dto.trackingData) dto.trackingData = getTrackingData();
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_REG_Api}/StripeSubscription/create/${actorSignUpId}${window.location.search}`,
    headers: {
      "Content-Type": "application/json",
      CWB: "1",
      ...getAuthConfig().headers,
    },
    data: dto,
    withCredentials: true,
  });
};

export const createStripeSubscriptionOneTime = (
  actorSignUpId: number,
  dto: IStripeCharge
) => {
  if (!dto.trackingData) dto.trackingData = getTrackingData();
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_REG_Api}/StripeSubscription/create/onetimefee/${actorSignUpId}`,
    headers: {
      "Content-Type": "application/json",
      CWB: "1",
      ...getAuthConfig().headers,
    },
    data: dto,
    withCredentials: true,
  });
};

export const upgradeFreemium = (dto: IStripeCharge) => {
  if (!dto.trackingData) dto.trackingData = getTrackingData();
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_REG_Api}/StripeSubscription/upgrade`,
    headers: {
      "Content-Type": "application/json",
      CWB: "1",
      ...getAuthConfig().headers,
    },
    data: dto,
    withCredentials: true,
  });
};

export const upgradeFreemiumProduct = (dto: IStripeChargeProduct) => {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_REG_Api}/StripeSubscription/upgrade/add-on`,
    headers: {
      "Content-Type": "application/json",
      CWB: "1",
      ...getAuthConfig().headers,
    },
    data: dto,
    withCredentials: true,
  });
};

export const upgradeOneTime = (dto: IStripeCharge) => {
  if (!dto.trackingData) dto.trackingData = getTrackingData();
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_REG_Api}/StripeSubscription/upgrade/onetimefee`,
    headers: {
      "Content-Type": "application/json",
      CWB: "1",
      ...getAuthConfig().headers,
    },
    data: dto,
    withCredentials: true,
  });
};
