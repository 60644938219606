import React, { useCallback, useEffect, useState } from 'react';
import qs from 'query-string';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  TrialFooter,
  Modal,
  PageContainer,
  Typography
} from '../../../Common';
import { getWebSessionKey } from "../../../../helpers";

interface Props extends StateProps, RouteComponentProps {
  t: any;
}

const ActorTrailRegistrationComplete: React.FC<Props> = ({
  t,
  location,
  clientId,
  residenceNotMatch
}) => {
  const [remainingTime, setRemainingTime] = useState<number>(19);
  const qsArgs = qs.parse(location.search);
  const isJob = qsArgs.page === 'openbd' || qsArgs.page === 'submission' || (qsArgs.bd && qsArgs.bd.length > 4);

  const redirect = useCallback(() => {
    const baseUrl = process.env.REACT_APP_CWB_Site;
    const k = getWebSessionKey();
    const redirectObject: any = {
      signup: 1,
      login: clientId,
      ...(qs.parse(location.search))
    };

    if (isJob && !redirectObject.page) {
      redirectObject.page = 'openbd';
    };

    // if(residenceNotMatch !== null && residenceNotMatch){
    //   // enable page redirect & submit because of pay as you go
    //   delete redirectObject.page;
    //   delete redirectObject.bd;
    // }

    if (!redirectObject.login) {
      delete redirectObject.login;
    }

    if (k) {
      redirectObject.k = k;
      const params = qs.stringify(redirectObject);
      window.location.replace(`${baseUrl}/login/go?${params}`);
    } else {
      const params = qs.stringify(redirectObject);
      window.location.replace(`${baseUrl}/login/?${params}`);
    }
  }, [clientId, location.search, residenceNotMatch]);

  useEffect(() => {
    if (remainingTime === 0) {
      redirect();
    } else {
      setTimeout(() => {
        setRemainingTime(remainingTime - 1);
      }, 1000);
    }
  }, [remainingTime, redirect]);

  return <PageContainer>
    <StyledModal>
      <Typography
        align="center"
        component="h1"
        variant="h2"
      >
        {t("Thank you for signing up for your Casting Workbook free trial")}
      </Typography>
      <Icon alt="check" src="/images/icon-tick.svg" />
      <StyledTypography
        align="center"
        color="darkGrey"
      >
        {t('You now have access to all of Casting Workbooks Pro features and content!')}
      </StyledTypography>
      <StyledTypography
        align="center"
        color="darkGrey"
      >
        {t('On a mobile device? Go to the App Store or Google Play store to download the powerful Actor App!')}
      </StyledTypography>
      {
        !isJob ?
          (<StyledTypography
            align="center"
            color="darkGrey"
          >
            {t("You will automatically be redirected to the home page, in")}
            &nbsp;<span>{remainingTime}</span>&nbsp;
            {t("seconds.")}
          </StyledTypography>) :
          (<StyledTypography
            align="center"
            color="darkGrey"
          >
            {t('You will be automatically redirected to the submission screen, in')}
            &nbsp;<span>{remainingTime}</span>&nbsp;
            {t('seconds.')}
          </StyledTypography>)
      }
      <StyledTypography align="center"
        color="darkGrey">
        or
      </StyledTypography>
      <StyledButton onClick={() => redirect()}>
        {!isJob ? t("Go to Homepage") : t('Submit to breakdown')}
      </StyledButton>
      <TrialFooter />
    </StyledModal>
  </PageContainer>
    ;
};

const StyledModal = styled(Modal)`
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${p => p.theme.spacing(11, 18, 3)};
  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 40px;
  }
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin: ${p => p.theme.spacing(3, 6)};
  padding: 7px 7px 6px;
  background-color: ${p => p.theme.palette.system.success};
  border-radius: 50%;
`;

const StyledTypography = styled(Typography)`
  margin-bottom: ${p => p.theme.spacing(3)};
  font-size: 16px;
  line-height: 22px;

  & > span {
    color: ${p => p.theme.palette.misc.darkBlue};
    text-decoration: underline;
  }
`;

const StyledButton = styled(Button)`
margin-bottom:${p => p.theme.spacing(3)}
`;

interface StateProps {
  clientId: string;
  residenceNotMatch: boolean;
}

const mapStateToProps = (state: any) => ({
  clientId: state.actorModel.clientId,
  residenceNotMatch: state.actorModel.residenceNotMatch
});

export default withI18n()(withRouter(connect(
  mapStateToProps,
  null
)(ActorTrailRegistrationComplete)));
