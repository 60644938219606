import React from "react";
import styled from "styled-components";
import { withI18n } from "react-i18next";

interface IProps {
  t?: any;
}

const ActorSellingFeatures: React.FC<IProps> = ({ t }) => {
  return (
    <>
      <TitleWrapper>
        <MainTile>{t("Sign up for your Casting Workbook Pro Account!")}</MainTile>
      </TitleWrapper>
      <StyledP>
        {t(
          "One membership gives you all the tools you need to break into & thrive in the acting business:"
        )}
      </StyledP>
      <Container>
        <Detail>
          <DetailImg src="../../images/selling_one.png" />
          <DetailRight>
            <Title>
              <Point></Point> {t("Receive Agent Auditions, Self-Tape & Find Jobs")}
            </Title>
            <Content>
              {t(" The big productions hire casting directors who post directly to agents so having an agent matters!")}
              <br></br>
              <br></br>
              {t("But don't worry if you don’t have an agent yet! Most small and many big projects go out directly to actors for those hard to cast roles.")}
            </Content>
          </DetailRight>
        </Detail>
        <Detail>
          <DetailImg src="../../images/selling_two.png" />
          <DetailRight>
            <Title>
              <Point></Point> {t("Get Submitted or if not repped, submit directly to open roles.")}
            </Title>
            <Content>
              {t("You and your agent, get multiple resumes, headshots, submissions to jobs and casting director searches.")}
            </Content>
          </DetailRight>
        </Detail>
      </Container>
    </>
  );
};

export default withI18n()(ActorSellingFeatures);

const TitleWrapper = styled.div`
  width: 100%;
`;

const MainTile = styled.div`
  font-size: 28px;
  font-weight: 600;
  line-height: 44px;
  color: #FFFFFF !important;
  margin-bottom: 12px;
  @media all and (max-width: 768px) {
    padding-right: 15px;
    padding-left: 15px;
  }
  @media all and (max-width: 520px) {
    background-color: rgb(4, 15, 28);
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 0
  }
`;

const StyledP = styled.p`
  font-size: 16px;
  color: #FFFFFF !important;
  line-height: 22px;
  @media all and (max-width: 768px) {
    width:100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  @media all and (max-width: 520px) {
    background-color: rgb(4, 15, 28);
    font-size: 16px;
    padding: 15px;
    text-align:center;
  }
`;

const Container = styled.div`
max-width: 672px;
left: 133px;
top: 202px;

padding-top:24px;
border-radius: 2px;
display:flex;
flex-direction:column;
justify-content:space-evenly;
background: #FFFFFF;
padding:24px 24px 0px 24px;

@media all and (max-width: 768px) {
  width:100%;
  background: #FFFFFF;
  padding:18px;
}
`;

const Detail = styled.div`
width:100%;
display:flex;
justify-content:space-between;
margin-bottom:24px;
@media all and (max-width: 768px) {
  flex-direction:column;
}
`;

const DetailImg = styled.img`
width:30%;
@media all and (max-width: 768px) {
  width:100%;
  margin-bottom:24px;
}
`;

const DetailRight = styled.div`
width:67%;
display:flex;
flex-direction:column;
@media all and (max-width: 768px) {
  width:100%;
}
`;

const Title = styled.div`
font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 24px;
`;

const Point = styled.span`
color: #00AAFF;
`;

const Content = styled.div`
margin-top:8px;
font-family: Open Sans;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 15px;
/* or 107% */

letter-spacing: 0.374px;

/* Greys/700 */

color: #53627C;
`;


