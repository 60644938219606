import React from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import { withI18n } from "react-i18next";
import { getRegionCode } from "helpers";

interface Props {}

class StripeActorPaymentWrapper extends React.Component<Props, any> {
  getStripeKey = () => {
    const regionCode = getRegionCode();

    switch (regionCode) {
      case "CA":
        return process.env.REACT_APP_Stripe_CA_key;
      case "US":
        return process.env.REACT_APP_Stripe_US_key;
      case "ES":
        return process.env.REACT_APP_Stripe_ES_key;
      case "LATAM":
      case "MX":
        return process.env.REACT_APP_Stripe_LATAM_key;
      default:
        return process.env.REACT_APP_Stripe_CA_key;
    }
  };

  render() {
    return (
      <>
        <StripeProvider apiKey={this.getStripeKey()}>
          <Elements>{this.props.children}</Elements>
        </StripeProvider>
      </>
    );
  }
}

export default withI18n()(StripeActorPaymentWrapper);
