import React from "react";
import qs from "query-string";
import { withI18n } from "react-i18next";
import styled, { css } from "styled-components";
import { connect } from "react-redux";

import {
  VerifyPhoneCodeLength,
  getCurUserSignUp,
  getSessionId,
  getCountryCode,
} from "../../helpers";
import PhoneInput from "react-phone-input-2";
import Footer from "../Common/Footer";
import Modal from "../Common/Modal";
import PageContainer from "../Common/PageContainer";
import {
  Section,
  StyledBlueBtn,
  SectionTitle,
  SmallTextError,
  Spinner,
} from "../Common/StyledComponents";
import CWBLabsActorVerifyPhoneStep from "components/CWBLabs/ActorVerifyPhoneStep";
import { iRootState } from "../../store";
import {
  IActorError,
  IActorPhone,
  IActorAccountDto,
} from "shared/api/dtos/IActorDto";
import { ICountryDto } from "shared/api/dtos/IReferencesDto";
import { Button, TextInput, Typography } from "components/Common";
import { phoneValidation } from "helpers/validations";
import Page from "./components/Page";
import Card from "./components/Card";

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location?: any;
}

interface IState {
  canResend: boolean;
  isFormSubmitClicked: boolean;
  fields: IActorPhone;
  phoneChanging: boolean;
  newPhone: string;
  isChangingSubmitClicked: boolean;
}

class ActorVerifyPhoneStep extends React.Component<IProps, IState> {
  public state: IState = {
    canResend: true,
    isFormSubmitClicked: false,
    fields: { phone: "", code: "" },
    phoneChanging: false,
    newPhone: "",
    isChangingSubmitClicked: false,
  };

  async componentDidMount() {
    const sessionId = getSessionId();
    if (!sessionId) {
      this.props.location.replace(process.env.REACT_APP_CWB_500);
    }
    const user: IActorAccountDto = getCurUserSignUp();
    if (user && !this.state.fields.phone) {
      this.setState({
        fields: {
          ...this.state.fields,
          ...user.actorPhone,
        },
      });
    }

    let countryCode = this.props.countryCode;

    const qsArgs = qs.parse(this.props.location.search)
    if (qsArgs.dev && qsArgs.ctry) {
      countryCode = qs.parse(this.props.location.search).ctry as string;
      this.props.setForceRequesterCountryCode(countryCode);
    }

    if (!countryCode && !getCountryCode()) {
      try {
        await this.props.setRequesterCountryCode();
      } catch (e) {
        console.error(e);
      }
    }
  }

  checkValidation() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IActorError;

    if (
      !fields.code ||
      !fields.code.match(/^\d{4}/i) ||
      fields.code.length !== VerifyPhoneCodeLength
    ) {
      errors.Code = t(
        "Invalid code, only numbers and a code length of {{length}} is expected.",
        { length: VerifyPhoneCodeLength }
      );
      fields.code = "";
      this.setState({ fields });
    }

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  checkNumber() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IActorError;

    if (!fields.code || fields.code.match(/^[0-9`]+$/i)) {
      this.props.setErrors(errors);
      return true;
    }

    errors.Code = t("Only numbers are accepted");
    fields.code = "";
    this.setState({ fields });
    this.props.setErrors(errors);
    return false;
  }

  checkPhoneValidation() {
    const { t } = this.props;
    let errors = {} as IActorError;
    if (!this.state.newPhone) {
      errors.NewPhone = t("Invalid Phone Number");
    } else if (phoneValidation(this.state.newPhone, t))
      errors.NewPhone = phoneValidation(this.state.newPhone, t);

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  handleChange = (e: any) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    this.checkNumber();
  };

  handleChangeNewPhone = (phoneNumber: string) => {
    this.setState({
      newPhone: phoneNumber,
    });
    this.checkNumber();
    this.state.isChangingSubmitClicked && this.checkPhoneValidation();
  };

  getNumber = (): string => {
    return `+${this.state.newPhone}`;
  };

  handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      this.confirmChangePhone(event);
    }
  };

  confirmChangePhone = async (e: any) => {
    e.preventDefault();
    if (this.props.isLoading) return;
    this.setState({ isChangingSubmitClicked: true });
    if (!this.checkPhoneValidation()) return;
    await this.props.createActorPhone({ phone: this.getNumber() });
    const user: IActorAccountDto = getCurUserSignUp();
    if (user) {
      this.setState({
        fields: {
          ...this.state.fields,
          ...user.actorPhone,
        },
      });
    }
    this.setState({
      phoneChanging: false,
      newPhone: "",
      isChangingSubmitClicked: false,
    });
  };

  verifyPhone = async (e: any) => {
    e.preventDefault();
    this.setState({ isFormSubmitClicked: true });
    if (!this.checkValidation()) return;
    await this.props.verifyActorPhone(this.state.fields);
    this.moveToNext();
  };

  resendCode = async (e: any) => {
    e.preventDefault();
    if (!this.state.canResend) return;
    const user: IActorAccountDto = getCurUserSignUp();
    if (!user) window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    this.setState({ canResend: false });
    await this.props.createActorPhone(user.actorPhone);
    setTimeout(() => this.setState({ canResend: true }), 10 * 1000);
  };

  changePhone = () => {
    this.setState({
      phoneChanging: true,
    });
  };

  cancelChangePhone = () => {
    this.setState({
      phoneChanging: false,
    });
  };

  changeToEmail = () => {
    this.props.setSelectPhone(false);
    this.props.history.push({
      pathname: "/actor/1",
      search: this.props.location.search,
    });
  };

  moveToNext = () => {
    if (this.props.errors.errorMessage)
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/actor/3",
        search: this.props.location.search,
      });
    }
  };

  render() {
    const { t, location, countryCode, errors, isLoading } = this.props;
    const userCountryCode = countryCode || getCountryCode();
    const { canResend, fields, phoneChanging, newPhone } = this.state;

    return (
      <StyledPage>
        {phoneChanging ? (
          <Wrapper>
            <Title>{t("Change your Phone Number")}</Title>
            <Section>
              <SectionTitle>{t("Existing Phone Number")}</SectionTitle>
              <StyledInputDisable value={fields.phone} disabled />
            </Section>
            <Section>
              <SectionTitle>{t("New Phone Number")}</SectionTitle>
              {/* <StyledInput
              value={newPhone}
              type="email"
              onChange={this.handleChangeNewPhone}
            /> */}
              <PhoneInput
                containerStyle={{ marginTop: "8px" }}
                country={
                  userCountryCode ? userCountryCode.toLocaleLowerCase() : ""
                }
                value={this.state.newPhone}
                onChange={(phoneNumber) =>
                  this.handleChangeNewPhone(phoneNumber)
                }
                onEnterKeyPress={this.handleKeyPress}
              />
              <SmallTextError className="error">
                <span>{this.props.errors.Phone}</span>
              </SmallTextError>
            </Section>
            <SmallTextError className="error">
              <span>{this.props.errors.NewEmail}</span>
            </SmallTextError>
            <Section>
              <StyledBlueBtn
                onClick={this.confirmChangePhone}
                disabled={isLoading}
              >
                {t("Confirm")}
                {isLoading && (
                  <Spinner
                    src="../images/spinner.svg"
                    className="spinner-width"
                  />
                )}
              </StyledBlueBtn>
            </Section>
            <StyledAnchor onClick={this.cancelChangePhone}>
              {t("Cancel")}
            </StyledAnchor>
          </Wrapper>
        ) : (
          <StyledCard>
            <Typography align="center" component="h1" gutterBottom variant="h2">
              {t("Verify your phone number")}
            </Typography>
            <Typography align="center" color="medGrey" gutterBottom>
              {t("Enter the {{length}} digit code we sent to", {
                length: VerifyPhoneCodeLength,
              })}
            </Typography>
            <Typography align="center" color="darkGrey" variant="bodyBold">
              {fields && fields.phone}
              <ReplaceImg
                onClick={this.changePhone}
                src="/images/replace.svg"
                alt=""
              />
            </Typography>
            <Form onSubmit={this.verifyPhone}>
              <TextInput
                error={Boolean(errors.Code)}
                errorMessage={errors.Code}
                label={t("Verification Code")}
                name="code"
                value={fields.code}
                onChange={this.handleChange}
                autoFocus
              />
              <StyledButton
                startIcon={
                  isLoading && (
                    <Spinner
                      className="spinner-width"
                      src="../images/spinner.svg"
                    />
                  )
                }
              >
                {t("Continue")}
              </StyledButton>
            </Form>
            <TextButton disabled={!canResend} onClick={this.resendCode}>
              {canResend ? t("Resend code") : t("Code resent!")}
            </TextButton>
            <TextButton onClick={this.changeToEmail}>
              {t("Verify By Email")}
            </TextButton>
          </StyledCard>
        )}
        <StyledFooter />
      </StyledPage>
    );
  }
}

interface StateProps {
  errors: IActorError;
  fields: IActorPhone;
  countryList: ICountryDto[];
  isLoading: boolean;
  countryCode: string;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    errors: state.actorModel.errors,
    fields: state.actorModel.fields,
    countryList: state.referencesModel.countryList,
    isLoading: state.actorModel.isLoading,
    countryCode: state.referencesModel.countryCode,
  };
}

interface DispatchProps {
  setErrors: (errors: IActorError) => void;
  createActorPhone: (fields: IActorPhone) => void;
  getCountryList: () => void;
  verifyActorPhone: (fields: IActorPhone) => void;
  setSelectPhone: (phoneSelected: boolean) => void;
  setRequesterCountryCode: (fallback?: string | null) => void;
  setForceRequesterCountryCode: (countryCode: string) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    setErrors: dispatch.actorModel.setErrors,
    createActorPhone: dispatch.actorModel.createActorPhone,
    getCountryList: dispatch.referencesModel.getCountryList,
    verifyActorPhone: dispatch.actorModel.verifyActorPhone,
    setSelectPhone: dispatch.actorModel.setSelectPhone,
    setRequesterCountryCode: dispatch.referencesModel.setRequesterCountryCode,
    setForceRequesterCountryCode: dispatch.referencesModel.setForceRequesterCountryCode
  };
}

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorVerifyPhoneStep)
);

const StyledPage = styled(Page)`
  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding-top: 8px;
    border: none;
  }
`;

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-weight: ${(p) => p.theme["font-weight-600"]};

  max-width: 480px;
  min-width: 480px;
  background-color: ${(p) => p.theme.white};
  padding: 40px 80px;

  @media all and (max-width: 520px) {
    width: 90%;
    min-width: 40%;
  }

  * {
    font-size: ${(p) => p.theme["s-font-size"]};
  }
`;

const StyledAnchor = styled.a<{ disabled?: boolean }>`
  && {
    color: #00a2e0;
    font-size: ${(p) => p.theme["xs-font-size"]};
    font-weight: ${(p) => p.theme["font-weight-600"]};
    letter-spacing: 0.21px;
    line-height: 15px;
    text-align: center;
    margin-top: 8px;
    cursor: pointer;

    &:hover {
      color: ${(p) => p.theme.lightBlue};
    }

    ${(p) =>
      p.disabled &&
      css`
        color: ${(p) => p.theme.typography.color.disabled};
        cursor: default;

        &:hover {
          color: ${(p) => p.theme.typography.color.disabled};
        }
      `}
  }
`;

export const StyledInput = styled.input`
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: ${(props) => props.theme.dropDown["border"]};
  border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  background-color: ${(props) => props.theme.white};
  padding: 0 14px;
  margin-top: 8px;
  outline: 0 !important;

  &.invalid {
    color: ${(p) => p.theme.errorColor};
    border: ${(p) => p.theme["error-border"]};
    background-color: ${(p) => p.theme.red};
  }

  &:focus {
    border-color: #0f9bf0;
  }
`;

const Title = styled.div`
  font-size: ${(p) => p.theme["xxl-font-size"]} !important;
`;

const ReplaceImg = styled.img`
  width: 12px;
  height: 12px;
  margin: ${(p) => p.theme.spacing(0, 0, 0, 1)};
  cursor: pointer;
`;

export const StyledInputDisable = styled.input`
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: ${(props) => props.theme.dropDown["border"]};
  border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  background-color: #f1f2f4;
  padding: 0 14px;
  margin-top: 8px;
  outline: 0 !important;
  color: #8a94a6;
`;

const StyledCard = styled(Card)`
  width: 480px;
  padding: ${(p) => p.theme.spacing(4, 10)};

  @media all and (max-width: 520px) {
    width: 100%;
    padding: 40px;
    border-top: 0;
    border-radius: 0;
  }
`;

const Form = styled.form`
  width: 100%;
  margin: ${(p) => p.theme.spacing(2, 0)};
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${(p) => p.theme.spacing(3)};
`;

const TextButton = styled.button`
  color: ${(p) => p.theme.palette.button.normal};
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  background-color: transparent;
  border: none;

  &:hover {
    color: ${(p) => p.theme.palette.button.hover};
  }

  &:active {
    color: ${(p) => p.theme.palette.button.active};
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${(p) => p.theme.typography.color.disabled};
    cursor: default;
  }

  & + & {
    margin-top: ${(p) => p.theme.spacing(1)};
  }
`;

const StyledFooter = styled(Footer)`
  margin-top: ${(p) => p.theme.spacing(3)};
`;
