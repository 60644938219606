import React, { useCallback, useEffect, useState } from 'react';
import { getI18n, withI18n } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Typography } from 'components/Common';
import Card from './components/Card';
import Header from './components/Header';
import Footer from './components/Footer';
import Page from './components/Page';
import WarningCircle from 'images/warning.svg';
import { IPurchaseAttempt } from 'shared/api/dtos/IStripeDto';
import { iRootState } from 'store';
import { connect } from 'react-redux';
// @ts-ignore
import { getCurUser, getWebSessionKeyFromAuthToken, getSessionDataFromAuthToken, getAuthToken } from 'cwb-react'
import { getWebSessionKey } from "helpers";

type Props = {
  t: any;
} & RouteComponentProps & DispatchProps;

const ModalPaymentRequiresAction: React.FC<Props> = ({
  t,
  history,
  location,
  purchase,
  clientId
}) => {
  const isLangSpanish = getI18n().language === "es";
  const support = isLangSpanish
    ? process.env.REACT_APP_CWB_Support_es
    : process.env.REACT_APP_CWB_Support;

  const [disableButton, setDisableButton] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [remainingTime, setRemainingTime] = useState<number>(15);
  const redirect = useCallback(() => {

    let targetId = clientId;
    if (!targetId) {
      const user = getCurUser();
      if (user && user.id) {
        targetId = user.id.userId
      }

      if (!targetId) {
        const data: any = getSessionDataFromAuthToken(getAuthToken());
        if (data) {
          targetId = data.UserId || data.userId
        }
      }
    }

    if (!targetId || !purchase?.serviceId) {
      history.push({
        pathname: '/actor/payment-declined',
        search: location.search
      });
      return;
    }

    let url = `${process.env.REACT_APP_Api}/reg/Redirect/invoice/incomplete/${targetId}/product/${purchase.serviceId}`;

    let webSessionKey = getWebSessionKey();
    if (!webSessionKey) {
      webSessionKey = getWebSessionKeyFromAuthToken();
    }

    if (webSessionKey)
      url = url + `?k=${webSessionKey}`;

    if (purchase.couponCode) {
      if (url.indexOf('?') > -1)
        url = url + '&';
      else
        url = url + '?';
      url = url + `couponCode=${purchase.couponCode}`;
    }

    window.top.location.replace(url);
  }, []);

  useEffect(() => {
    if (remainingTime === 3) {
      setDisableButton(false);
    } else if (remainingTime == 2) {
      setIsRedirecting(true);
      redirect();
    } else if (remainingTime == 0) {
      return;
    }
    let expire = setTimeout(() => {
      const copy = remainingTime;
      setRemainingTime(copy - 1);
    }, 1000);

    return () => expire && clearTimeout(expire);
  }, [remainingTime]);
  return (
    <>
      <Header />
      <Page>
        <StyledDiv>
          <StyledCard variant='secondary'>
            <Icon src={WarningCircle} />
            <Typography align="center" component="h1" variant="h3">
              {t("Your payment requires confirmation")}
            </Typography>
            <HorizontalSeparator />
            <StyledTypography align="center">
              {t(
                `We attempted to process your payment but it requires verification.`
              )} &nbsp;
              {t('You will be automatically redirected to a secure payment page in')}
              &nbsp;<span>{remainingTime}</span>&nbsp;
              {t('seconds')}
            </StyledTypography>

            <StyledButton disabled={disableButton || isRedirecting}
              onClick={() => {
                if (isRedirecting)
                  return;

                if (remainingTime > 2) {
                  setRemainingTime(2);
                }
                setIsRedirecting(true);
                redirect()
              }
              }>
              {t("Pay now")}
            </StyledButton>
          </StyledCard>
          <Footer />
        </StyledDiv>
      </Page>
    </>
  );
};

const StyledDiv = styled.div`
  width: 450px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: ${(p) => p.theme.spacing(5, 'auto', 0)};

  @media all and (max-width: 520px) {
    width: unset;
    margin-top: ${(p) => p.theme.spacing(3)};
    padding: ${(p) => p.theme.spacing(0, 2)};
  }
`;

const StyledCard = styled(Card)`
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing(3)};
  padding-top: ${(p) => p.theme.spacing(2.5)};
  padding-left: ${(p) => p.theme.spacing(7)};
  padding-right: ${(p) => p.theme.spacing(7)};
  border-color: rgb(252, 183, 4);
  @media all and (max-width: 520px) {
    padding-left: ${(p) => p.theme.spacing(3)};
    padding-right: ${(p) => p.theme.spacing(3)};
  }
`;

const Icon = styled.img`
  margin-bottom: ${(p) => p.theme.spacing(1)};
`;

const HorizontalSeparator = styled.div`
  width: 72px;
  height: 3px;
  margin: ${(p) => p.theme.spacing(1, 0, 2)};
  background: ${(p) => p.theme.palette.system.warning};
`;

const StyledTypography = styled(Typography)`
  margin: ${(p) => p.theme.spacing(0, 0, 2)};
  line-height: 18px;
`;

const Link = styled.a`
  && {
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledButton = styled(Button)`
  width: 80%;
  margin-top: ${(p) => p.theme.spacing(2)};
  padding: ${(p) => p.theme.spacing(1, 2)};
 

  @media all and (max-width: 520px) {
    width: 100%;
  }
`;


interface DispatchProps {
  purchase: IPurchaseAttempt;
  clientId: any;
}

const mapStateToProps = (state: iRootState) => ({
  purchase: state.stripeModel.purchase,
  clientId: state.actorModel.clientId,
});

export default withI18n()(withRouter(connect(
  mapStateToProps,
  null
)(ModalPaymentRequiresAction)));
