import React from "react";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import { getSessionId } from "../../helpers";
import { ICastingError } from "../../shared/api/dtos/ICastingDtos";
import { iRootState } from "../../store";
import BackButton from "../Common/BackButton";
import Footer from "../Common/Footer";
import Modal from "../Common/Modal";
import PageContainer from "../Common/PageContainer";
import {
  StyledBlueWhiteBtn,
  Spinner,
  StyledBlueBtn
} from "../Common/StyledComponents";

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location?: any;
}

interface IState {
  accountType: number;
}

// TODO: Move style and JSDOM out of container compnent  into Presentational Components
class CastingAccessStep extends React.Component<IProps, IState> {
  public state: IState = {
    accountType: 0
  };

  async componentWillMount() {
    if (!getSessionId()) {
      this.props.history.push({
        pathname: "/",
        search: this.props.location.search
      });
    }
  }

  finishAccountSetup = async (value: number) => {
    this.setState({ accountType: value });
    if (value !== 1) {
      await this.props.createCastingUnverified();
    }
    this.moveToNext();
  };

  goToAssociations = () => {
    this.props.history.push({
      pathname: "/casting/select-associations",
      search: this.props.location.search
    });
  };

  moveToNext = () => {
    if (this.props.errors.errorMessage)
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/casting/limited",
        search: this.props.location.search
      });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  isSafari = (): boolean => {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') > -1) {
        return false
      } else {
        return true;
      }
    }
  }

  render() {
    const { t, isLoading } = this.props;
    return (
      <PageContainer>
        <BackButton width={872} />
        <Wrapper>
          <InnerWrapper>
            <StyledTitle>
              {t("Select the casting account that works best for you")}
            </StyledTitle>
            <StyledSectionDiv>
              <SectionWrapper>
                <section>
                  <SectionTitle>
                    {t("Instant Access")}
                    <span style={{ display: 'flex' }}>
                      {t("(Limited Features)")}
                    </span>
                  </SectionTitle>
                  <SectionSubTitle>{t("No references required")}</SectionSubTitle>
                  <ContentDiv>
                    <Text>{t("Post breakdowns to actors and some agents")}</Text>
                    <Text>
                      {t("SmartScript for analysis, breakdowns and sides")}
                    </Text>
                    <Text>{t("Request actor self-tapes")}</Text>
                    <Text>{t("Schedule auditions")}</Text>
                    <Text>{t("ePitch with tracking")}</Text>
                  </ContentDiv>
                </section>
                <section>
                  <StyledBlueWhiteBtn onClick={() => this.finishAccountSetup(6)} disabled={isLoading}>
                    {t("Get Instant Access")}
                    {this.state.accountType === 6 && isLoading && (
                      <Spinner src="../images/spinner_blue.svg" />
                    )}
                  </StyledBlueWhiteBtn>
                </section>
              </SectionWrapper>
              <SectionWrapper>
                <section>
                <SectionTitle>
                  {t("Pro-Casting")}
                  <span style={{ display: 'flex' }}>
                    {t("(All Features)")}
                  </span>
                </SectionTitle>
                <SectionSubTitle>
                  {t("2 agency references required")}*
                </SectionSubTitle>
                <ContentDiv>
                  <Text>{t("Post breakdowns to actors and agents")}</Text>
                  <Text>
                    {t("SmartScript for analysis, breakdowns and sides")}
                  </Text>
                  <Text>{t("Request actor self-tapes")}</Text>
                  <Text>{t("Schedule auditions")}</Text>
                  <Text>{t("ePitch and Teamshare with tracking")}</Text>
                  <Text>{t("Search resumes, profiles and rosters")}</Text>
                  <Text>{t("Smart Audition Manager (SAM) access")}</Text>
                  <Text>{t("iSAM access (desktop capture and encode)")}</Text>
                </ContentDiv>
                </section>
                <section style={{ marginTop: '16px' }}>
                  <StyledBlueBtn className={this.isSafari() && 'safari-btn'} onClick={() => this.goToAssociations()}>
                    {t("Get Pro-Casting")}
                  </StyledBlueBtn>
                  <Note>
                    *{t("No references required for CSA, CDC or CCDA members")}
                  </Note>
                </section>
              </SectionWrapper>
            </StyledSectionDiv>
          </InnerWrapper>
        </Wrapper>
        <Footer />
      </PageContainer>
    );
  }
}

interface StateProps {
  isLoading: boolean;
  errors: ICastingError;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    isLoading: state.castingModel.isLoading,
    errors: state.castingModel.errors
  };
}

interface DispatchProps {
  createCastingUnverified: () => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    createCastingUnverified: dispatch.castingModel.createCastingUnverified
  };
}

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(CastingAccessStep)
);

const Wrapper = styled(Modal)`
  max-width: 872px;
  min-width: 872px;

  @media all and (max-width: 920px) {
    width: 90%;
    min-width: 40%;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: ${p => p.theme.white};
  font-weight: ${p => p.theme["font-weight-600"]};
  padding: 40px 80px;
`;

const StyledTitle = styled.div`
  font-size: ${p => p.theme["xxl-font-size"]};
  margin-bottom: 8px;
`;

const StyledSectionDiv = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const SectionWrapper = styled.div`
  width: 348px;
  border: ${props => props.theme.dropDown["border"]} !important;
  border-radius: ${props => props.theme.dropDown["border-radius"]};
  background-color: ${props => props.theme.white} !important;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  padding: 24px;
  margin-top: 8px;

  &:first-child {
    margin-right: 16px;
    @media all and (max-width: 920px) {
      margin-right: 0;
    }
  }

  &:first-child  button {
    width: 100%;
    margin-bottom: 32px;
  }
`;

const SectionTitle = styled.div`
  font-size: ${p => p.theme["xl-font-size"]};
`;

const SectionSubTitle = styled.div`
  color: ${p => p.theme.color} !important;
  font-size: ${p => p.theme["s-font-size"]};
  margin-top: 8px;
  padding-bottom: 19px;
  border-bottom: 1px solid #d3dde9;
`;

const Text = styled.div`
  color: ${p => p.theme.color} !important;
  font-size: ${p => p.theme["s-font-size"]};
  font-weight: normal;
  margin-top: 16px;
`;

const ContentDiv = styled.div`
  min-height: 296px;
`;

const Note = styled.div`
  color: ${p => p.theme.color} !important;
  font-size: ${p => p.theme["xxs-font-size"]};
  font-weight: normal;
  margin-top: 16px;
`;
