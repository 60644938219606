import React from "react";
import { withI18n } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { Button, Modal, Typography } from "../../Common";
import ErrorIcon from "../../../images/error.svg";
import { setCurUserSignUp } from "helpers";
// @ts-ignore
import { getCurUser } from "cwb-react";

type Props = {
  t: any;
} & RouteComponentProps;

const ModalSignUpPaymentDeclined: React.FC<Props> = ({
  t,
  history,
  location,
}) => {
  const onLogin = () => {
    setCurUserSignUp(null);
    const user = getCurUser();
    const clientId = user.id ? user.id.userId : null;
    window.location.assign(
      `${process.env.REACT_APP_CWB_Site}/login/?login=${clientId}`
    );
  };

  return (
    <>
      <StyledModal>
        <Typography align="center" component="h1" variant="h2">
          {t("Your payment was declined")}
        </Typography>
        <StyledImg src={ErrorIcon} />
        <Typography align="center" color="darkGrey" variant="h4">
          {t(
            "Thank you for joining Casting Workbook. We attempted to process your payment but there was a problem. We have created a freemium basic account so you can log into Casting Workbook and try your payment again. Please use the membership ID and password sent to your email to access your profile and click UPGRADE to activate your full service account. Thank you"
          )}
          .
        </Typography>
        <div>
          <StyledButton
            onClick={() =>
              history.push({
                pathname: "/actor/payment",
                search: location.search,
              })
            }
          >
            {t("Try again")}
          </StyledButton>
          <StyledLoginButton onClick={() => onLogin()}>
            {t("Login")}
          </StyledLoginButton>
        </div>
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(p) => p.theme.spacing(10, 19, 12)};
`;

const StyledImg = styled.img`
  margin: ${(p) => p.theme.spacing(2, 0, 4)};
`;

const StyledButton = styled(Button)`
  margin-top: ${(p) => p.theme.spacing(5)};
  display: inline;
  min-width: 150px;
  margin-right: 100px;
`;

const StyledLoginButton = styled(Button)`
  margin-top: ${(p) => p.theme.spacing(5)};
  display: inline;
  min-width: 150px;
`;

export default withI18n()(withRouter(ModalSignUpPaymentDeclined));
