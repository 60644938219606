import React from "react";
import styled from "styled-components";
import { withI18n } from "react-i18next";
import * as SharedStyle from "../Common/StyledComponents";

interface IProps {
  t?: any;
}

const AgentSellingFeatures: React.FC<IProps> = ({ t }) => {
  return (
    <>
      <TitleWrapper>
        <MainTile>{t("Talent Agent / Manager")}</MainTile>
      </TitleWrapper>
      <StyledP>
        {t(
          "Why Join? We offer you powerful, innovative and free technology for the busy agent and manager’s office"
        )}
      </StyledP>
      <TitleWrapper>
        <Title>
          <SharedStyle.SellingFeatureIcon src="../images/files_labeled_outlined.svg" />
          <div>{t("Breakdowns")}</div>
        </Title>
      </TitleWrapper>
      <SharedStyle.SellingFeatureP>
        {t(
          "Film, Television, Commercials, Theater & Voice breakdowns with free integrated scripts & sides. Multiple agent coverage for studios & networks. And add private development & casting grid projects for tracking and pitching clients."
        )}
      </SharedStyle.SellingFeatureP>
      <TitleWrapper>
        <Title>
          <SharedStyle.SellingFeatureIcon src="../images/iphone.svg" />
          <div>{t("Taping/recording requests")}</div>
        </Title>
      </TitleWrapper>
      <SharedStyle.SellingFeatureP>
        {t(
          "With a few clicks request self-tapes from your talent using our powerful and integrated taping app, “Actor App”. Self-tapes arrive in your dashboard for approval and forwarding to casting everywhere. Say bye-bye to upside down videos and unformatted audio files."
        )}
      </SharedStyle.SellingFeatureP>
      <TitleWrapper>
        <Title>
          <SharedStyle.SellingFeatureIcon src="../images/user_big_outlined.svg" />
          <div>{t("Your Talent benefits")}</div>
        </Title>
      </TitleWrapper>
      <SharedStyle.SellingFeatureP>
        {t(
          "Your talent can upload 90 minutes of demo reels and clips for one low flat fee per year and no add-on or update fees. Your talent also receives a free, powerful self-taping and pitching app “Actor App” with their membership."
        )}
      </SharedStyle.SellingFeatureP>
    </>
  );
};

export default withI18n()(AgentSellingFeatures);

const TitleWrapper = styled.div`
  width: 100%;
`;

const MainTile = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  color: #0a1f44 !important;
  margin-bottom: 12px;
`;

const Title = styled.div`
  display: flex;
  font-size: 21px;
  font-weight: 600;
  line-height: 29px;
  color: #364866 !important;
  margin-bottom: 12px;
`;

const StyledP = styled.p`
  font-size: 14px;
  color: #364866 !important;
  line-height: 25px;
`;
