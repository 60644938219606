import React from "react";
import styled, { css } from "styled-components";

interface Props {
  className?: string;
  as?: "h1" | "p";
  variant?: "h1" | "p";
}

const Text: React.FC<Props> = ({ children, className, variant = "p" }) => {
  switch (variant) {
    case "h1":
    return (<StyledH1 className={className}>{children}</StyledH1>)
    case "p":
    default:
      return (<StyledP className={className}>{children}</StyledP>);
  }
};

const common = css`
  margin: 0;
`;

const StyledH1 = styled.h1`
  ${common}
  color: ${p => p.theme.black};
  font-size: ${p => p.theme["xxl-font-size"]};
  font-weight: ${p => p.theme["font-weight-600"]};
`;

const StyledP = styled.p`
  ${common}
  color: #53627C;
`;

export default Text;