import React from 'react';
import styled from 'styled-components';

type Props = {
  id?: string;
  className?: string;
};

const Paper: React.FC<Props> = ({
  children,
  id,
  className
}) => {
  const props = { id, className };

  return (
    <StyledDiv {...props}>
      {children}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background-color: ${p => p.theme.palette.common.white};
  box-shadow: 0 2px 4px ${p => p.theme.palette.misc.boxShadow};
`;

export default Paper;
