import React, { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import { Button, ButtonGroup, Typography } from "../Common";
import { Spinner } from "../Common/StyledComponents";
import qs from "query-string";
// @ts-ignore
import { CWBModal } from "cwb-react";
import { iRootState } from "../../store";
import { connect } from "react-redux";

interface IProps extends StateProps, RouteComponentProps {
  t: any;
  history: any;
  location: any;
  isLoading: boolean;
  onClose: () => void;
  handleUpgrade: () => void;
  toHomePage: () => void;
}

const NoTrialForCountryModal: React.FC<IProps> = ({
  t,
  history,
  location,
  onClose: handleClose,
  handleUpgrade,
  toHomePage,
  isLoading,
}) => {
  return (
    <StyledCWBModal
      backdrop="static"
      isOpen={true}
      buttons={
        <ButtonGroup>
          <StyledButton onClick={handleUpgrade}>
            {t("Continue")}
            {isLoading && (
              <StyledSpinner
                src="/images/spinner.svg"
                className="spinner-width"
              />
            )}
          </StyledButton>
          <Button variant="secondary" onClick={toHomePage}>
            {t("Cancel")}
          </Button>
        </ButtonGroup>
      }
      hideModal={handleClose}
    >
      <Typography align="center" component="h3" variant="h3">
        {t(
          "Sorry, but the country you selected do not offer a trial at this time."
        )}
      </Typography>
      <br />
      <Typography color="medGrey" component="h5" align="center" >
        {t(
          "Choose continue to go on a free or paid subscription for this country."
        )}
        <br />
        {t("Or choose cancel to return to the home page.")}
      </Typography>
      &nbsp;
    </StyledCWBModal>
  );
};

const StyledCWBModal = styled(CWBModal)`
  & .modal-header {
    justify-content: space-between;
  }
  & .modal-body {
    text-align: left;
  }
  & .modal-footer .modal-buttons {
    justify-content: flex-start;
    margin: ${(p) => p.theme.spacing(0, 0, 1)};
  }
  border-top: 6px solid #00aaff;
  top: 150px;
  max-width: 600px !important;
`;

interface StateProps {
  isLoading: boolean;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    isLoading: state.actorModel.isLoading,
  };
}

const StyledButton = styled(Button)`
  width: 100%;
  height: 37px;
  font-size: 13px;
  font-weight: 600;
`;

const StyledSpinner = styled(Spinner)`
  width: 16px !important;
`;
export default withI18n()(
  withRouter(connect(mapStateToProps)(NoTrialForCountryModal))
);
