import { createModel } from "@rematch/core";
import * as ServicesAPI from "../shared/api/Services.api";
import * as ReferencesApi from "../shared/api/References.api";
import { IPremiumFeatureComparison } from "../shared/api/dtos/IPremiumDto";
// @ts-ignore
import { getCurUserId } from "cwb-react";
import { getCountryCode, getUserActorSignUpId, setCountryCode, toFixedTrunc } from "../helpers";
import { ISubscriptionService, ISubscriptionDiscountService, IProductSubscriptionService } from "../shared/api/dtos/IServicesDto";
// @ts-ignore
import * as Sentry from "@sentry/react";
import { getI18n } from "react-i18next";

const premiumModel = createModel({
  state: {
    monthlyCharge: 0,
    yearlyCharge: 0,
    yearlyPerMonthCharge: 0,
    headshot: {
      free: 0,
      premium: 0,
    } as IPremiumFeatureComparison,
    resume: {
      free: 0,
      premium: 0,
    } as IPremiumFeatureComparison,
    mediaLimit: 0,
    currencySymbol: "$",
    postCurrencySymbol: '',
    bdSubmissionCharge: 2.99,
    isJobSubmissionFree: false
  },
  reducers: {
    updateMonthlyCharge: (state, data) => {
      return { ...state, monthlyCharge: data };
    },
    updateYearlyCharge: (state, data) => {
      return { ...state, yearlyCharge: data };
    },
    updateYearlyPerMonthCharge: (state, data) => {
      return { ...state, yearlyPerMonthCharge: data };
    },
    updateHeadshot: (state, data: IPremiumFeatureComparison) => {
      return { ...state, headshot: data };
    },
    updateResume: (state, data: IPremiumFeatureComparison) => {
      return { ...state, resume: data };
    },
    updateMediaLimit: (state, data) => {
      return { ...state, mediaLimit: data };
    },
    updateCurrencySymbol: (state, data) => {
      return { ...state, currencySymbol: data };
    },
    updateBdSubmissionCharge: (state, data) => {
      return { ...state, bdSubmissionCharge: data}
    },
    updatePostCurrencySymbol: (state, data) => {
      return { ...state, postCurrencySymbol: data}
    },
    updateIsJobSubmissionFree: (state, data) => {
      return { ...state, isJobSubmissionFree: data}
    }
  },
  effects: (dispatch: any) => ({
    async getSubscriptionServices() {

      let freemium: ISubscriptionService;
      let premiumYearly: ISubscriptionService;
      let premiumMonthly: ISubscriptionService;
      let bdSubmission: ISubscriptionService;

      let clientId = getCurUserId();
      let actorSignupId = getUserActorSignUpId();
      let currencySymbol = "$";
      let postCurrencySymbol = "";
      
      let countryCode = getCountryCode();
      if(!countryCode) {
        try {
          const resp = await ReferencesApi.getRequesterCountryCode();
          const code = resp.data.countryCode;
          setCountryCode(code);
          countryCode = code;
        } catch (e) {
          Sentry.captureException(e);
          console.log(e);
          let fallbackCountry = "CA";
          if(getI18n().language === "es") { 
            fallbackCountry = "ES";
          }
          setCountryCode(fallbackCountry);
          countryCode = fallbackCountry?.toUpperCase();
        }
      }

      if (clientId || actorSignupId) {
        try {
          let paidServices = [] as ISubscriptionService[];

          
          if (clientId) {
            paidServices = (await ServicesAPI.getPaidServicesClient()).data;
            freemium = (await ServicesAPI.getFreeServiceClient()).data;
            bdSubmission = (await ServicesAPI.getBreakdownSubmissionClientService()).data
          } else {
            paidServices = (
              await ServicesAPI.getPaidServicesSignup(actorSignupId)
            ).data;
            freemium = (await ServicesAPI.getFreeServiceSignup(actorSignupId))
              .data;
            bdSubmission = (await ServicesAPI.getPaidBdSubmissionSignup(actorSignupId))
              .data;
          }

          premiumMonthly = paidServices.find((s) => s.durationMonths === 1);
          premiumYearly = paidServices.find((s) => s.durationMonths === 12);
          if (premiumYearly && premiumYearly.currency === "EUR") currencySymbol = "€";
          else currencySymbol = "$";
          if (premiumYearly && premiumYearly.countryCode == "MX") postCurrencySymbol = premiumYearly.currency;
          else postCurrencySymbol = "";

        } catch (e) {
          try {
            const {
              data: services,
            } = await ServicesAPI.getSubscriptionsServices();

            freemium = services.find((s) => s.isFreemium);
            premiumYearly = services.find((s) => {
              return (
                !s.discountAge && !s.isVoiceOnly && s.durationMonths === 12
              );
            });
            premiumMonthly = services.find((s) => {
              return !s.discountAge && !s.isVoiceOnly && s.durationMonths === 1;
            });
          } catch (e) {
            throw e;
          }
        }
      } else {
        try {
          const {
            data: services,
          } = await ServicesAPI.getSubscriptionsServices();

          freemium = services.find((s) => s.isFreemium);
          const premiumYearlyMatches = services.filter((s) => {
            return !s.discountAge && !s.isVoiceOnly && s.durationMonths === 12;
          });
          premiumYearly = premiumYearlyMatches.find((s) => {
            return countryCode === s.countryCode;
          }) || premiumYearlyMatches[0];
          const premiumMonthlyMatches = services.filter((s) => {
            return !s.discountAge && !s.isVoiceOnly && s.durationMonths === 1;
          });
          premiumMonthly = premiumMonthlyMatches.find((s) => {
            return countryCode === s.countryCode;
          }) || premiumMonthlyMatches[0];

          if (premiumYearly && premiumYearly.currency === "EUR") currencySymbol = "€";
          else currencySymbol = "$";
          if (premiumYearly && premiumYearly.countryCode == "MX") postCurrencySymbol = premiumYearly.currency;
          else postCurrencySymbol = "";
        } catch (e) {
          throw e;
        }
      }

      dispatch.premiumModel.updateYearlyCharge(premiumYearly.price);
      dispatch.premiumModel.updateMonthlyCharge(
        premiumMonthly
          ? premiumMonthly.price
          : Number((premiumYearly.price / 12).toFixed(2))
      );
      dispatch.premiumModel.updateYearlyPerMonthCharge(
        Number((premiumYearly.price / 12).toFixed(2))
      );
      dispatch.premiumModel.updateHeadshot({
        free: freemium.numberAllowedPhoto,
        premium: premiumYearly.numberAllowedPhoto,
      });
      dispatch.premiumModel.updateResume({
        free: freemium.numberAllowedResume,
        premium: premiumYearly.numberAllowedResume,
      });
      dispatch.premiumModel.updateMediaLimit(
        premiumYearly.numberAllowedAudio + premiumYearly.numberAllowedVideo
      );
      dispatch.premiumModel.updateCurrencySymbol(currencySymbol);
      if (bdSubmission?.price)
        dispatch.premiumModel.updateBdSubmissionCharge(bdSubmission.price);
      if (bdSubmission?.isJobSubmissionFree)
        dispatch.premiumModel.updateIsJobSubmissionFree(bdSubmission.isJobSubmissionFree)

      dispatch.premiumModel.updatePostCurrencySymbol(postCurrencySymbol);

    },
    async getSubscriptionServicesCWBlabs(code:string) {

      let premiumYearly: ISubscriptionService;
      let premiumMonthly: ISubscriptionService;

      let currencySymbol = "$";
      let postCurrencySymbol = "";

        try {
          let paidServices = [] as ISubscriptionService[];
          paidServices = (await ServicesAPI.getPaidServicesCWBlabs(code)).data;

          premiumMonthly = paidServices.find((s) => s.durationMonths === 1);
          premiumYearly = paidServices.find((s) => s.durationMonths === 12);

          if (premiumYearly && premiumYearly.currency === "EUR") currencySymbol = "€";
          else currencySymbol = "$";
          if (premiumYearly && premiumYearly.countryCode == "MX") postCurrencySymbol = premiumYearly.currency;
          else postCurrencySymbol = "";
        } catch (e) {
          throw e;
        }

      
      dispatch.premiumModel.updateYearlyCharge(premiumYearly.price);
      dispatch.premiumModel.updateMonthlyCharge(
        premiumMonthly
          ? premiumMonthly.price
          : Number((premiumYearly.price / 12).toFixed(2))
      );
      dispatch.premiumModel.updateYearlyPerMonthCharge(
        Number((premiumYearly.price / 12).toFixed(2))
      );
      dispatch.premiumModel.updateHeadshot({
        premium: premiumYearly.numberAllowedPhoto,
      });
      dispatch.premiumModel.updateResume({
        premium: premiumYearly.numberAllowedResume,
      });
      dispatch.premiumModel.updateMediaLimit(
        premiumYearly.numberAllowedAudio + premiumYearly.numberAllowedVideo
      );
      dispatch.premiumModel.updateCurrencySymbol(currencySymbol);
      dispatch.premiumModel.updatePostCurrencySymbol(postCurrencySymbol);
    },
    async getDiscountSubscriptionServices(promo:string) {

      let freemium: ISubscriptionService;
      let bdSubmission: ISubscriptionService;
      let premiumYearly: ISubscriptionDiscountService;
      let premiumMonthly: ISubscriptionService;

      let clientId = getCurUserId();
      let actorSignupId = getUserActorSignUpId();
      let currencySymbol = "$";
      let postCurrencySymbol = "";
      let promoDiscountedPrice = 0;

      
      let countryCode = getCountryCode();
      if(!countryCode) {
        try {
          const resp = await ReferencesApi.getRequesterCountryCode();
          const code = resp.data.countryCode;
          setCountryCode(code);
          countryCode = code;
        } catch (e) {
          Sentry.captureException(e);
          console.log(e);
          let fallbackCountry = "CA";
          if(getI18n().language === "es") fallbackCountry = "ES";
          setCountryCode(fallbackCountry);
          countryCode = fallbackCountry;
        }
      }

      if (clientId || actorSignupId) {
        try {
          let paidServices = [] as ISubscriptionDiscountService[];

          if (clientId) {
            paidServices = (await ServicesAPI.getPaidServicesClient(promo)).data;
            freemium = (await ServicesAPI.getFreeServiceClient()).data;
            bdSubmission = (await ServicesAPI.getBreakdownSubmissionClientService(promo)).data;
          } else {
            paidServices = (
              await ServicesAPI.getPaidServicesSignup(actorSignupId, promo)
            ).data;
            freemium = (await ServicesAPI.getFreeServiceSignup(actorSignupId))
              .data;
            bdSubmission = (await ServicesAPI.getPaidBdSubmissionSignup(actorSignupId, promo))
              .data;
          }
          
          premiumMonthly = paidServices.find((s) => s.durationMonths === 1);
          premiumYearly = paidServices.find((s) => s.durationMonths === 12);
      
          promoDiscountedPrice = premiumYearly.promoDiscountedPrice??premiumYearly.price;
          
          if (premiumYearly && premiumYearly.currency === "EUR") currencySymbol = "€";
          else currencySymbol = "$";
          if (premiumYearly && premiumYearly.countryCode == "MX") postCurrencySymbol = premiumYearly.currency;
          else postCurrencySymbol = "";
        } catch (e) {
          Sentry.captureException(e)
          try {
            const {
              data: services,
            } = await ServicesAPI.getSubscriptionsServices();

            freemium = services.find((s) => s.isFreemium);
            premiumYearly = services.find((s) => {
              return (
                !s.discountAge && !s.isVoiceOnly && s.durationMonths === 12
              );
            });
            premiumMonthly = services.find((s) => {
              return !s.discountAge && !s.isVoiceOnly && s.durationMonths === 1;
            });
          } catch (e) {
            throw e;
          }
        }
      } else {
        try {
          const {
            data: services,
          } = await ServicesAPI.getSubscriptionsServices();

          freemium = services.find((s) => s.isFreemium);
          const premiumYearlyMatches = services.filter((s) => {
            return !s.discountAge && !s.isVoiceOnly && s.durationMonths === 12;
          });
          premiumYearly = premiumYearlyMatches.find((s) => {
            return countryCode === s.countryCode;
          }) || premiumYearlyMatches[0];
          const premiumMonthlyMatches = services.filter((s) => {
            return !s.discountAge && !s.isVoiceOnly && s.durationMonths === 1;
          });
          premiumMonthly = premiumMonthlyMatches.find((s) => {
            return countryCode === s.countryCode;
          }) || premiumMonthlyMatches[0];

          if (premiumYearly && premiumYearly.currency === "EUR") currencySymbol = "€";
          if (premiumYearly && premiumYearly.countryCode === "MX") postCurrencySymbol = premiumYearly.currency;
        } catch (e) {
          throw e;
        }
      }
      
      const yearly = promoDiscountedPrice;
      dispatch.premiumModel.updateYearlyCharge(yearly.toFixed(2));
      dispatch.premiumModel.updateMonthlyCharge(
        premiumMonthly
          ? premiumMonthly.price
          : Number((yearly / 12).toFixed(2))
      );
      dispatch.premiumModel.updateYearlyPerMonthCharge(
        Number((yearly / 12).toFixed(2))
      );
      dispatch.premiumModel.updateHeadshot({
        free: freemium.numberAllowedPhoto,
        premium: premiumYearly.numberAllowedPhoto,
      });
      dispatch.premiumModel.updateResume({
        free: freemium.numberAllowedResume,
        premium: premiumYearly.numberAllowedResume,
      });
      dispatch.premiumModel.updateMediaLimit(
        premiumYearly.numberAllowedAudio + premiumYearly.numberAllowedVideo
      );
      dispatch.premiumModel.updateCurrencySymbol(currencySymbol);

      if (bdSubmission?.price)
        dispatch.premiumModel.updateBdSubmissionCharge(bdSubmission.price);
      if (bdSubmission?.isJobSubmissionFree)
        dispatch.premiumModel.updateIsJobSubmissionFree(bdSubmission.isJobSubmissionFree)

      dispatch.premiumModel.updatePostCurrencySymbol(postCurrencySymbol);
    },
    async getSubscriptionServicesWithCountryCode(countryCode:string) {

      let freemium: ISubscriptionService;
      let bdSubmission: ISubscriptionService;
      let premiumYearly: ISubscriptionService;
      let premiumMonthly: ISubscriptionService;

      let clientId = getCurUserId();
      let actorSignupId = getUserActorSignUpId();
      let currencySymbol = "$";
      let postCurrencySymbol = "";

      if (clientId || actorSignupId) {
        try {
          let paidServices = [] as ISubscriptionService[];

          if (clientId) {
            paidServices = (await ServicesAPI.getPaidServicesClient()).data;
            freemium = (await ServicesAPI.getFreeServiceClient()).data;
            bdSubmission = (await ServicesAPI.getBreakdownSubmissionClientService()).data;
          } else {
            paidServices = (
              await ServicesAPI.getPaidServicesSignup(actorSignupId)
            ).data;
            freemium = (await ServicesAPI.getFreeServiceSignup(actorSignupId))
              .data;
            bdSubmission = (await ServicesAPI.getPaidBdSubmissionSignup(actorSignupId))
              .data;
          }

          premiumMonthly = paidServices.find((s) => s.durationMonths === 1);
          premiumYearly = paidServices.find((s) => s.durationMonths === 12);
          if (premiumYearly && premiumYearly.currency === "EUR") currencySymbol = "€";
          if (premiumYearly && premiumYearly.countryCode === "MX") postCurrencySymbol = premiumYearly.currency;

        } catch (e) {
          Sentry.captureException(e)
          try {
            const {
              data: services,
            } = await ServicesAPI.getSubscriptionsServices();

            freemium = services.find((s) => s.isFreemium);
            premiumYearly = services.find((s) => {
              return (
                !s.discountAge && !s.isVoiceOnly && s.durationMonths === 12
              );
            });
            premiumMonthly = services.find((s) => {
              return !s.discountAge && !s.isVoiceOnly && s.durationMonths === 1;
            });
          } catch (e) {
            throw e;
          }
        }
      } else {
        try {
          const {
            data: services,
          } = await ServicesAPI.getSubscriptionsServices();

          freemium = services.find((s) => s.isFreemium);
          const premiumYearlyMatches = services.filter((s) => {
            return !s.discountAge && !s.isVoiceOnly && s.durationMonths === 12;
          });
          premiumYearly = premiumYearlyMatches.find((s) => {
            return countryCode === s.countryCode;
          }) || premiumYearlyMatches[0];
          const premiumMonthlyMatches = services.filter((s) => {
            return !s.discountAge && !s.isVoiceOnly && s.durationMonths === 1;
          });
          premiumMonthly = premiumMonthlyMatches.find((s) => {
            return countryCode === s.countryCode;
          }) || premiumMonthlyMatches[0];

          if (premiumYearly && premiumYearly.currency === "EUR") currencySymbol = "€";
          else currencySymbol = "$";
          if (premiumYearly && premiumYearly.countryCode == "MX") postCurrencySymbol = premiumYearly.currency;
          else postCurrencySymbol = "";
        } catch (e) {
          throw e;
        }
      }

      dispatch.premiumModel.updateYearlyCharge(premiumYearly.price);
      dispatch.premiumModel.updateMonthlyCharge(
        premiumMonthly
          ? premiumMonthly.price
          : Number((premiumYearly.price / 12).toFixed(2))
      );
      dispatch.premiumModel.updateYearlyPerMonthCharge(
        Number((premiumYearly.price / 12).toFixed(2))
      );
      dispatch.premiumModel.updateHeadshot({
        free: freemium.numberAllowedPhoto,
        premium: premiumYearly.numberAllowedPhoto,
      });
      dispatch.premiumModel.updateResume({
        free: freemium.numberAllowedResume,
        premium: premiumYearly.numberAllowedResume,
      });
      dispatch.premiumModel.updateMediaLimit(
        premiumYearly.numberAllowedAudio + premiumYearly.numberAllowedVideo
      );
      dispatch.premiumModel.updateCurrencySymbol(currencySymbol);
      if (bdSubmission?.price)
        dispatch.premiumModel.updateBdSubmissionCharge(bdSubmission.price);
      if (bdSubmission?.isJobSubmissionFree)
        dispatch.premiumModel.updateIsJobSubmissionFree(bdSubmission.isJobSubmissionFree)
      
      dispatch.premiumModel.updatePostCurrencySymbol(postCurrencySymbol);
    },


    async getProductSubscriptionServices(service:number) {
      /* let countryCode = "CA"; */

      let freemium: ISubscriptionService;
      let premiumYearly: IProductSubscriptionService;

      let clientId = getCurUserId();
      let currencySymbol = "$";
      let postCurrencySymbol = "";;

      if (clientId) {
        try {
          let paidServices : IProductSubscriptionService;

          if (clientId) {
            paidServices = (await ServicesAPI.getProductServicesClient(service)).data;
            freemium = (await ServicesAPI.getFreeServiceClient()).data;
          }

          if(paidServices.canUserAccess){
            premiumYearly = paidServices;
            if (premiumYearly.currency === "EUR") currencySymbol = "€";
            if (premiumYearly.countryCode == "MX") postCurrencySymbol = premiumYearly.currency;
          }
          else {
            throw new Error(`ClientId: ${clientId} do not have access to service: ${service}`)
          }
        } catch (e) {
          Sentry.captureException(e)
          console.log('no service response', e);
        }
      } else {
        console.log('no clientId')
      }

      dispatch.premiumModel.updateYearlyCharge(premiumYearly.price);
      dispatch.premiumModel.updateMonthlyCharge(Number((premiumYearly.price / 12).toFixed(2))
      );
      dispatch.premiumModel.updateYearlyPerMonthCharge(
        Number(toFixedTrunc((premiumYearly.price / 12),2))
      );
      dispatch.premiumModel.updateHeadshot({
        free: freemium.numberAllowedPhoto,
        premium: premiumYearly.numberAllowedPhoto,
      });
      dispatch.premiumModel.updateResume({
        free: freemium.numberAllowedResume,
        premium: premiumYearly.numberAllowedResume,
      });
      dispatch.premiumModel.updateMediaLimit(
        premiumYearly.numberAllowedAudio + premiumYearly.numberAllowedVideo
      );
      dispatch.premiumModel.updateCurrencySymbol(currencySymbol);
      dispatch.premiumModel.updatePostCurrencySymbol(postCurrencySymbol);
    },
  }),
  
});

export default premiumModel;
