export interface IHttpResponse<T> extends Response {
  data?: T;
}

export const http = <T>(request: RequestInfo): Promise<IHttpResponse<T>> => {
  return new Promise((resolve, reject) => {
    let response: IHttpResponse<T>;
    fetch(request)
      .then(res => {
        response = res;
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          return {} as IHttpResponse<T>;
        }
        return res.json();
      })
      .then(body => {
        if (response.ok) {
          response.data = body;
          resolve(response);
        } else {
          reject(body);
        }
      })
      .catch(err => {
        console.error(err);
        reject(err);
      });
  });
};

export const get = async <T>(
  path: string,
  args: RequestInit = {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    credentials: 'include',
    headers: {
      CWB: '1'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
): Promise<IHttpResponse<T>> => {
  return await http<T>(new Request(path, args));
};

export const post = async <T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: "post",
    mode: "cors",
    credentials: "include",
    // cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      CWB: "1"
    },
    body: JSON.stringify(body)
  }
): Promise<IHttpResponse<T>> => {
  return await http<T>(new Request(path, args));
};

export const put = async <T>(
  path: string,
  body: any,
  args: RequestInit = { method: "put", body: JSON.stringify(body) }
): Promise<IHttpResponse<T>> => {
  return await http<T>(new Request(path, args));
};
