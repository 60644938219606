import React from "react";
import qs from "query-string";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";

import {
  getCurUserSignUp,
  getSessionId,
  getCountryCode,
  setRegionCode,
} from "../../../../../helpers/index";
// @ts-ignore
import { CWBDropdown } from "cwb-react";
import { withRouter } from "react-router-dom";

import { iRootState } from "../../../../../store";
import BackButton from "../../../../Common/BackButton";
import Footer from "../../../../Common/Footer";
import Modal from "../../../../Common/Modal";
import PageContainer from "../../../../Common/PageContainer";
import {
  ErrorText,
  Section,
  SectionTitle,
  LargeText,
  Spinner,
} from "../../../../Common/StyledComponents";
import Typography from "../../../../Common/Typography";
import {
  IActorFilmCenter,
  IActorError,
  IActorAccountDto,
} from "../../../../../shared/api/dtos/IActorDto";
import { IGroupedCountry } from "../../../../../helpers/ICountryList";
import Button from "../../../../Common/Button";
import * as ActorJobsApi from "../../../../../shared/api/ActorJobs.api";
import ResidenceModal from "../../../../Modals/residenceModal";
import * as ServicesAPI from "../../../../../shared/api/Services.api";
import NoTrialForCountryModal from "../../../../Modals/NoTrialForCountryModal";
import {
  getUserActorSignUpId,
  timedSentrySendThenErrorPage,
} from "../../../../../helpers";
import { IHttpResponse } from "shared/http";

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location?: any;
}

interface IState {
  fields: any;
  isFormSubmitClicked: boolean;
  cityList: any[];
  residenceModalOpen: boolean;
  residenceChecking: boolean;
  hasValidTrialCountry: boolean;
  openModalHasValidTrialCountry: boolean;
}

class ActorTrialFilmCenterStep extends React.Component<IProps, IState> {
  public state: IState = {
    fields: {
      onCamera: true,
      voice: true,
      country: "",
      province: "",
      provinceId: 1,
      city: "",
      cityId: 1,
      countryId: 1,
    },
    isFormSubmitClicked: false,
    cityList: [],
    residenceModalOpen: false,
    residenceChecking: false,
    hasValidTrialCountry: false,
    openModalHasValidTrialCountry: false,
  };

  async componentDidMount() {
    if (!this.props.countryCode && !getCountryCode()) {
      const qsArgs = this.props.location.search ? { ...qs.parse(this.props.location.search) } as any : {};
      if (qsArgs.dev && qsArgs.ctry) {
        await this.props.setForceRequesterCountryCode(qsArgs.ctry)
      } else {
        try {
          await this.props.setRequesterCountryCode();
        } catch (e) {
          console.error(e);
        }
      }
    }

    const user: IActorAccountDto = getCurUserSignUp();
    if (getSessionId() && user) {
      if (this.props.groupCountryList.length <= 0)
        await this.props.getGroupCountryList();

      if (user.actorFilmCenter) this.mapUserToStateFields(user.actorFilmCenter);
      else {
        const grpCountries = this.props.groupCountryList;
        let country = grpCountries[0];

        // To test below code. Install Opera. In settings, turn on VPN.
        if (getCountryCode() && getCountryCode()?.toUpperCase() !== "CA") {
          const selectedCountry = grpCountries.find(
            (x) => x.country.countryCode === getCountryCode()?.toUpperCase()
          );
          if (selectedCountry) {
            country = selectedCountry;
          }
        }

        this.setCountry(country);
        const regionCode = country!.country!.locationCode || country!.country!.countryCode;
        setRegionCode(regionCode);
      }
    } else {
      window.location.replace("/");
    }
  }

  mapUserToStateFields = (userfilmCenter: IActorFilmCenter) => {
    if (!userfilmCenter) return;
    let fields: IActorFilmCenter = this.state.fields;

    fields.province = userfilmCenter.province;
    fields.provinceId = userfilmCenter.provinceId;
    fields.city = userfilmCenter.city;
    fields.cityId = userfilmCenter.cityId;
    fields.countryId = userfilmCenter.countryId;
    fields.onCamera = userfilmCenter ? userfilmCenter.onCamera : false;
    fields.voice = userfilmCenter ? userfilmCenter.voice : false;

    this.setState({ fields });

    const grpCountries = this.props.groupCountryList;
    const country = grpCountries.find((x) => x.id === fields.countryId);
    this.setCountry(country);
    const regionCode =
      country!.country!.locationCode || country!.country!.countryCode;
    setRegionCode(regionCode);
  };

  checkValidation(): boolean {
    const { t } = this.props;
    let fields: IActorFilmCenter = this.state.fields;
    let errors: IActorError = {} as IActorError;

    //projectTypeToCast
    if (!fields.onCamera && !fields.voice)
      errors.OnCamera = t("Select at least one type");

    //country
    if (!fields.country) errors.Country = t("Country field is required");

    //city
    if (!fields.city) errors.City = t("City field is required");

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  setCountry = (option: any) => {
    let fields: IActorFilmCenter = this.state.fields;
    try {
      const cityList = option.cityList
        ? option.cityList.map((city: any) => ({
          label: city.cityName,
          value: city.cityName,
          id: city.cityId,
          city,
        }))
        : [];
      fields.country = option.value;
      fields.countryId = option.id;
      this.setState({ fields });
      this.setState({ cityList });
    } catch (err) {
      // nothing needed here... an unhandled error sometimes occurs if the country.cities are not loaded yet and the user click the city dropdown
    }
  };

  handleCity = (option: any) => {
    let fields: IActorFilmCenter = this.state.fields;
    fields.city = option.value;
    fields.cityId = option.id;
    fields.provinceId = option.city!.provinceId;
    fields.province = option.city!.provinceName;
    this.setState({ fields });
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  handleCountry = (option: any) => {
    let fields: IActorFilmCenter = this.state.fields;
    fields.country = option.value;
    fields.countryId = option.id;
    fields.provinceId = option.country!.provinceId;
    fields.province = option.country!.provinceName;
    this.setState({ fields });
    this.setCountry(option);
    const regionCode =
      option!.country!.locationCode || option!.country!.countryCode;
    setRegionCode(regionCode);
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  checkValidTrialCountry = async () => {
    const selectedCountry = this.getCountry();
    const selectedCountryCode = selectedCountry!.country!.countryCode;
    if (!selectedCountryCode) {
      this.setState({
        hasValidTrialCountry: false,
        openModalHasValidTrialCountry: true,
      });
      return { continue: false, trial: null };
    }
    let isDev = false;
    if (qs.parse(this.props.location.search).dev === "1") isDev = true;
    const ServiceResponse = await ServicesAPI.getTrialService(
      selectedCountryCode,
      isDev
    );
    if (
      ServiceResponse.status === 200 &&
      ServiceResponse.data &&
      ServiceResponse.data.serviceId
    ) {
      this.setState({
        hasValidTrialCountry: true,
        openModalHasValidTrialCountry: false,
      });
      this.props.setTrialServicesWorkflow(true);
      return { continue: true, trial: true };
    } else {
      if (this.props.trialServicesWorkflow) {
        this.setState({
          hasValidTrialCountry: false,
          openModalHasValidTrialCountry: true,
        });
        return { continue: false, trial: true };
      }
      return { continue: true, trial: false };
    }
  };

  // here is the key to victory! no chg needed in code trial
  saveAccountSignUp = async (e: any) => {
    if (!this.props.isLoading && !this.state.residenceChecking) {
      const { location } = this.props;
      e.preventDefault();
      this.setState({ isFormSubmitClicked: true });
      if (!this.checkValidation()) return;
      const qsArgs = qs.parse(location.search);
      if (qsArgs.bd && qsArgs.bd.length > 4) {
        this.setState({
          residenceChecking: true,
        });
        this.checkResidenceArea();
      } else {
        const hasValidTrialCountry = await this.checkValidTrialCountry();
        if (!hasValidTrialCountry.continue) return;

        if (hasValidTrialCountry.trial)
          this.continueTrailSaveup();
        else
          this.continueFreemiumSave();
      }
    }
  };

  continueTrailSaveup = async () => {
    this.props.setResidenceNotMatch(false);
    let user: IActorAccountDto = getCurUserSignUp();
    user.actorFilmCenter = this.state.fields;
    const qsResult = qs.parse(window.location.search)
    await this.props.createTrialSignUp({ ...this.state.fields, dev: qsResult.dev });
    this.moveToNext();
  };

  continueFreemiumSave = async () => {
    this.props.setResidenceNotMatch(false);
    this.props.setIsLoading(true);
    try {
      let user: IActorAccountDto = getCurUserSignUp();
      user.actorFilmCenter = this.state.fields;

      const qsResult = qs.parse(window.location.search)
      user.dev = qsResult.dev as string;

      await this.props.saveActorSignUp(user);

      this.props.setIsLoading(true);
      try {
        const response = await this.props.createFreemiumUser(getUserActorSignUpId());
        this.props.setClientId(response.data.clientId);
        this.props.setIsLoading(false);
        this.props.history.push({
          pathname: "/actor/free-completed",
          search: this.props.location.search,
        });
      } catch (ex2) {
        timedSentrySendThenErrorPage(ex2);
      } finally {
        this.props.setIsLoading(false);
      }
    }
    catch (ex) {
      timedSentrySendThenErrorPage(ex);
    }
    finally {
      this.props.setIsLoading(false);
    }
  };

  continueSaveupNotMatch = async () => {
    const hasValidTrialCountry = await this.checkValidTrialCountry();
    if (!hasValidTrialCountry.continue) {
      this.setState({
        residenceModalOpen: false,
        residenceChecking: false,
      });
      return;
    }

    this.props.setResidenceNotMatch(true);
    let user: IActorAccountDto = getCurUserSignUp();
    user.actorFilmCenter = this.state.fields;
    if (!this.props.isLoading) {
      if (hasValidTrialCountry.trial) {
        const qsResult = qs.parse(window.location.search);
        await this.props.createTrialSignUp({ ...this.state.fields, dev: qsResult.dev });
        this.moveToNext();
      } else {
        await this.continueFreemiumSave();
      }
    }
  };

  continueSaveupNonTrial = async (nextPath: string) => {
    this.props.setResidenceNotMatch(false);
    let user: IActorAccountDto = getCurUserSignUp();
    user.actorFilmCenter = this.state.fields;

    const qsResult = qs.parse(window.location.search);
    user.dev = qsResult.dev as string;

    await this.props.saveActorSignUp(user);
    this.moveToNext(nextPath);
  };

  checkResidenceArea = async () => {
    const { location } = this.props;
    const { fields } = this.state;
    const { cityId } = fields;
    const bdId = qs.parse(location.search).bd;
    const sessionId = getSessionId();
    const page = qs.parse(location.search).page;
    try {
      const filterResponse = await ActorJobsApi.getFilterResult(
        sessionId,
        bdId,
        cityId,
        page
      );
      if (
        filterResponse.status === 200 &&
        filterResponse.data.countryMismatch
      ) {
        this.setState({
          residenceModalOpen: true,
          residenceChecking: false,
        });
      } else {
        this.setState({
          residenceChecking: false,
        });
        const hasValidTrialCountry = await this.checkValidTrialCountry();
        if (!hasValidTrialCountry.continue) return;

        if (hasValidTrialCountry.trial)
          this.continueTrailSaveup();
        else
          this.continueFreemiumSave();
      }
    } catch (err) {
      this.setState({
        residenceChecking: false,
      });
      const hasValidTrialCountry = await this.checkValidTrialCountry();
      if (!hasValidTrialCountry.continue) return;

      if (hasValidTrialCountry.trial)
        this.continueTrailSaveup();
      else
        this.continueFreemiumSave();
    }
  };

  moveToNext = (nextPath?: string) => {
    if (this.props.errors.errorMessage)
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);

    if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      if (nextPath) {
        this.props.history.push({
          pathname: nextPath,
          search: this.props.location.search,
        });
      } else {
        this.props.history.push({
          pathname: "/actor/free-trial/completed",
          search: this.props.location.search,
        });
      }
    }
  };

  toHomePage = () => {
    const { location } = this.props;
    const bdId = qs.parse(location.search).bd;
    window.location.href = `${process.env.REACT_APP_CWB_Site}/open-breakdown.asp?bd=${bdId}`;
  };

  getCountry = (): IGroupedCountry => {
    const fields: IActorFilmCenter = this.state.fields;
    let country = this.props.groupCountryList.find(
      (x) => x.id === fields.countryId
    );
    if (fields.province && fields.country) return country;

    const qsArgs = qs.parse(this.props.location.search);
    let countryCode = getCountryCode();
    if (countryCode)
      country = this.props.groupCountryList.find(
        (x) => x.value === countryCode || x.value?.toUpperCase() == countryCode.toUpperCase()
      );
    else if (qsArgs.ctry)
      country = this.props.groupCountryList.find(
        (x) => x.value === qsArgs.ctry || x.value?.toUpperCase() == qsArgs.ctry.toString().toUpperCase()
      );

    return country;
  };

  render() {
    const { t } = this.props;
    const errors: IActorError = this.props.errors;
    const fields: IActorFilmCenter = this.state.fields;
    const city = this.state.cityList.find((x) => x.id === fields.cityId);
    const country = this.getCountry();

    return (
      <PageContainer>
        <BackButton width={480} />
        <Wrapper>
          <StyledTypography align="center" component="h1" variant="h2">
            {t("Last Step")}
          </StyledTypography>
          <Section className="margin-bottom-none">
            <LargeText>{t("Film center")}</LargeText>
            <FilmCenterContent>
              {t("Select your closest location from the list below.")}
            </FilmCenterContent>
          </Section>
          <Section className="margin-bottom-none">
            <SectionTitle>{t("Choose your country")}</SectionTitle>
            <CWBDropdown
              value={country}
              isValueSelected={true}
              placeholder={t("Select Country")}
              items={this.props.groupCountryList}
              onChange={(option: any) => this.handleCountry(option)}
            />
            <ErrorText>{errors.Country}</ErrorText>
          </Section>
          <Section className="margin-bottom-none">
            <SectionTitle>{t("Choose your nearest city")}</SectionTitle>
            <CWBDropdown
              name="city"
              value={city || null}
              isValueSelected={true}
              placeholder={t("Select an Option")}
              items={this.state.cityList ? this.state.cityList : []}
              onChange={(option: any) => this.handleCity(option)}
            />
            <ErrorText>{errors.City}</ErrorText>
          </Section>
          <ErrorText>{errors.errorMessage}</ErrorText>
          <Section>
            <StyledButton onClick={(e) => this.saveAccountSignUp(e)}>
              {t("Create my account now")}
              {((!this.state.residenceModalOpen && this.props.isLoading) ||
                this.state.residenceChecking) && (
                  <Spinner src="/images/spinner.svg" className="spinner-width" />
                )}
            </StyledButton>
          </Section>
        </Wrapper>
        <Footer />
        {this.state.residenceModalOpen && (
          <ResidenceModal
            onClose={() =>
              this.setState({
                residenceModalOpen: false,
              })
            }
            handleUpgrade={() => this.continueSaveupNotMatch()}
            toHomePage={this.toHomePage}
          />
        )}
        {this.state.openModalHasValidTrialCountry && (
          <NoTrialForCountryModal
            onClose={() =>
              this.setState({
                openModalHasValidTrialCountry: false,
              })
            }
            handleUpgrade={() => this.continueSaveupNonTrial("/actor/5")}
            toHomePage={this.toHomePage}
          />
        )}
      </PageContainer>
    );
  }
}

interface StateProps {
  errors: IActorError;
  groupCountryList: IGroupedCountry[];
  isLoading: boolean;
  countryCode: string;
  trialServicesWorkflow?: boolean;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    errors: state.actorModel.errors,
    groupCountryList: state.referencesModel.supportedGroupCountryList,
    isLoading: state.actorModel.isLoading,
    countryCode: state.referencesModel.countryCode,
    trialServicesWorkflow: state.actorModel.trialServicesWorkflow
  };
}

interface DispatchProps {
  saveCastingFilmCenter: (fields: any) => void;
  getGroupCountryList: () => void;
  setErrors: (errors: IActorError) => void;
  createTrialSignUp: (dto: IActorFilmCenter) => void;
  setResidenceNotMatch: (status: boolean) => void;
  setRequesterCountryCode: (fallback?: string | null) => void;
  setForceRequesterCountryCode: (countryCode: string) => void;
  saveActorSignUp: (dto: IActorAccountDto) => void;
  setTrialServicesWorkflow: (trialServicesWorkflow: boolean) => void;
  createFreemiumUser: (actorSignUpId: number) => Promise<IHttpResponse<any>>;
  setClientId: (clientId: string) => void;
  setIsLoading: (isLoading: boolean) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    saveCastingFilmCenter: dispatch.actorModel.saveCastingFilmCenter,
    getGroupCountryList: dispatch.referencesModel.getSupportedGroupCountryList,
    setErrors: dispatch.actorModel.setErrors,
    createTrialSignUp: dispatch.actorModel.createTrialSignUp,
    setResidenceNotMatch: dispatch.actorModel.setResidenceNotMatch,
    setRequesterCountryCode: dispatch.referencesModel.setRequesterCountryCode,
    setForceRequesterCountryCode: dispatch.referencesModel.setForceRequesterCountryCode,
    saveActorSignUp: dispatch.actorModel.saveActorSignUp,
    setTrialServicesWorkflow: dispatch.actorModel.setTrialServicesWorkflow,
    createFreemiumUser: dispatch.actorModel.createFreemiumUser,
    setClientId: dispatch.actorModel.setClientId,
    setIsLoading: dispatch.actorModel.setIsLoading
  };
}

export default withI18n()(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ActorTrialFilmCenterStep)
  )
);

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-weight: ${(p) => p.theme["font-weight-600"]};

  max-width: 480px;
  min-width: 480px;
  background-color: ${(p) => p.theme.white};
  padding: 40px 80px;

  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 40px;
  }

  .cwb-dropdown-wrapper {
    margin-top: 8px;
    max-width: 100%;
    height: 40px;

    .cwb-dropdown__single-value {
      font-size: ${(p) => p.theme["xs-font-size"]};
    }

    .cwb-dropdown__indicator {
      width: 14px;
    }
  }

  .cwb-dropdown__placeholder {
    color: ${(p) => p.theme.midGrey};
    font-size: ${(p) => p.theme["xs-font-size"]};
    font-weight: ${(p) => p.theme["font-weight-600"]};
  }

  .cwb-dropdown-wrapper > div {
    min-width: 100%;
    max-width: 100%;
  }

  .cwb-dropdown-wrapper > div .cwb-dropdown__control {
    min-height: 40px;
    border: ${(props) => props.theme.dropDown["border"]} !important;
    border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  }
`;

const StyledTypography = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(3)};
`;

const FilmCenterContent = styled.div`
  color: ${(p) => p.theme.color} !important;
  font-size: ${(p) => p.theme["s-font-size"]};
  font-weight: normal;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 37px;
  font-size: 13px;
  font-weight: 600;
  background-color: ${(p) => p.theme.palette.brand.lightBlue};

  &:hover {
    background-color: ${(p) => p.theme.palette.brand.blue};
  }

  &:active {
    background-color: ${(p) => p.theme.palette.brand.darkBlue};
  }
`;
