import React, { useEffect, useState } from "react";
import qs from "query-string";
import { withI18n } from "react-i18next";
import { connect } from "react-redux";
import { Link as ReactRouterLink, RouteComponentProps } from "react-router-dom";
import { cleanCache, getCountryCode } from "../../../../../helpers/index";
import styled from "styled-components";
import { Typography } from "components/Common";
import ActorCodeSignUpForm from "./ActorCodeSignUpForm";
import Card from "../../../../CWBLabs/components/Card";
import * as ServicesAPI from "../../../../../shared/api/Services.api";
import HeaderComponent from "../../../../HeaderComponent";
// @ts-ignore
import { CWBLoadingCrest } from "cwb-react";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { ITrialServiceData } from "shared/api/dtos/IServicesDto";

type Props = {
  t: any;
} & StateProps &
  DispatchProps &
  RouteComponentProps;

const ActorCodeSignUp: React.FC<Props> = ({
  t,
  location,
  currencySymbol,
  history,
  yearlyPerMonthCharge,
  setServiceCodeError,
  countryCode,
  setRequesterCountryCode,
  setForceRequesterCountryCode,
  setTrialServiceData
}) => {
  const [trialValid, setTrialValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const [expiration, setExpiration] = useState("");
  const [expirationDuration, setExpirationDuration] = useState("");
  const redirectParams = qs.stringify({
    ...qs.parse(location.search),
  });
  const fallbackOnError = "US";
  useEffect(() => {
    cleanCache();
  }, []);
  useEffect(() => {
    let countryCodeCopy = countryCode;
    const qsArgs = qs.parse(location.search)
    if (qsArgs.dev && qsArgs.ctry) {
      countryCodeCopy = qs.parse(location.search).ctry as string;
      setForceRequesterCountryCode(countryCodeCopy);
    }

    if (!countryCodeCopy && !getCountryCode()) {
      (async () => {
        try {
          await setRequesterCountryCode(fallbackOnError);
        } catch (e) {
          console.error(e);
        }
      })();
    }
    if (countryCodeCopy || getCountryCode()) {
      (async () => {
        try {
          const fallBackUrl = `/register/offer-not-valid?${redirectParams}`;
          const serviceCode = qs.parse(location.search).join;
          if (!serviceCode) {
            setServiceCodeError(1);
            history.push(fallBackUrl);
          } else {
            const ServiceResponse = await ServicesAPI.getLinkCodeTrialService(
              serviceCode,
              countryCodeCopy || getCountryCode()
            );
            if (
              ServiceResponse.status === 200 &&
              ServiceResponse.data.canUserAccess
            ) {
              setTrialServiceData({
                join: Array.isArray(serviceCode) ? serviceCode[0] : serviceCode as string,
                country: countryCode,
                data: ServiceResponse.data
              })
              setExpirationDuration(ServiceResponse.data.trialDurationDays?.toString() || "");
              if (ServiceResponse.data.trialEndDate) {
                const trialDate = moment(
                  ServiceResponse.data.trialEndDate
                ).format("MMM Do, YYYY");
                setExpiration(trialDate);
                setTrialValid(true);
                setLoading(false);
              } else if (
                ServiceResponse.data.trialExpirationDate &&
                moment(ServiceResponse.data.trialExpirationDate).isAfter(
                  moment()
                )
              ) {
                const trialDate = moment(
                  ServiceResponse.data.trialExpirationDate
                ).format("MMM Do, YYYY");
                setExpiration(trialDate);
                setTrialValid(true);
                setLoading(false);
              } else {
                setServiceCodeError(2);
                history.push(fallBackUrl);
              }
            } else if (ServiceResponse.status !== 200) {
              setServiceCodeError(1);
              history.push(fallBackUrl);
            } else if (
              ServiceResponse.status === 200 &&
              !ServiceResponse.data.canUserAccess
            ) {
              if (ServiceResponse.data.isTrialExpired) {
                setServiceCodeError(2);
                history.push(fallBackUrl);
              } else {
                setServiceCodeError(1);
                history.push(fallBackUrl);
              }
            }
          }
        } catch (err) {
          Sentry.captureException(err);
          console.log(err);
        }
      })();
    }
  }, [countryCode, history, location, redirectParams, setServiceCodeError]);

  const params = qs.stringify({
    ...qs.parse(location.search),
  });

  return (
    <>
      <HeaderComponent />
      {loading ? (
        <StyledDiv>
          <CWBLoadingCrest customLogoSrc="/images/icon-cwb-logo-grey.svg" />
        </StyledDiv>
      ) : (
        <StyledDiv>
          <Typography align="center" component="h1" variant="h1">
            {t("You're Invited!")}
          </Typography>
          <Subheading align="center" variant="h1">
            {t(`Be a part of the fastest growing essential casting service!`)}
          </Subheading>
          <div style={{ display: "flex" }}>
            <Typography align="center" component="h4" variant="h4">
              {expirationDuration &&  t("Free Pro trial for {{expirationDays}} days", {
                  expirationDays: expirationDuration,
                })
              }
              {!expirationDuration &&
                t("Free Pro trial running until {{expiration}}", {
                  expiration: expiration,
                })
              }
            </Typography>
          </div>
          <StyledCard>
            <StyledTrialActorSignUpForm
              currencySymbol={currencySymbol}
              yearlyPerMonthCharge={yearlyPerMonthCharge}
              trialValid={trialValid}
            />
          </StyledCard>
          <Footnote align="center">
            {t(
              "Are you a Casting Director or Agent? Learn more about how Casting Workbook can support you."
            )}
          </Footnote>
          <LinksContainer>
            <StyledReactRouterLink to={`/register/agent?${params}`}>
              {t("Agents & Managers")}
            </StyledReactRouterLink>
            <StyledReactRouterLink to={`/register/casting?${params}`}>
              {t("Casting Directors")}
            </StyledReactRouterLink>
          </LinksContainer>
        </StyledDiv>
      )}
    </>
  );
};

const StyledDiv = styled.div`
  width: 889px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: ${(p) => p.theme.spacing(10, "auto", 0)};

  @media all and (max-width: 520px) {
    width: unset;
    margin-top: ${(p) => p.theme.spacing(3)};
    padding: ${(p) => p.theme.spacing(0, 2)};
  }
`;

const Subheading = styled(Typography)`
  margin: ${(p) => p.theme.spacing(0, 0, 2)};

  @media all and (max-width: 520px) {
    margin: ${(p) => p.theme.spacing(0, 0, 2)};
  }
`;

const StyledCard = styled(Card)`
  width: 427px;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  margin: ${(p) => p.theme.spacing(4, 0, 4)};
  padding: 0;

  @media all and (max-width: 520px) {
    flex-flow: column nowrap;
  }
`;

const StyledTypography = styled(Typography)`
  margin-left: ${(p) => p.theme.spacing(1)};
  cursor: pointer;
`;

const StyledTrialActorSignUpForm = styled(ActorCodeSignUpForm)`
  width: 100%;

  @media all and (max-width: 520px) {
    width: 100%;
  }
`;

const Footnote = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(2)};
  line-height: 19px;
`;

const LinksContainer = styled.div`
  margin-bottom: ${(p) => p.theme.spacing(6)};

  & > a + a {
    margin-left: ${(p) => p.theme.spacing(6)};
  }
`;

const StyledReactRouterLink = styled(ReactRouterLink)`
  && {
    color: #02b8f9;
    font-weight: bold;

    &:hover {
      color: #02b8f9;
      filter: brightness(1.15);
    }
  }
`;

type StateProps = {
  currencySymbol: string;
  mediaLimit: number;
  yearlyPerMonthCharge: number;
  numPhotos: number;
  serviceCodeError: number;
  countryCode: string;
};

const mapStateToProps = (state: any) => ({
  currencySymbol: state.premiumModel.currencySymbol,
  mediaLimit: state.premiumModel.mediaLimit,
  numPhotos: state.premiumModel.headshot.premium,
  yearlyPerMonthCharge: state.premiumModel.yearlyPerMonthCharge,
  serviceCodeError: state.actorModel.serviceCodeError,
  countryCode: state.referencesModel.countryCode,
});

type DispatchProps = {
  getSubscriptionServicesWithCountryCode: (code: string) => void;
  setServiceCodeError: (error: number) => void;
  setRequesterCountryCode: (fallback?: string | null) => void;
  setForceRequesterCountryCode: (countryCode: string) => void;
  setTrialServiceData: (data: ITrialServiceData) => void
};

const mapDispatchToProps = (dispatch: any) => ({
  getSubscriptionServicesWithCountryCode:
    dispatch.premiumModel.getSubscriptionServicesWithCountryCode,
  setServiceCodeError: dispatch.actorModel.setServiceCodeError,
  setRequesterCountryCode: dispatch.referencesModel.setRequesterCountryCode,
  setForceRequesterCountryCode: dispatch.referencesModel.setForceRequesterCountryCode,
  setTrialServiceData: dispatch.referencesModel.setTrialServiceData
});

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorCodeSignUp)
);
