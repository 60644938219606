import { createModel } from "@rematch/core";
import * as StripeCustomerAPI from "../shared/api/StripeCustomer.api";
import { IPaymentDetailsDto } from "../shared/api/dtos/IPaymentDetailsDto";
import { ICustomerPortalDto } from "../shared/api/dtos/ICustomerPortalDto";
// @ts-ignore
import * as Sentry from "@sentry/react";
import { IHttpResponse } from "shared/http";

const stripeCustomerModel = createModel({
  state: {
    isLoading: false as boolean,
    paymentDetails: {} as IPaymentDetailsDto,
    customerPortal: {} as ICustomerPortalDto,
  },
  reducers: {
    setIsLoading: (state: any, isLoading: boolean) => ({
      ...state,
      isLoading,
    }),
    setPaymentDetails: (state: any, paymentDetails: IPaymentDetailsDto) => ({
      ...state,
      paymentDetails,
    }),
    setCustomerPortal: (state: any, customerPortal: ICustomerPortalDto) => ({
      ...state,
      customerPortal,
    }),
  },
  effects: (dispatch: any) => ({
    getCustomerPaymentDetails: async () => {
      dispatch.stripeCustomerModel.setIsLoading(true);
      let response: IHttpResponse<IPaymentDetailsDto>;
      try {
        response = await StripeCustomerAPI.getCustomerPaymentDetails();
        dispatch.stripeCustomerModel.setPaymentDetails(response.data);
      } catch (e) {
        if (response && (response.status == 400 || response.status >= 500))
          Sentry.captureException(e);
      } finally {
        dispatch.stripeCustomerModel.setIsLoading(false);
      }
    },
    getCustomerPortal: async () => {
      dispatch.stripeCustomerModel.setIsLoading(true);
      let response: IHttpResponse<ICustomerPortalDto>;
      try {
        response = await StripeCustomerAPI.getCustomerPortal();
        dispatch.stripeCustomerModel.setCustomerPortal(response.data);
      } catch (e) {
        if (response && (response.status == 400 || response.status >= 500))
          Sentry.captureException(e);
      } finally {
        dispatch.stripeCustomerModel.setIsLoading(false);
      }
    },
  }),
});

export default stripeCustomerModel;
