import * as http from "../http";
// @ts-ignore
import { getAuthConfig } from "cwb-react";

export const getActorProfile = (clientId: number) => {
  return http.get(`${process.env.REACT_APP_PM_Api}/ActorProfile/${clientId}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      CWB: '1',
      ...getAuthConfig().headers
    },
  });
}

export const updateEmail = (clientId: number, newEmail: string) => {
  return http.put(
    `${process.env.REACT_APP_PM_Api}/ContactMethod/${clientId}/email`,
    { newEmail },
    {
      method: 'PUT',
      mode: 'cors',
      credentials: 'include',
      headers: {
        CWB: '1',
        ...getAuthConfig().headers,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ newEmail })
    }
  );
};
