import React from 'react';
import styled from "styled-components";
import TextInput from "./TextInput";

export const BlendedStyledInput = (props: any) => {
  return (<WrapperStyledInput>
    <CustomStyledInput {...props} />
  </WrapperStyledInput>)
}

const WrapperStyledInput = styled.div`
  div > img {
    display:none;
  }

  & > div > p.error {
    color: ${(p) => p.theme.errorColor};
    font-size: 11px;
    font-weight: normal;
    line-height: 15px;
  }
`

const CustomStyledInput = styled(TextInput)`
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    border: ${(props) => props.theme.dropDown["border"]};
    border-radius: ${(props) => props.theme.dropDown["border-radius"]};
    background-color: ${(props) => props.theme.white};
    padding: 0 14px;
    margin-top: 8px;
    outline: 0 !important;

  &.invalid {
    color: ${(p) => p.theme.errorColor};
    border: ${(p) => p.theme["error-border"]};
    background-color: ${(p) => p.theme.red};
  }

`;

export default BlendedStyledInput;