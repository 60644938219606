import React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  style?: any;
  height?: number;
};

const Skeleton: React.FC<Props> = ({
  className,
  style,
  height
}) => {
  const props = { className, style, height };
  return (
    <StyledDiv {...props} />
  );
};

const StyledDiv = styled.div<Props>`
  width: 100%;
  height: ${(p) => p.height}px;
  animation: pulse 1.5s ease-in-out 0.5s infinite;
  background-color: rgba(0, 0, 0, 0.11);
  border-radius: 3px;

  & + & {
    margin-top: ${(p) => p.theme.spacing(0.5)};
  }

  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }

    50% {
      opacity: 0.4;
    }
  }
`;

export default Skeleton;
