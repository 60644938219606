import React, { useEffect, useState } from "react";
import qs from "query-string";
import { getI18n } from "react-i18next";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import Membership from "../../Common/Membership";
import MembershipOneTimeSignup from "components/Common/MembershipOneTimeSignup";
import {
  getCountryCode,
  getCurUserSignUp,
  getCustomCouponCode,
  getCustomProductInfo,
  getRegionCode,
  getUserActorSignUpId,
  timedSentrySendThenErrorPage,
} from "../../../helpers";
import { IActorAccountDto } from "../../../shared/api/dtos/IActorDto";
import { IStripeCharge } from "../../../shared/api/dtos/IStripeDto";
import { iRootState } from "./../../../store";
import MembershipProduct from "components/Common/MembershipProduct";

interface Props extends RouteComponentProps, StateProps, DispatchProps { }

const ActorPaymentStep: React.FC<Props> = ({
  history,
  location,
  createStripeSubscription,
  createFreemiumUser,
  getServices,
  setLoading,
  isLoading,
  setClientId,
  getOneTimePaidServiceSignup,
  createStripeSubscriptionOneTime,
  customCouponCode,
  customProductCode,
  customProductServiceId
}) => {
  const user: IActorAccountDto = getCurUserSignUp();

  const [hasOffer, setHasOffer] = useState(false);

  const [isHomeKit, setIsHomeKit] = useState(false);
  const [product, setProduct] = useState('');

  const [promoCode, setPromoCode] = useState('');

  useEffect(() => {
    let targetPromoCode = qs.parse(location.search).promo?.toString();
    if (!targetPromoCode && customCouponCode) {
      targetPromoCode = customCouponCode.toString();
    }
    setPromoCode(targetPromoCode);
  }, [location])

  useEffect(() => {

    let productCode = qs.parse(location.search).product?.toString();
    if (!productCode && customProductCode) {
      productCode = customProductCode.toString();
    }

    const isHomeKit = productCode === "homekit";

    setIsHomeKit(isHomeKit);
    setProduct(productCode);

  }, [location]);

  useEffect(() => {
    //check valid special service
    const offer = qs.parse(location.search).offer === "latam597";
    const id = getUserActorSignUpId();
    if (offer) {
      (async () => {
        const isValid = await getOneTimePaidServiceSignup(id);
        if (isValid) {
          setHasOffer(true);
        }
      })();
    }
  }, [location, getOneTimePaidServiceSignup]);

  const fetchServices = async () => {
    await getServices(getUserActorSignUpId());
  };

  const handleSubmit = async (apiPayload: IStripeCharge) => {
    const response = await createStripeSubscription(apiPayload);

    if (response && response.data && response.data.clientId)
      setClientId(response.data.clientId);
  };

  const moveToNext = (serviceId: number) => {
    history.push({
      pathname: "/actor/payment-processing",
      search: location.search,
      state: {
        successPath: "/actor/pro-completed",
        serviceId,
      },
    });
  };

  const handleSkip = async () => {
    try {
      if (isLoading)
        return;

      setLoading(true);

      await createFreemiumUser(getUserActorSignUpId());
      history.push({
        pathname: "/actor/free-completed",
        search: location.search,
      });
      setLoading(false);
    } catch (e) {
      timedSentrySendThenErrorPage(e);
    }
  };

  const handleOneTimeSubmit = async (apiPayload: IStripeCharge) => {
    const response = await createStripeSubscriptionOneTime(apiPayload);

    if (response && response.data && response.data.clientId)
      setClientId(response.data.clientId);
  };

  if (!user) return <Redirect to="/register" />;

  const isLangSpanish = getI18n().language === "es";
  const isUsa = getRegionCode() === "US" || getCountryCode() === "US";

  if (hasOffer)
    return (
      <MembershipOneTimeSignup
        country={user.actorFilmCenter.country}
        provinceId={user.actorFilmCenter.provinceId}
        onSubmit={(apiPayload) => handleOneTimeSubmit(apiPayload)}
        moveToNext={moveToNext}
      />
    );

  if (isHomeKit && isUsa)
    return (<MembershipProduct
      country={user.actorFilmCenter.country}
      provinceId={user.actorFilmCenter.provinceId}
      // allowSkip={((qs.parse(location.search).cwblabs ?
      //   Boolean(qs.parse(location.search).cwblabs) : false)
      // ) || isLangSpanish}
      // onSkip={handleSkip}
      // getServices={() => fetchServices()}
      onSubmit={(apiPayload) => handleSubmit(apiPayload)}
      moveToNext={moveToNext}
      // clientId={authenticatedUser.id.userId}
      product={product}
      promoCode={promoCode}
    />)

  return (
    <Membership
      country={user.actorFilmCenter.country}
      provinceId={user.actorFilmCenter.provinceId}
      allowSkip={((qs.parse(location.search).cwblabs ?
        Boolean(qs.parse(location.search).cwblabs) : false)
      ) || isLangSpanish}
      onSkip={handleSkip}
      getServices={() => fetchServices()}
      onSubmit={(apiPayload) => handleSubmit(apiPayload)}
      moveToNext={moveToNext}
      promoCode={promoCode}
    />
  );
};

interface DispatchProps {
  createStripeSubscription: (dto: IStripeCharge) => any;
  createStripeSubscriptionOneTime: (dto: IStripeCharge) => any;
  createFreemiumUser: (actorSignUpId: number) => void;
  getServices: (actorSignUpId: number) => void;
  setLoading: (loading: boolean) => any;
  setClientId: (clientId: string) => void;
  getOneTimePaidServiceSignup: (actorSignUpId: number) => boolean;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    createStripeSubscription: dispatch.stripeModel.createStripeSubscription,
    createStripeSubscriptionOneTime:
      dispatch.stripeModel.createStripeSubscriptionOneTime,
    createFreemiumUser: dispatch.actorModel.createFreemiumUser,
    getServices: dispatch.servicesModel.getPaidServicesSignup,
    setLoading: dispatch.appModel.setLoading,
    setClientId: dispatch.actorModel.setClientId,
    getOneTimePaidServiceSignup:
      dispatch.servicesModel.getOneTimePaidServiceSignup,

  };
};

interface StateProps {
  customCouponCode?: string;
  customProductCode?: string;
  customProductServiceId?: number;
  isLoading: boolean
}

function mapStateToProps(state: iRootState): StateProps {
  let customCouponCode = state.actorModel.customCouponCode;
  if (!customCouponCode) {
    customCouponCode = getCustomCouponCode(getUserActorSignUpId());
  }

  let customProductCode = state.actorModel.customProductCode;
  let customProductServiceId = state.actorModel.customProductServiceId;
  
  return {
    customCouponCode: customCouponCode,
    customProductCode: customProductCode,
    customProductServiceId: customProductServiceId,
    isLoading: state.appModel.loading,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActorPaymentStep);
