import React from "react";
import styled from "styled-components";
import { withI18n } from "react-i18next";
import * as SharedStyle from "../Common/StyledComponents";

interface IProps {
  t?: any;
}

const ProducerSellingFeatures: React.FC<IProps> = ({ t }) => {
  return (
    <>
      <TitleWrapper>
        <MainTile>{t("Producer")}</MainTile>
      </TitleWrapper>
      <StyledP>
        {t(
          "Casting Workbook, in partnership with Producer Essentials, is proud to offer a new video masterclass series for aspiring producers who are looking to take the next step & bring their creative project to life."
        )}
      </StyledP>
      <TitleWrapper>
        <Title>
          {t("For Aspiring Producers")}
        </Title>
      </TitleWrapper>
      <SharedStyle.SellingFeatureP>
        {t(
          "Whether you’re an actor, director, writer, an aspiring business professional or a passionate creative interested in how the production industry works, this series will save you time and money. Producer Essentials: A Comprehensive Guide to Business Affairs and Production Law for Film & Television offers you the tools you need to effectively navigate the Film & TV production landscape and get your first project off the ground."
        )}
      </SharedStyle.SellingFeatureP>
      <TitleWrapper>
        <Title>
          {t("Learn from the very best")}
        </Title>
      </TitleWrapper>
      <SharedStyle.SellingFeatureP>
        {t(
          "Developed and designed by top entertainment lawyers, Lori Massini & Heather Watt, Producer Essentials brings together current industry legal experts & business professionals to provide step-by-step instruction, essential information & firsthand experience into every video session."
        )}
      </SharedStyle.SellingFeatureP>
      <TitleWrapper>
        <Title>
          {t("Master Class Instruction")}
        </Title>
      </TitleWrapper>
      <SharedStyle.SellingFeatureP>
        {t(
          "Through 12 video sessions across 16 hours of content, this self-paced course covers all aspects of producing a film or television production, from financing the project and post-production requirements to marketing and pitching your project to investors, festivals and distributors."
        )}
      </SharedStyle.SellingFeatureP>
    </>
  );
};

export default withI18n()(ProducerSellingFeatures);

const TitleWrapper = styled.div`
  width: 100%;
`;

const MainTile = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  color: #0a1f44 !important;
  margin-bottom: 12px;
`;

const Title = styled.div`
  display: flex;
  font-size: 21px;
  font-weight: 600;
  line-height: 29px;
  color: #364866 !important;
  margin-bottom: 12px;
`;

const StyledP = styled.p`
  font-size: 14px;
  color: #364866 !important;
  line-height: 25px;
`;
