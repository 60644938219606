import styled from "styled-components";
// @ts-ignore
import { CWBCheckbox } from "cwb-react";

export const ErrorText = styled.div`
  color: ${(p) => p.theme.errorColor} !important;
  width: 100%;
  font-size: ${(p) => p.theme["xxs-font-size"]};
  font-weight: normal;
  letter-spacing: 0.21px;
  line-height: 15px;
  margin-top: 8px;
`;

export const CheckboxWrapper = styled.div`
  height: 40px;
  width: 100%;
  border: ${(props) => props.theme.dropDown["border"]} !important;
  border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  background-color: ${(props) => props.theme.white} !important;

  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;

  padding: 0 8px;
  cursor: pointer;

  &:first-child { margin-right: 8px; }
`;

export const CheckboxWrapperNoBorder = styled.div`
  height: 20px;
  width: 100%;
  background-color: ${(props) => props.theme.white} !important;

  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;

  padding: 0 8px;

  &:first-child {
    margin-right: 8px;
  }
`;

export const CheckboxWrapperStage = styled.div`
  height: auto;
  width: 100%;
  background-color: ${(props) => props.theme.white} !important;

  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;

  &:first-child {
    margin-right: 8px;
  }
`;

export const CheckboxWrapperProduct = styled.div`
  height: auto;
  width: 100%;
  cursor:pointer;

  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;

  &:first-child {
    margin-right: 8px;
  }
`;

export const InnerSection = styled.div`
  width: 100%;
  margin-left: 16px;
  margin-right: 16px;
`;


export const Section = styled.div`
  width: 100%;
  margin: 16px 0 8px 0;

  &.margin-bottom-none {
    margin-bottom: 0;
  }

  &.margin-top-none {
    margin-top: 0;
  }

  &.margin-none {
    margin: 0;
  }
`;

export const QuestionSection = styled(Section)`
  margin-top:0 !important;
`
export const SectionTitle = styled.div`
  font-size: ${(p) => p.theme["xxs-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  color: ${(p) => p.theme.color} !important;
  line-height: 16px;
`;

export const StyledInput = styled.input`
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: ${(props) => props.theme.dropDown["border"]};
  border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  background-color: ${(props) => props.theme.white};
  padding: 0 14px;
  margin-top: 8px;
  outline: 0 !important;

  &.invalid {
    color: ${(p) => p.theme.errorColor};
    border: ${(p) => p.theme["error-border"]};
    background-color: ${(p) => p.theme.red};
  }
`;

export const StyledTextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  border: ${(props) => props.theme.dropDown["border"]};
  border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  background-color: ${(props) => props.theme.white};
  padding: ${p => p.theme.spacing(1)};
  margin-top: 8px;
  font-size: 13px;
  outline: 0 !important;

  &.invalid {
    color: ${(p) => p.theme.errorColor};
    border: ${(p) => p.theme["error-border"]};
    background-color: ${(p) => p.theme.red};
  }
`;

export const Divider = styled.div`
  margin: 16px 0 8px 0;
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.dropDown["border-color"]};
`;

export const TrialDivider = styled.div`
  margin: 20px 0 20px 0;
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.dropDown["border-color"]};
`;

export const Spacer10 = styled.div`
  margin: 10px 0 8px 0;
  width: 100%;
`;

export const SectionSubTitle = styled.div`
  color: ${(p) => p.theme["color"]} !important;
  font-size: ${(p) => p.theme["title-font-size"]};
  font-size: 10px;
  padding-top: 3px;
`;

export const StyledOverlay = styled.div`
  width: 100% !important;
  height: 100% !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 10;
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.32);
`;

export const StyledBlueWhiteBtn = styled.button`
  font-size: ${(p) => p.theme["font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  color: ${(p) => p.theme.btnDarkBlue} !important;
  outline: 0 !important;
  border: 1px solid ${(p) => p.theme.btnDarkBlue} !important;
  border-radius: ${(p) => p.theme.dropDown["border-radius"]};
  background-color: ${(p) => p.theme.white};
  padding: 9px 0;
  cursor: pointer;
  &.disabled:hover,
  &:disabled:hover {
    opacity: 0.5;
    cursor: default;
  }
`;

export const StyledBlueBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: ${(p) => p.theme["font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};

  outline: 0 !important;
  border: 1px solid ${(p) => p.theme.lightBlue} !important;
  border-radius: ${(p) => p.theme.dropDown["border-radius"]};

  box-shadow: none !important;
  padding: 9px 0;
  cursor: pointer;

  background: #2ed2ff;
  color: white !important;

  &:hover {
    background: #00aaff !important;
    border: 1px solid ${(p) => p.theme.btnDarkBlue} !important;
    color: white !important;
  }
`;

export const StyledCheckbox = styled(CWBCheckbox)`
  .icon-cwb-unchecked:before {
    font-size: ${(p) => p.theme["font-size"]};
  }
  .icon-cwb-checked:before {
    font-size: ${(p) => p.theme["font-size"]};
    color: ${(p) => p.theme.darkBlue};
  }
  &.child-cb {
    margin-left: 40px;
  }

  &.small-cb {
    display: flex;
    align-items: center;
    .icon-cwb-unchecked,
    .icon-cwb-unchecked:before,
    .icon-cwb-checked,
    .icon-cwb-checked:before {
      font-size: ${(p) => p.theme["sm-cb-font"]};
    }
  }
`;

export const ErrorImg = styled.i`
  display: inline-block;
  background: ${(p) => p.theme.errorColor};
  border-radius: 25px;
  padding: 2px;
  transform: rotate(45deg);
  font-size: 8px;
  font-weight: 800;
  margin-right: 5px;
`;

export const Spinner = styled.img`
  height: 16px;
  width: 16px;
`;

export const SmallText = styled.div`
  color: ${(p) => p.theme.color} !important;
  width: 100%;
  font-size: ${(p) => p.theme["xxs-font-size"]} !important;
  font-weight: ${(p) => p.theme["font-weight-600"]};
  letter-spacing: 0.21px;
  line-height: 15px;
  margin-top: 8px;
  a {
    font-size: ${(p) => p.theme["xxs-font-size"]} !important
  }
  span{
    font-size: ${(p) => p.theme["xxs-font-size"]} !important
    font-weight: normal;
  }

  &.error, &.error span{
    color: ${(p) => p.theme.errorColor} !important;
    font-weight: normal;
  }
  `;

export const SmallTextError = styled.div`
  color: ${(p) => p.theme.color} !important;
  width: 100%;
  font-size: ${(p) => p.theme["xxs-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  letter-spacing: 0.21px;
  line-height: 15px;
  margin-top: 8px;

  span {
    font-weight: normal;
  }

  &.error,
  &.error span {
    color: ${(p) => p.theme.errorColor} !important;
    font-weight: normal;
  }
`;

export const LargeText = styled.div`
  font-size: ${(p) => p.theme["lg-font-size"]};
`;

export const SellingFeatureP = styled.p`
  font-size: 14px;
  color: #364866 !important;
  line-height: 25px;
  margin-left: 7%;
`;

export const SellingFeatureIcon = styled.img`
  height: 22px;
  width: 22px;
  margin-bottom: 1%;
  margin-right: 2%;
`;
