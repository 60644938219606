import { init, RematchRootState } from "@rematch/core";
// @ts-ignore
import * as models from "./models";
// @ts-ignore
export type models = typeof models;
export const store = init({
  models,
});
export type Store = typeof store;
export type Dispatch = typeof store.dispatch;
export type iRootState = RematchRootState<typeof models>;
