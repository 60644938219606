import React from "react";
import qs from "query-string";
import { withI18n } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Footer, PageContainer } from "../Common";
import BackButton from "../Common/BackButton";
import ModalPaymentRequiresAction from "components/CWBLabs/ModalPaymentRequiresAction";
import { IActorAccountDto, IActorCredentials, IActorFilmCenter, IActorPhone, IActorStageName } from "shared/api/dtos/IActorDto";
import { getCurUserSignUp } from "helpers";
// import ModalSignUpPaymentRequiresAction from "./SignUp/ModalSignUpPaymentRequiresAction";
import ModalUpgradePaymentRequiresAction from "./Upgrade/ModalUpgradePaymentRequiresAction";

type Props = {
  t: any;
} & RouteComponentProps;

const ActorPaymentRequiresAction: React.FC<Props> = ({ t, history, location }) => {
  // const userSignUp: IActorAccountDto = getCurUserSignUp();
  // const userSignUp: IActorAccountDto = {
  //   sessionId: "", 
  //   actorCredentials: {} as IActorCredentials,
  //   actorStageName: {} as IActorStageName,
  //   actorFilmCenter: {} as IActorFilmCenter,
  //   actorPhone: {} as IActorPhone
  // };

  return qs.parse(location.search).cwblabs ? (
    <ModalPaymentRequiresAction />
  ) : (
    <PageContainer>
      <BackButton width={800} />
      <ModalUpgradePaymentRequiresAction />
      
      {/* {userSignUp ? (
        <ModalSignUpPaymentRequiresAction />
      ) : (
        <ModalUpgradePaymentRequiresAction />
      )} */}
      <Footer />
    </PageContainer>
  );
};

export default withI18n()(withRouter(ActorPaymentRequiresAction));
