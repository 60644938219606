import React from "react";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import { Button, TextInput, Typography } from "components/Common";
import BirthDatePicker from "components/Common/BirthDatePicker";
import {
  Spinner,
  Section,
  SmallTextError,
  CheckboxWrapperStage,
  StyledCheckbox,
} from "components/Common/StyledComponents";
import Tooltip from "components/Common/Tooltip";
import Card from "./components/Card";
import Footer from "./components/Footer";
import Page from "./components/Page";
import BackButton from "components/Common/BackButton";

type Props = {
  t: any;
  errors: any;
  fields: any;
  isLangSpanish: boolean;
  isLoading: boolean;
  ageChecked: boolean;
  onChange: (e: any) => void;
  onSubmit: (e: any) => void;
  handleAgeCheck: (e: any) => void;
};

const ActorStageNameStep: React.FC<Props> = ({
  t,
  errors,
  fields,
  isLangSpanish,
  isLoading,
  handleAgeCheck,
  ageChecked,
  onChange: handleChange,
  onSubmit: handleSubmit,
}) => {
  return (
    <StyledPage>
      <BackButton width={480} />
      <StyledCard>
        <Typography align="center" component="h1" variant="h2">
          {t("Profile Information")}
        </Typography>
        <Form onSubmit={handleSubmit}>
          <Typography color="darkGrey" component="h2" variant="h5">
            {t(`What's your stage name?`)}
          </Typography>
          <TextInput
            error={Boolean(errors.FirstName)}
            errorMessage={errors.FirstName}
            label={t("First Name")}
            name="firstName"
            value={fields.firstName}
            onChange={handleChange}
          />
          {!isLangSpanish && (
            <TextInput
              error={Boolean(errors.MiddleName)}
              errorMessage={errors.MiddleName}
              label={t("Middle Name (If used professionally)")}
              name="middleName"
              value={fields.middleName}
              onChange={handleChange}
            />
          )}
          <TextInput
            error={Boolean(errors.LastName)}
            errorMessage={errors.LastName}
            label={t("Last Name")}
            name="lastName"
            value={fields.lastName}
            onChange={handleChange}
          />
          <StyledTypography color="darkGrey" component="h2" variant="h5">
            {t(`When's your birthday?`)}
            <StyledTooltip
              placement="top"
              tip={t(
                "This field is not mandatory. Some projects require youth and young adults to qualify for project eligibility by being at least a certain age."
              )}
            >
              <img alt="more info" src="../images/info.svg" />
            </StyledTooltip>
          </StyledTypography>
          <BirthDatePicker
            name="birthDate"
            value={fields.birthDate}
            onChange={handleChange}
          />
          <Section>
            <CheckboxWrapperStage onClick={handleAgeCheck}>
              <AgeCheckbox className="small-cb" isChecked={ageChecked} />
              <StyledLabel>
                {t(
                  "I am currently at least 18 years old or this is being filled by a parent or guardian"
                )}
              </StyledLabel>
            </CheckboxWrapperStage>
            <SmallTextError className="error">
              <span>{errors.ageChecked}</span>
            </SmallTextError>
          </Section>
          <StyledButton
            startIcon={
              isLoading && (
                <Spinner
                  className="spinner-width"
                  src="../images/spinner.svg"
                />
              )
            }
          >
            {t("Continue")}
          </StyledButton>
        </Form>
      </StyledCard>
      <StyledFooter />
    </StyledPage>
  );
};

const StyledPage = styled(Page)`
  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding-top: 8px;
    border: none;
  }
`;

const StyledCard = styled(Card)`
  width: 480px;
  padding: ${(p) => p.theme.spacing(4, 10)};

  @media all and (max-width: 520px) {
    width: 100%;
    padding: 40px;
    border-top: 0;
    border-radius: 0;
  }
`;

const Form = styled.form`
  width: 100%;
  margin: ${(p) => p.theme.spacing(2)};

  & > label {
    margin-top: ${(p) => p.theme.spacing(2)};
  }
`;

const StyledTypography = styled(Typography)`
  margin: ${(p) => p.theme.spacing(3, 0, 1)};
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: 5px;

  & > img {
    width: 18px;
    height: 18px;
    margin-top: -2px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${(p) => p.theme.spacing(3)};
`;

const StyledFooter = styled(Footer)`
  margin-top: ${(p) => p.theme.spacing(3)};
`;

const StyledLabel = styled(Typography)`
  margin-left: ${(p) => p.theme.spacing(1)};
  font-size: ${(p) => p.theme["xxs-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  color: ${(p) => p.theme.color} !important;
  line-height: 16px;
  cursor: pointer;
`;

const AgeCheckbox = styled(StyledCheckbox)`
  cursor: pointer;
`;

export default withI18n()(ActorStageNameStep);
