import { createModel } from "@rematch/core";
import * as ProfileManagementApi from "../shared/api/ProfileManagement.api";
// @ts-ignore
import * as Sentry from "@sentry/react";

const appModel = createModel({
  state: {
    loading: false,
    actorProfile: null,
    authenticatedUser: null,
    sessionData: null
  },
  reducers: {    
    setLoading: (state: any, loading: boolean) => ({
      ...state,
      loading
    }),    
    setActorProfile: (state: any, actorProfile: any) => ({
      ...state,
      actorProfile
    }),
    setAuthenticatedUser: (state: any, authenticatedUser: any) => ({
      ...state,
      authenticatedUser
    }),
    setSessionData: (state: any, sessionData: any) => ({
      ...state,
      sessionData
    })
  },
  effects: (dispatch: any) => ({
    async getActorProfile(clientId: number) {
      let profile = null;
      try {
        const response = await ProfileManagementApi.getActorProfile(clientId);
        profile = response.data;
      } catch (e) {
        Sentry.captureException(e)
      }
      dispatch.appModel.setActorProfile(profile);
    },
    async getActorProfileCached(clientId: number, state) {
      if (state.appModel.actorProfile && state.appModel.actorProfile.clientId == clientId) {
        return state.appModel.actorProfile;
      }
      let profile = null;
      try {
        const response = await ProfileManagementApi.getActorProfile(clientId);
        profile = response.data;
      } catch (e) {
        Sentry.captureException(e)
      }
      dispatch.appModel.setActorProfile(profile);
    }
  })
});

export default appModel;
