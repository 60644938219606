import { createModel } from "@rematch/core";

const headerModel = createModel({
  state: {
    isLoggedIn: false
  },
  reducers: {
    setUserLoggedIn: (state: any, isLoggedIn: boolean) => ({
      ...state,
      isLoggedIn
    })
  },
  effects: () => ({})
});

export default headerModel;
