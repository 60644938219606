import React, { useState, useEffect } from "react";
import qs from "query-string";
import { connect } from "react-redux";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import { Button } from "../../Common";
import Footer from "../../Common/Footer";
import Modal from "../../Common/Modal";
import PageContainer from "../../Common/PageContainer";
import Text from "../../Common/Text";
import SpecialUpgradeTable from "../../Common/SpecialUpgradeTable";
import DeclineOfferModal from "../../Modals/DeclineOfferModal";
import { emailValidation } from "helpers/validations";
import * as ServicesAPI from "../../../shared/api/Services.api";
import moment from 'moment';

interface IProps extends StateProps, DispatchProps{
  history: any;
  location: any;
  t: any;
}

const ActorUpgradeSpecial: React.FC<IProps> = ({
  history,
  location,
  t,
  actorProfile,
  sessionData,
  authenticatedUser,
  setFromPrecheckout
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [declined, setDeclined] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("$")

  useEffect(() => {
    const redirectParams = qs.stringify({
      ...(qs.parse(location.search))
   });
   (async () => {
    const response = await ServicesAPI.getOneTimeServiceClient();
    const services = response.data;
    if(!services.some(item => item.oneTimeFee && item.hasOneTimeFee 
      && moment(item.oneTimeFeeExpirationDate).isAfter(moment()))){
        history.push(`/actor/upgrade-to-pro?${redirectParams}`) 
      }else {
        const service = services.find(item => item.oneTimeFee && item.hasOneTimeFee 
          && moment(item.oneTimeFeeExpirationDate).isAfter(moment()));
        if(service){
          if(service.currency === "EUR"){
            setCurrencySymbol("€");
          }
          setFromPrecheckout(true);
        }
      }
   })()
  },[location, history, setFromPrecheckout])

  const handleUpgrade = () => {
    let errors = emailValidation(actorProfile.email, t);
    if (!errors) {
      const params = qs.stringify({
        ...(qs.parse(location.search)),
        product:'special'
     });
      history.push(`/actor/select-membership?${params}`);
    } 
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const toHomepage = () => {
    if(sessionData && authenticatedUser){
      const params = qs.stringify({
        tk: sessionData.WebSessionKey,
        cid: authenticatedUser.id.userId,
        ...(qs.parse(location.search))
      });
     window.top.location.href = 
     `${process.env.REACT_APP_CWB_Site}/actorsworkbook/default.asp?${params}`;
    }else {
      setIsOpen(false)
    }
  }

  return <PageContainer>
      <StyledModal>
        <ContentWrapper>
          <div>
            <StyledTextH1 variant="h1">
              {declined? t("Upgrade now and take control of your career") : t("Upgrade now and put all your materials to work")}
            </StyledTextH1>
            <StyledTextP>
              {declined? t("Enjoy the best content and tools to boost your acting career for just {{currencySymbol}}5.97 for 3 months!",{currencySymbol}):t(
                "Trial has ended. Take advantage of limited time offer. 3 months for just {{currencySymbol}}5.97 ({{currencySymbol}}1.99/mo)", {currencySymbol}
              )}
            </StyledTextP>
          </div>
          {
            <SpecialUpgradeTable declined={declined} />
          }
          <ButtonContainer>
              <Button variant="secondary" onClick={() => {
                if(!declined){
                  setDeclined(true);
                  setTimeout(() => setIsOpen(true), 9000);
                }else {
                  toHomepage()
                };
              }
              }>
              {t("No thank you")}
            </Button>
            <Button onClick={() => handleUpgrade()}>
              {t("Upgrade now")}
            </Button>
          </ButtonContainer>
        </ContentWrapper>
      </StyledModal>
      <Footer />
      {isOpen && <DeclineOfferModal onClose={() => handleClose()} handleUpgrade={handleUpgrade} toHomePage={toHomepage} currencySymbol={currencySymbol}/>}
    </PageContainer>
};

const StyledModal = styled(Modal)`
  width: 848px;

  @media all and (max-width: 768px) {
    width: 90%;
  }

  @media all and (max-width: 520px) {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 85px;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > table {
    margin: ${(p) => p.theme.spacing(3, 0)};
  }

  @media all and (max-width: 768px) {
    padding: 24px 40px;
  }

  @media all and (max-width: 520px) {
    padding: 24px 16px;
  }
`;


const StyledTextH1 = styled(Text)`
  && {
    margin: 22px 0 16px;
    text-align: center;

    @media all and (max-width: 520px) {
      width: 80%;
    }
  }
`;

const StyledTextP = styled(Text)`
  && {
    text-align: center;
    display: inherit;
    width: max-content;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > * + * {
    margin-left: 10px;
  }
`;


interface StateProps {
  actorProfile: any;
  authenticatedUser: any;
  sessionData: any;
}

const mapStateToProps = (state: any) => ({
  actorProfile: state.appModel.actorProfile,
  authenticatedUser: state.appModel.authenticatedUser,
  sessionData: state.appModel.sessionData,
});

interface DispatchProps {
  setFromPrecheckout:(fromPrecheckout:boolean) => void;
}

const mapDispatchToProps = (dispatch: any) => ({
  setFromPrecheckout:dispatch.servicesModel.setFromPrecheckout
});

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorUpgradeSpecial)
);
