import { createModel } from "@rematch/core";
import * as PaymentAPI from "../shared/api/Payment.api";
import * as StripeApi from "../shared/api/Stripe.api";
import {
  ISubscriptionResponse,
  IStripeCharge,
  IStripePaymentError,
  IStripeChargeProduct,
  IPurchaseAttempt,
} from "../shared/api/dtos/IStripeDto";
import { cleanObjectProperties, getUserActorSignUpId } from "../helpers";
import i18next from "i18next";
import { setUser } from "../service/UserService";
// @ts-ignore
import * as Sentry from "@sentry/react";
// @ts-ignore
import { setUserAuthToken } from "cwb-react";

const stripeModel = createModel({
  state: {
    subscriptionDetails: {} as ISubscriptionResponse,
    errors: {} as IStripePaymentError,
    isLoading: false,
    purchase: null as IPurchaseAttempt
  },
  reducers: {
    updateSubscriptionDetails(state, data: ISubscriptionResponse) {
      return { ...state, subscriptionDetails: data };
    },
    setErrors: (state: any, errors: IStripePaymentError) => ({
      ...state,
      errors,
    }),
    setIsLoading: (state: any, isLoading: boolean) => ({
      ...state,
      isLoading,
    }),
    setPurchase: (state: any, purchase) => ({
      ...state,
      purchase
    })
  },
  effects: (dispatch: any) => ({
    async createStripeSubscription(dto: IStripeCharge) {
      cleanObjectProperties(dto);
      dispatch.stripeModel.setPurchase({ serviceId: dto.serviceId, couponCode: dto.couponCode });
      return await StripeApi.createStripeSubscription(
        getUserActorSignUpId(),
        dto
      )
        .then(async (resp: any) => {
          dispatch.stripeModel.updateSubscriptionDetails(resp.data);
          setUserAuthToken(resp.data.jwt);
          await setUser(resp.data.jwt);
          return resp;
        })
        .catch((resp) => {
          Sentry.captureException(resp);
          const { status, data } = resp.response;
          if (status === 504 || status === 530 || (status >= 400 && status < 500) || !data) {
            throw resp;
          }

          if (data.message) {
            dispatch.stripeModel.setErrors({
              errorMessage: data.message
            });
          } else {
            dispatch.stripeModel.setErrors({
              errorMessage: i18next.t(
                "Cannot process the request. An unexpected error occurred."
              ),
            });
          }
        })
        .finally(() => {
          dispatch.stripeModel.setIsLoading(false);
        });
    },
    async createStripeSubscriptionOneTime(dto: IStripeCharge) {
      cleanObjectProperties(dto);
      dispatch.stripeModel.setPurchase({ serviceId: dto.serviceId, couponCode: dto.couponCode });
      return await StripeApi.createStripeSubscriptionOneTime(
        getUserActorSignUpId(),
        dto
      )
        .then(async (resp: any) => {
          dispatch.stripeModel.updateSubscriptionDetails(resp.data);
          setUserAuthToken(resp.data.jwt);
          await setUser(resp.data.jwt);
          return resp;
        })
        .catch((resp) => {
          Sentry.captureException(resp);
          const { status, data } = resp.response;
          if (status === 504 || status === 530 || (status >= 400 && status < 500) || !data) {
            throw resp;
          }

          if (data.message) {
            dispatch.stripeModel.setErrors({
              errorMessage: data.message
            });
          } else {
            dispatch.stripeModel.setErrors({
              errorMessage: i18next.t(
                "Cannot process the request. An unexpected error occurred."
              ),
            });
          }
        })
        .finally(() => {
          dispatch.stripeModel.setIsLoading(false);
        });
    },
    async upgradeFreemium(dto: IStripeCharge) {
      cleanObjectProperties(dto);
      dispatch.stripeModel.setPurchase({ serviceId: dto.serviceId, couponCode: dto.couponCode });
      return await StripeApi.upgradeFreemium(dto)
        .then((resp: any) => {
          dispatch.stripeModel.updateSubscriptionDetails(resp.data);
          return resp;
        })
        .catch((resp) => {
          Sentry.captureException(resp);
          const { status, data } = resp.response;
          if (status === 504 || status === 530 || (status >= 400 && status < 500) || !data) {
            throw resp;
          }

          if (data.message) {
            dispatch.stripeModel.setErrors({
              errorMessage: data.message,
            });
          } else {
            dispatch.stripeModel.setErrors({
              errorMessage: i18next.t(
                "Cannot process the request. An unexpected error occurred."
              ),
            });
          }
        })
        .finally(() => {
          dispatch.stripeModel.setIsLoading(false);
        });
    },
    async upgradeFreemiumProduct(dto: IStripeChargeProduct) {
      cleanObjectProperties(dto);
      dispatch.stripeModel.setPurchase({ serviceId: dto.serviceId, couponCode: dto.couponCode });
      return await StripeApi.upgradeFreemiumProduct(dto)
        .then((resp: any) => {
          dispatch.stripeModel.updateSubscriptionDetails(resp.data);
          return resp;
        })
        .catch((resp) => {
          Sentry.captureException(resp)
          const { status, data } = resp.response;
          if (status === 504 || status === 530 || (status >= 400 && status < 500) || !data) {
            throw resp;
          }

          if (data.message) {
            dispatch.stripeModel.setErrors({
              errorMessage: data.message,
            });
          } else {
            dispatch.stripeModel.setErrors({
              errorMessage: i18next.t(
                "Cannot process the request. An unexpected error occurred."
              ),
            });
          }
        })
        .finally(() => {
          dispatch.stripeModel.setIsLoading(false);
        });
    },
    async getPaymentStatus(serviceId: number) {
      const response = await PaymentAPI.getPaymentStatus(serviceId);
      return response.data;
    },
  }),
});

export default stripeModel;