import React from "react";
import styled from "styled-components";

type Props = {
  className?: string;
  [key: string]: any;
};

const PageContainer: React.FC<Props> = ({ children, className }) => {
  return (
    <StyledDiv className={className}>
      {children}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;

  @media all and (max-width: 520px) {
    padding-top: 0;
    border-top: 1px solid ${p => p.theme.lightGrey};
  }
`;

export default PageContainer;
