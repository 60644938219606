import React, { useState, useEffect, useCallback } from "react";
import qs from "query-string";
import { withI18n } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";
import Footer from "../../Common/Footer";
import Modal from "../../Common/Modal";
import PageContainer from "../../Common/PageContainer";
import Text from "../../Common/Text";
import { StyledBlueBtn } from "../../Common/StyledComponents";
import { iRootState } from "../../../store";
import CWBLabsActorUpgradeComplete from "components/CWBLabs/ActorUpgradeComplete";

interface IProps extends StateProps {
  t: any;
  history: any;
  location: any;
}

const ActorUpgradeComplete: React.FC<IProps> = ({
  history,
  location,
  t,
  authenticatedUser,
  sessionData
}) => {
  const [remainingTime, setRemainingTime] = useState<number>(19);
  const qsArgs = qs.parse(location.search);
  const isJob = qsArgs.page === 'openbd' || qsArgs.page === 'submission' || (qsArgs.bd && qsArgs.bd.length > 4);

  const redirect = useCallback(() => {
    let queryObj = {
      ...(qs.parse(location.search)),      
    }

    if(isJob && !queryObj.page) {
      queryObj.page = 'openbd';
    };

    if (sessionData && sessionData.WebSessionKey)
      queryObj.tk = sessionData.WebSessionKey;
    if (authenticatedUser && authenticatedUser.id)
      queryObj.cid = authenticatedUser.id.userId;

    const queryString  = qs.stringify(queryObj);
    
    window.top.location.href = 
      `${process.env.REACT_APP_CWB_Site}/actorsworkbook/default.asp?${queryString}`
    ;
  }, [authenticatedUser, sessionData, location]);

  useEffect(() => {
    if (remainingTime === 0) {
      redirect();
    } else {
      setTimeout(() => {
        setRemainingTime(remainingTime - 1);
      }, 1000);
    }
  }, [remainingTime, redirect]);

  return qs.parse(location.search).cwblabs ? (
    <CWBLabsActorUpgradeComplete />
  ) : (
    <PageContainer>
      <StyledModal>
        <ContentWrapper>
          <StyledTextH1 variant="h1">
            {t("Payment Complete")}
          </StyledTextH1>
          <Icon alt="check" src="../images/icon-tick.svg" />
          <StyledTextP>
            {t("You now have access to all of Casting Workbook's Pro features and content!")}
          </StyledTextP>
          {
            isJob? <StyledTextP>
              {t("You will automatically be redirected to the submission page, in")}
            &nbsp;<span>{remainingTime}</span>&nbsp;
            {t("seconds.")}
            </StyledTextP> : <StyledTextP>
            {t("You will automatically be redirected to the home page, in")}
            &nbsp;<span>{remainingTime}</span>&nbsp;
            {t("seconds.")}
          </StyledTextP>
          }
          <StyledButton onClick={() => redirect()}>
            {isJob? t("Go to submission"): t("Go to Casting Workbook")}
          </StyledButton>
        </ContentWrapper>
      </StyledModal>
      <Footer />
    </PageContainer>
  );
};

const StyledModal = styled(Modal)`
  width: 800px;
  height: 666px;

  @media all and (max-width: 768px) {
    width: 690px;
  }

  @media all and (max-width: 520px) {
    width: 100%;
    height: 100%;
  }
`;

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 160px 16px;

  @media all and (max-width: 768px) {
    padding: 0 104px 16px;
  }

  @media all and (max-width: 520px) {
    padding: 40px 32px 56px;
  }
`;

const StyledTextH1 = styled(Text)`
  margin-bottom: 16px;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 20px;
  padding: 8px;
  background-color: ${p => p.theme.green};
  border-radius: 50%;
`;

const StyledTextP = styled(Text)`
  margin-bottom: 24px;
  text-align: center;

  & > span {
    color: ${p => p.theme.blue};
    text-decoration: underline;
  }

  @media all and (min-width: 520px) {
    &:last-of-type {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
`;

const StyledButton = styled(StyledBlueBtn)`
  width: unset;
  padding: 8px;
`;

interface StateProps {
  authenticatedUser: any;
  sessionData: any;
}

const mapStateToProps = (state: iRootState) => ({
  authenticatedUser: state.appModel.authenticatedUser,
  sessionData: state.appModel.sessionData
});

export default withI18n()(connect(
  mapStateToProps,
  null
)(ActorUpgradeComplete));
