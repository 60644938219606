//@ts-nocheck
import React from "react";
import styled from "styled-components";
// @ts-ignore
import { UncontrolledTooltip } from "reactstrap";

interface Props {
  className?: string;
  placement?: string;
  tip: string;
}

const Tooltip: React.FC<Props> = ({ children, className, placement = "right", tip }) => {
  const anchorId = tip ? tip.replace(/[^A-Za-z]/g,'') : 'tooltip';

  return (
    <Container
      id={anchorId}
      className={className}
    >
      {children}
      <UncontrolledTooltip
        placement={placement}
        target={anchorId}
      >
        {tip}
      </UncontrolledTooltip>
    </Container>
  );
}

const Container = styled.span`
  position: relative;
`;

export default Tooltip;
