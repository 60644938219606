import React from "react";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import { IAccountRequestData } from "../../shared/api/dtos/IActorDto";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import Footer from "../Common/Footer";
import Modal from "../Common/Modal";
import PageContainer from "../Common/PageContainer";

interface IProps extends RouteComponentProps<any> {
  t?: any;
  location: any;
}

interface IState {
  qs: IAccountRequestData;
}

class AccountConfirmed extends React.Component<IProps, {}> {
  public state: IState = {
    qs: {} as IAccountRequestData,
  };
  private _redirect: any;

  componentWillMount() {
    const qs = window.localStorage.getItem('qs');
    this.setState({qs});
  }

  componentDidMount() {
    this._redirect = clearTimeout(this._redirect);
    this._redirect = setTimeout(() => {
      window.top.location.href = `${process.env.REACT_APP_CWB_Site}/actorsworkbook/default.asp?${this.state.qs}`
    }, 9000);
  }

  componentWillUnmount() {
    window.localStorage.removeItem('qs');
  }

  render() {
    const { t } = this.props;
    return (
      <PageContainer>
        <Wrapper>
          <Thanksdiv>
            <StyledTitle>{t("Thank You")}</StyledTitle>
            <TickIcon src="../../images/icon-tick.svg" />
          </Thanksdiv>
          <TopText>
            {t('You now have the access to Virtual Casting Room. You can fill in info-sheet, get audition details and plus more, find details in Casting Workbook dashboard.')}
          </TopText>
          <BottomText>{t('You will be automatically redirected to dashboard 9 seconds')}</BottomText>
          <LoginButton href={`${process.env.REACT_APP_CWB_Prod_Site}/actorsworkbook/default.asp?${this.state.qs}`}>
            {t("Go to dashboard")}
          </LoginButton>
        </Wrapper>
        <Footer />
      </PageContainer>
    );
  }
}

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  max-width: 610px;
  min-width: 610px;
  background-color: ${(p) => p.theme.white};
  padding: 32px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;

  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 32px 24px;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const Thanksdiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media all and (max-width: 520px) {
    flex-direction: row;
    margin-bottom: 40px;
  }
`

const StyledTitle = styled.div`
  color: ${p => p.theme.formField.color} !important;
  font-size: ${p => p.theme["xxl-font-size"]};
  font-weight: ${p => p.theme["font-weight"]};
  margin-right: 16px;
`;

const TickIcon = styled.img`
  margin: 24px 0;
  background: ${p => p.theme.green};;
  padding: 10px 8px;
  border-radius: 25px;
  @media all and (max-width: 520px) {
    margin: 0;
  }
`

const TopText = styled.div`
  font-size: ${p => p.theme["lg-font-size"]};
  line-height: 22px;
  color: ${p => p.theme.formField.color};
  margin-bottom: 27px;
  @media all and (max-width: 520px) {
    margin-bottom: 24px;
    text-align: left;
  }
`

const BottomText = styled.div`
  font-size: ${p => p.theme["lg-font-size"]};
  line-height: 22px;
  color: ${p => p.theme.formField.color};
  margin-bottom: 51px;
  @media all and (max-width: 520px) {
    margin-bottom: 40px;
    text-align: left;
  }
`

const LoginButton = styled.a`
  width: 60%;

  display: flex;
  justify-content: center;

  color: ${p => p.theme.white} !important;
  font-size: ${p => p.theme["font-size"]};
  font-weight: ${p => p.theme["font-weight-600"]};
  background-color: ${p => p.theme.btnBlue};
  border-radius: 4px;
  padding: 9px 0;
  @media all and (max-width: 520px) {
    width: 100%;
  }
`;

export default withI18n()(withRouter(AccountConfirmed));
