import { getTrackingData } from "helpers";
import * as http from "../http";
import { IAgentUser } from "./dtos/IAgentDtos";
import { TrackingData } from "./dtos/ITrackingDataDto";

export const createAgentAccount = (dto: IAgentUser) => {
  dto.trackingData = getTrackingData();
  return http.post<IAgentUser>(
    `${process.env.REACT_APP_REG_Api}/agent/create${window.location.search}`,
    dto
  );
};
